module.exports = {
    accounts: {
        title: 'Neuen Nutzer anlegen',
        schema: {
            "type": "object",
            "title": "Zugangsdaten",
            "properties": {
                "firstname": {
                    "type": "string",
                    "title": "Vorname"
                },
                "lastname": {
                    "type": "string",
                    "title": "Nachname"
                },
                "email": {
                    "title": "Email",
                    "type": "string",
                    "format": "email"
                },
                "password": {
                    "title": "Passwort",
                    "type": "string"
                },
                "targetgroup_ids": {
                    "title": "Projekte",
                    "type": "array",
                    "items": {
                        "type": "string",
                        "enum": [],
                        "enumNames": []
                    }
                },
                "scope": {
                    "type": "array",
                    "title": "Role",
                    "required": true,
                    "items": {
                        "type": "object",
                        "properties": {
                            "role": {
                                "type": "string",
                                "title": "Rolle",
                                "enum": [
                                    "super_admin",
                                    "admin",
                                    "customer",
                                    "pages",
                                    "posts",
                                    "contacts",
                                    "media"
                                ],
                                "enumNames": [
                                    "Super Admin (nur Mitarbeiter)",
                                    "Admin (Partner)",
                                    "Kunde",
                                    "Seiten",
                                    "Blogs",
                                    "Kontakte",
                                    "Dateien"
                                ]
                            }
                        }
                    }
                }
            }
        }
    },
    pages: {
        title: 'Neue Seite anlegen',
        schema: {
            title: "Titel der Seite",
            type: "string",
            required: true
        }
    },
    posts: {
        title: 'Neuen Blogartikel anlegen',
        schema: {
            title: "Titel des Blogs",
            type: "string",
            required: true
        }
    },
    contacts: {
        title: 'Neuer Kontakt',
        new_target: 'contacts?nosend=true',
        schema: {
            title: "Name des Kontakts",
            type: "string",
            required: true
        }
    },
    media: {
        title: 'Neue Datei',
        new_target: 'media',
        schema: {
            title: "Datei hochladen",
            type: 'string'
        },
        uiSchema: {
            "ui:widget": "file_extended"
        }
    },
    progresses: {
        title: 'Neue Fortschrittmeldung',
        new_target: 'progresses',
        schema: {
            title: "",
            type: "object",
            properties: {
                "title": {
                    "title": "Titel",
                    "type": "string",
                    'required': true
                },
                "effort": {
                    "title": "Verbrauchte Zeit in h",
                    "type": "number",
                    "default": 0
                },
                "mail_sent": {
                    "title": "Als Email an Kunden versenden",
                    "type": "boolean",
                    "default": true
                },
                "description": {
                    "title": "Durchgeführte Arbeiten",
                    "type": "string"
                }
            }
        },
        uiSchema: {
            mail_sent: {
                "ui:widget": "hidden"
            },
            description: {
                "ui:widget": "textarea"
            }
        }
    }
}