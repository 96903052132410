import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import ColorPicker from './color-picker';
import DomainSelect from './domain-select';
import FileWidgetExtendedOld from './FileWidgetExtendedOld';
import FloatMenu from './float-menu';
var Handlebars = require('handlebars');
import HTMLEditor from './htmleditor';

import React, { Component } from "react";

export default class Wizard extends Component {
    constructor(props) {
        super(props);
        
        var self = this;
        this.async_checks = {};
        this.state =  {
            active_idx: 0,
            initialized: false
        };
    }
    translate(steps) {
        for (var i = 0; i < steps.length; i++) {
            var step = steps[i];
            console.log('step', step);
            try {
                step.check = eval('(' + step.check + ')'); //second variable object reference
                step.async_check = eval('(' + step.async_check + ')');
                step.convert = eval('(' + step.convert + ')');
                step.finish = eval('(' + step.finish + ')');
                step.visible = eval('(' + step.visible + ')');
                if (typeof (step.onchange) !== 'undefined') {
                    step.onchange = eval('(' + step.onchange + ')');
                }
                step.ui_schema.type = {
                    id: step.type
                }
            } catch (e) { console.error(e) }
        }
        console.log('conv steps', steps);
        return steps;
    }
    reloadAgent(ev) {
        var self = this;
        if (typeof (ev) !== 'undefined') {
            ev.preventDefault();
        }
        /* replace unirest
        fetch
        unirest.get(this.props.config.app.host + '/agents?' + new Date().getTime()).end((res) => {
            this.steps = this.translate(res.body);
            this.setState({ initialized: true });
        });
        */
        fetch(this.props.config.app.host + '/agents?' + new Date().getTime())
        .then(response => response.json())
        .then(data => {
            console.log('data',data);
            this.steps = this.translate(data);
            this.setState({ initialized: true });
        })
    }
    loadSavedWizardData() {
        let stepsData = JSON.parse(localStorage.getItem('last_wizard_data'));
        this.steps.forEach((step, idx) => {
            step.data = stepsData[idx];
        })
        this.forceUpdate();
    }
    saveWizardData() {
        localStorage.setItem('last_wizard_data', JSON.stringify(this.steps.map((step) => { return step.data})));
    }
    componentDidMount() {
        var self = this;
        this.reloadAgent();
    }
    handleStepClick(ev, new_idx) {
        ev.preventDefault();
        if (typeof (new_idx) === 'undefined') {
            new_idx = parseInt(ev.currentTarget.dataset.id);
        }
        if (new_idx < this.steps.length && new_idx > -1) {
            this.steps[this.state.active_idx].active = false;
            this.steps[new_idx].active = true;
            this.setState({ active_idx: new_idx });
        }
    }
    handleNext(ev) {
        var self = this;
        var old_active_index = this.state.active_idx;
        //find next active step
        var active_next = (old_active_index) => {
            if (old_active_index + 1 < this.steps.length) {
                var step = this.steps[old_active_index];
                var next = this.steps[old_active_index + 1];
                var next_visible = _.bind(next.visible, step);
                if (next && next_visible(this.steps)) {
                    this.handleStepClick(ev, old_active_index + 1);
                } else {
                    active_next(old_active_index + 1);
                }
            }
        }
        active_next(old_active_index);
    }
    handlePrevious(ev) {
        ev.preventDefault();
        var old_active_index = this.state.active_idx;
        this.handleStepClick(ev, old_active_index - 1);
    }
    
    handleStep(ev) {
    
        //get step name to be updated
        var type = ev.uiSchema.type.id;
        //get step object to be upgated
        console.log("this.steps", this.steps);
        var step_index = this.steps.findIndex((step) => {
            return step.type === type;
        });
        //updated step and set new data
        var updated_step = this.steps[step_index];
        updated_step.data = ev.formData;
        //synchronous check onchange method of changed step

        var has_onchange = typeof (updated_step.onchange) !== 'undefined';
        if (has_onchange) {
            var onchange = _.bind(updated_step.onchange, updated_step);
            onchange(this.steps);
        }
        //asynchronous check onchange 
        //TODO async changing other possible
        var async_checks_promises = [];
        _.each(this.steps, (step, idx) => {
            if (typeof updated_step.async_check !== 'undefined') {
                async_checks_promises.push(new Promise((resolve, reject) => {
                    var async_check = _.bind(updated_step.async_check, updated_step);
                    if (idx === step_index) {
                        async_check = _.bind(updated_step.async_check, updated_step);
                    }
                    async_check((result) => {
                        this.async_checks[updated_step.type] = result;
                        resolve();
                    }, this.props.client)
                }));
            } else {
                this.async_checks[updated_step.type] = null;
            }
        });
        Promise.all(async_checks_promises).then(() => {
            if (this.old_progress.percentage !== this.progress().percentage) {
                this.forceUpdate();
            }
        });


    }
    create_config() {
        var self = this;
        var menu = [];
        if (this.steps[1].data !== 'Fahrzeughandel') {
            menu = [
                { title: 'Über uns', url: '/#ueber_uns', call_to_action: false },
                { title: 'Leistungen', url: '/#leistungen', call_to_action: false },
                { title: 'Kontakt', url: '/#kontakt', call_to_action: true }
            ];
        }
        //predefined -> TODO to agent_config
        var landingpage = {
            seo: { //page
                
            },
            menu: menu,
            name: this.steps[2].data.name,
            layout: {
                brands_colors: {},
                heading_colors: {},
                fonts: {},
                content_colors: {},
                nav_colors: {}
            },
            sections: [] //page
        };
        //iterative generation step by step of landingpage
        _.each(this.steps, (step) => {
            var visible = _.bind(step.visible, step);
            if (visible(this.steps) && step.check(this.async_checks[step.type]).done) {
                step.convert(landingpage, step);
            }
        });

        return landingpage;
    }
    empty_page(title) {
        return {
            landingpage: false,
            seo: {
                head_title: title,
                head_description: "",
                head_keywords: ""
            },
            sections: []
        }
    }
    create_text_section(title, sub_title, text) {
        return {
            field_type: 'Text',
            text_data: {
                config: {
                    alignment: 'left',
                    background_reversed: false,
                    anchor: 'text'
                },
                titles: {
                    title: title,
                    sub_title: sub_title
                },
                content: text
            }
        };
    }
    create_simple_page(title, data, template) {
        
        console.log(data);
    
        var self = this;
        return new Promise((resolve, reject) => {
            var p = this.empty_page(title);
            $.get(this.props.config.app.host + '/static/templates/' + template, (template) => {
                var template = template;
                var template_render = Handlebars.compile(template);
                var result = template_render(data);
                p.sections.push(this.create_text_section(title, '', result));
                resolve(p);
            });
        })
    }
    handleFinish(ev) {
        var self = this;
        ev.preventDefault();
        emitter.emit('SHOW_LOADING');
        this.saveWizardData();
        var config = this.create_config();

        //throw new Error();
        var created_channel_group = null;
        //TODO contact address
        //channel group create
        this.props.client.create('/webpages', {
            name: config.name,
            contact_mail: this.steps[0].data.contactEmail ? this.steps[0].contactEmail : this.props.user.userId,
            targetGenerator: this.steps[0].data.targetGenerator
        }).then((res) => {
            console.log("UI1")
            if (res.status !== 200) {
                throw new Error(JSON.stringify(res.data));
            }
            //menu, layout, etc.
            created_channel_group = res.data;
            return this.props.client.create('/webpages/' + created_channel_group._id + '/config', config);
        }).then((res) => {
            if (res.status !== 200) {
                throw new Error(JSON.stringify(res.data));
            }
            console.log("UI2")
            //logo upload
            return Promise.all([
                this.props.client.create('/webpages/' + created_channel_group._id + '/media?filename=logo.png', { base64data: config.logo }),
                this.props.client.create('/webpages/' + created_channel_group._id + '/media?filename=background.jpg', { base64data: config.header })
            ]);
        }).then((resps) => {
            if (resps[0].status !== 200 || resps[1].status !== 200) {
                throw Error(JSON.stringify([resps[0].data, resps[1].data]));
            }
            console.log("UI3")
            //header upload
            var data = _.find(this.steps, { type: 'company_data' }).data;
            return Promise.all([
                //TODO: add terms and conditions
                this.create_simple_page('Datenschutzerklaerung', data, 'privacy_policy_template.html'),
                this.create_simple_page('Impressum', data, 'imprint.html')
            ]);
        }).then((pages) => {
            console.log("UI4")
            //privacy and imprint create
            return Promise.all([
                this.props.client.create('/webpages/' + created_channel_group._id + '/pages', {sub_path: '/', seo: config.seo, sections: config.sections}),
                this.props.client.create('/webpages/' + created_channel_group._id + '/pages', pages[0]),
                this.props.client.create('/webpages/' + created_channel_group._id + '/pages', pages[1])
            ]);

        }).then((simple_pages) => {
            console.log("UI5")
            if (simple_pages[0].status !== 200 || simple_pages[1].status !== 200) {
                throw Error(JSON.stringify([simple_pages[0].data, simple_pages[1].data]));
            } 
            //menu
            return this.props.client.update('/webpages/' + created_channel_group._id + '/menu', config.menu);
        }).then((res) => {
            console.log("UI6", res.status, res.data)
            if (res.status !== 200) {
                throw new Error(JSON.stringify(res.data));
            }
            emitter.emit('VALIDATE_TARGETGROUP');

            location.hash = '#webpages/' + created_channel_group._id
            emitter.emit('HIDE_LOADING');
        }).catch(e => {
            console.log('error', e);
            emitter.emit('SHOW_MODAL', {
                ok: (formData) => {
                },
                cancel: () => {
                },
                state: {
                    title: 'Problem bei Erstellung',
                    cancel_title: 'Abbrechen',
                    ok_title: 'Ok',
                    body_content: e.message
                }
            });
        });


    }
    progress() {
        var self = this;
        var result = {
            finished_steps_count: 0,
            finished_steps_target_count: 0
        };
        this.steps.forEach((step) => {
            var visible = _.bind(step.visible, step);
            if (visible(this.steps)) {
                if (step.check(this.async_checks[step.type]).done) {
                    ++result.finished_steps_count;
                }
                ++result.finished_steps_target_count;
            }
        })

        result.finish = result.finished_steps_count === result.finished_steps_target_count;
        result.percentage = result.finished_steps_count / this.steps.length;
        return result;
    }
    menu_params() {
        var self = this;
        var progress = this.progress();

        return {
            cancel: {
                title: 'Abbrechen',
                action: () => {
                    history.back();
                }
            },
            backward: {
                title: 'Zurück',
                action: (e) => {
                    this.handlePrevious(e);
                }
            },
            forward: {
                title: 'Weiter',
                action: (e) => {
                    this.handleNext(e);
                }
            },
            ok: {
                title: 'Anlegen',
                disabled: !progress.finish,
                action: (e) => {
                    this.handleFinish(e);
                }
            }
        }
    }
    componentDidUpdate(prevProps) {
        emitter.emit('HIDE_LOADING');
    }
    render() {
        var self = this;


        if (this.state.initialized && this.steps) {
            var inactive = {
                display: 'none'
            };
            var progress = this.progress();
            this.old_progress = progress;
            var percentage = progress.percentage;

            var finished_percentage = Math.round(percentage * 100);
            var percentage_style = {
                width: finished_percentage + '%'
            }
            var width_max = {
                width: '100%',
                marginBottom: '0px',
                height: '27px'
            }
            var percentage_class = 'progress-bar progress-bar-success';
            if (percentage < 0.8) {
                percentage_class = 'progress-bar progress-bar-warning';
            } else if (percentage < 0.4) {
                percentage_class = 'progress-bar progress-bar-danger';
            }

            var additional_widgets = {
                colorpicker: ColorPicker,
                domainselect: DomainSelect,
                file_extended: FileWidgetExtendedOld,
                htmleditor: HTMLEditor
            };

            var idx_tab = 0;
            var shorter = true;

            var wizard_steps = this.steps.map((step, i) => {
                var visible = _.bind(step.visible, step);
                if (visible(this.steps)) {
                    var clazz = step.active === true ? 'selected' : 'disabled';
                    if (step.active) {
                        shorter = false;
                    }
                    var wizard_step_style = { marginTop: '0px' };
                    if (!shorter) {
                        wizard_step_style = { marginTop: '20px' };
                    }
                    ++idx_tab;
                    var check = step.check(this.async_checks[step.type]);
                    var emotion = check.emotion;
                    return (
                        <li key={i} onClick={(e) => this.handleStepClick(e)} data-id={i}>
                            <a href="#" className={clazz} onClick={(e) => this.handleStepClick(e)} data-id={i} style={wizard_step_style}>
                                <span className={'step_no ' + emotion}>{idx_tab}</span>
                            </a>
                        </li>
                    )
                }
            });
            var current_step_form = '<div></div>';
            //TODO better step.active
            this.steps.forEach((step, i) => {
                var visible = _.bind(step.visible, step);
                if (visible(this.steps) && step.active) {
                    var check = step.check(this.async_checks[step.type]);
                    var emotion = check.emotion;
                    var emoClass = '';
                    if (emotion === 'happy') {
                        emoClass = 'fa fa-smile-o wizard-' + emotion;
                    } else if (emotion === 'neutral') {
                        emoClass = 'fa fa-meh-o wizard-' + emotion;
                    } else {
                        emoClass = 'fa fa-frown-o wizard-' + emotion;
                    }
                    var emoIcon = <i className={emoClass} aria-hidden="true"></i>;
                    current_step_form = (
                        <div className="content">
                            <h1 className={'wizard-' + emotion}>{emoIcon} - {check.answer}</h1>
                            <Form validator={validator} schema={step.data_schema} formData={step.data} uiSchema={step.ui_schema} onChange={(e) => this.handleStep(e)} widgets={additional_widgets} />
                        </div>
                    )
                }
            });
            return (
                <div className="row">
                    <div className="nav_menu_top">
                        <div className="col-md-2">
                            <h2 style={{ marginTop: '5px', marginTop: '18px', marginBottom: '18px', color: 'white' }}>Fortschrittsanzeige</h2>
                        </div>
                        <div className="col-md-10">
                            <div className="progress left" style={width_max}>
                                <div className={percentage_class} style={percentage_style}></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12" style={{ marginTop: '58px' }}>
                        <div className="x_panel">
                            <div className="x_title">
                                <h2>Setup-Assistent</h2>
                                <ul className="nav navbar-right panel_toolbox">
                                    <li><a className="close-link"><i className="fa fa-refresh" onClick={(e) => this.reloadAgent(e)}></i></a></li>
                                    <li><a><i className="fa fa-floppy-o" onClick={(e) => this.loadSavedWizardData(e)}></i></a></li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="x_content">
                                <div id="wizard_verticle" className="form_wizard wizard_verticle">
                                    <ul className="list-unstyled wizard_steps anchor">
                                        {wizard_steps}
                                    </ul>

                                    <div className="stepContainer">
                                        {current_step_form}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FloatMenu params={this.menu_params()} />
                </div>
            );

        } else {
            return <span></span>
        }
    }
}
