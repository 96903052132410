import React, { Component } from "react";

export default class FloatMenu extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var self = this;
        var ok = <span/>;
        var backward = <span/>;
        var preview = <span/>;
        console.log("float 1", self.props.params);
        if (typeof(self.props.params.preview) !== 'undefined') {
            preview = <a className="btn btn-info" href={self.props.params.preview.href} target="_blank">{self.props.params.preview.title}</a>
        }
        console.log("float 2");
        if (typeof(self.props.params.backward) !== 'undefined') {
            backward = <button className="btn btn-default" onClick={(e) => self.props.params.backward.action(e)}>{self.props.params.backward.title}</button>
        }
        console.log("float 3");
        var forward = <div/>;
        if (typeof(self.props.params.forward) !== 'undefined') {
            forward = <button className="btn btn-default" onClick={(e) => self.props.params.forward.action(e)}>{self.props.params.forward.title}</button>
        }
        console.log("float 4");
        if (typeof(self.props.params.ok) !== 'undefined') {
            var opts = {};
            if (self.props.params.ok.disabled) {
                opts['disabled'] = 'disabled';
            }
            ok = <button {...opts} className="btn btn-success" onClick={(e) => self.props.params.ok.action(e)}>{self.props.params.ok.title}</button>;
        }
        var cancel = <div/>;
        console.log("float 5");
        if (typeof(self.props.params.cancel) !== 'undefined') {
            cancel = <button className="btn btn-default" onClick={(e) => self.props.params.cancel.action(e)}>{self.props.params.cancel.title}</button>
        }
        return (
            <div className="nav_menu nav_menu_inner nav_menu_bottom text-right">
            {backward}
            {forward}    
            {cancel}
            {preview}
            {ok}    
            </div>
        );
    }
}