import Form from '@rjsf/core';

import validator from "@rjsf/validator-ajv8";
import FloatMenu from './float-menu';

import React, { Component } from "react";


export default class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.state =  {initialized: false, schema: null, user: null};
    }
    validate(props) {
        var self = this;
        var user = null;
        var groups = null;
        props.client.read('/webpages/?skip=0&limit=1000&fields=name').then((res) => {
            groups = res.data;
            return props.oauth_client.read('/schema/accounts');
        }).then((res) => {
            let schemaData = res.data;
            groups.forEach(g => {
                schemaData.properties.targetgroup_ids.items.enum.push(g._id);
                schemaData.properties.targetgroup_ids.items.enumNames.push(g.name);
            });
            self.setState({schema: schemaData});
            console.log("edit user me oder others", props.resource);
            return props.oauth_client.read('/accounts/' + (this.props.resource.resourceId ? this.props.resource.resourceId: global.user.email));

        }).then((res) => {
            user = res.data;
            
            self.setState({initialized: true, user, validation: false});
        })
    }
    componentDidMount() {
        var self = this;
        self.validate(self.props);
    }
    componentWillReceiveProps(new_props) {
        var self = this;
        self.validate(new_props);
    }
    handleChange(ev) {
        console.log(ev);
        this.setState({user: ev.formData});
    }
    save() {
        var self = this;
        emitter.trigger('SHOW_LOADING');
        console.log('save user', self.state.user);
        self.props.oauth_client.update('/accounts/' + self.state.user.email, self.state.user).then(function(update) {
            emitter.trigger('HIDE_LOADING');
        })
    }
    render() {
        var self = this;
        if (self.state.initialized) {
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function() {
                        history.back();
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function() {
                        self.save();
                    }
                }
            }
            console.log('user', self.state.user);
            var uiSchema = {
                    email: {
                        "ui:readonly": true  
                    },
                    password: {
                        "ui:placeholder": '*********',
                        "ui:widget": "password"
                    },
                    scope: {
                        "ui:disabled": global.is_super_admin ? false : true  ,
                        "ui:widget": "select"
                    }
                
            };
            /*var accountUIAttributes = {
                user: this.props.user, //do we have?
                params: resourceParams, //?
                client: this.props.client,//do we have?
                config: this.props.config,//do we have?
                resource: '/accounts', //do we have?
                apiURL: this.props.config //do we have, not only login?
            }*/
            return (
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="x_panel">
                            <div className="x_title">
                                <div className="clearfix"/>
                            </div>
                            <div className="x_content">
                                <Form validator={validator} liveValidate={self.state.validation} uiSchema={uiSchema} schema={self.state.schema} formData={self.state.user} onChange={(e) => self.handleChange(e)}>
                                    <div></div>
                                </Form>
                            </div>
                        </div>
                        {JSON.stringify(global.user.scope)}
                    </div>

                    <FloatMenu params={menu_params}/>
                </div>
            )
        } else {
            return <div/>;
        }
    }
}
