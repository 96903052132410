import React, { Component } from "react";
import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
export default class Section extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { formData } = this.props;
        
        let { section_type } = formData;
        let schema = this.props.sectionSchemas[section_type];
        schema.definitions = this.props.schemaDefinitions;

        var options = Object.keys(this.props.sectionSchemas).map((type) => {
            var title = this.props.sectionSchemas[type].title;
            if (formData.section_type === type) {
                return <option selected value={type}>{title}</option>;
            } else {
                return <option value={type}>{title}</option>
            }
        })
        var section_type_select = <select
            className="form-control"
            style={{ marginBottom: '20px' }}
            onChange={(e) => {
                let new_section_type = e.target.value;
                formData.section_type = new_section_type;
                this.props.onChange({formData})
            }}>
            {options}
        </select>
        var section_form_inner = (
            <div>
                {section_type_select}
                
                <Form
                    widgets={this.props.additional_widgets}
                    uiSchema={this.props.uiSchema}
                    schema={schema}
                    fields={this.props.fields}
                    formData={formData}
                    validator={validator}
                    onChange={(e) => {
                        this.props.onChange(e);
                    }}>
                    <div></div>
                </Form>
            </div>
        );

        var section_title = formData.titles && formData.titles.title ? formData.titles.title : '';
        var section_title_snippet = '(' + this.props.idx + ') ' + (section_title && section_title.trim().length > 0 ? section_title : 'Kein Titel angegeben');


        return (
            <div key={'section-form-' + this.props.idx} className="x_panel" data-id={formData._id} style={{ cursor: 'pointer' }} >
                <div className="x_title" onClick={(e) => {
                    if (e.target !== e.currentTarget) return;
                    this.props.visible(formData._id);
                }}>
                    <h2>{section_title_snippet}</h2>
                    
                    <ul className="nav navbar-right panel_toolbox">
                        <li><a onClick={() => this.props.moveUp()}><i className="fa fa-arrow-up"></i></a></li>
                        <li><a onClick={() => this.props.moveDown()}><i className="fa fa-arrow-down"></i></a></li>
                        <li><a onClick={() => this.props.new()}><i className="fa fa-plus"></i></a></li>
                        <li><a onClick={() => this.props.delete()}><i className="fa fa-minus"></i></a></li>
                        <li><a onClick={() => this.props.visible(formData._id)}><i className={this.props.isVisible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i></a></li>
                    </ul>
                    <div className="clearfix"></div>
                </div>
                <div className="x_content">
                    <div className="row">
                        <div className="col-md-12">
                            <div id={formData._id} className="section-edit-wrapper" style={this.props.isVisible ? { display: 'block' } : { display: 'none' }}>
                                {section_form_inner}
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}