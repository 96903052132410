module.exports = {
    _id: {
        "ui:widget": "hidden"
    },
    ref_product_id: {
        "ui:widget": "hidden"
    },
    ref_targetgroup_id: {
        "ui:widget": "hidden"
    },
    top_menu_left: {
        items: {
            title: {
                classNames: "col-xs-5"
            },
            url: {
                classNames: "col-xs-5"
            },
            prio: {
                classNames: "col-xs-2"
            },
            call_to_action: {
                classNames: "col-xs-2"
            },
            icon: {
                classNames: "col-xs-5"
            },
            icon_alignment: {
                classNames: "col-xs-5"
            },
            sub_menu_item: {
                "ui:widget": "hidden"
            }
        }
    },
    top_menu_right: {
        items: {
            title: {
                classNames: "col-xs-3"
            },
            url: {
                classNames: "col-xs-3"
            },
            prio: {
                classNames: "col-xs-1"
            },
            call_to_action: {
                classNames: "col-xs-1"
            },
            icon: {
                classNames: "col-xs-2"
            },
            icon_alignment: {
                classNames: "col-xs-2"
            },
            sub_menu_item: {
                "ui:widget": "hidden"
            }
        }
    },
    main_menu: {
        items: {
            title: {
                classNames: "col-xs-5"
            },
            url: {
                classNames: "col-xs-5"
            },
            prio: {
                classNames: "col-xs-2"
            },
            call_to_action: {
                classNames: "col-xs-2"
            },
            icon: {
                classNames: "col-xs-5"
            },
            icon_alignment: {
                classNames: "col-xs-5"
            },
            sub_menu_item: {
                items: {
                    title: {
                        classNames: "col-xs-5"
                    },
                    url: {
                        classNames: "col-xs-5"
                    },
                    prio: {
                        classNames: "col-xs-2"
                    },
                    call_to_action: {
                        "ui:widget": "hidden"
                    },
                    icon: {
                        "ui:widget": "hidden"
                    },
                    icon_alignment: {
                        "ui:widget": "hidden"
                    },
                }
            }
        }
    },
    footer_menu: {
        items: {
            title: {
                classNames: "col-xs-5"
            },
            url: {
                classNames: "col-xs-5"
            },
            prio: {
                classNames: "col-xs-2"
            },
            call_to_action: {
                classNames: "col-xs-2"
            },
            icon: {
                classNames: "col-xs-5"
            },
            icon_alignment: {
                classNames: "col-xs-5"
            },
            sub_menu_item: {
                items: {
                    title: {
                        classNames: "col-xs-5"
                    },
                    url: {
                        classNames: "col-xs-5"
                    },
                    prio: {
                        classNames: "col-xs-2"
                    },
                    call_to_action: {
                        classNames: "col-xs-2"
                    },
                    icon: {
                        classNames: "col-xs-5"
                    },
                    icon_alignment: {
                        classNames: "col-xs-5"
                    },
                    content: {
                        "ui:widget": "html_editor"
                    },
                    img: {
                        "ui:widget": "file_extended"
                    }
                }
            }
        }
    }
};