import React, { Component } from "react";
import PropTypes from "prop-types";

export default class TitlesField extends Component {
    constructor(props) {
        super(props);
        this.state = props.formData;
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => this.props.onChange(this.state));
    }

    render() {
        const {anchor, alignment, alignment_headings, background_reversed, margin_top, margin_bottom, background_alternative_area_color} = this.state;
        return (
<div className="row">
    <div className="col-md-4">
        <div className={"form-group"}> 
            <label>Ausrichtung Überschriften</label>
            <select name="alignment_headings"  className="form-control" value={alignment_headings} onChange={this.onChange}>
                <option value="left">Links</option>
                <option value="center">Zentriert</option>
                <option value="right">Rechts</option>
                <option value="justify">Block</option>
            </select>
        </div>
    </div>
    <div className="col-md-4">
        <div className={"form-group"}> 
            <label>Ausrichtung Text</label>
            <select name="alignment"  className="form-control" value={alignment} onChange={this.onChange}>
                <option value="left">Links</option>
                <option value="center">Zentriert</option>
                <option value="right">Rechts</option>
                <option value="justify">Block</option>
            </select>
        </div>
    </div>
    <div className="col-md-12">
        <label>Alternativer Hintergrund</label>
        <input name="background_reversed" style={{position: "inherit", zIndex: "1000000", marginLeft: '10px'}} className="" type="checkbox" value={background_reversed} checked={background_reversed ? "checked" : ""} onChange={this.onChange} />
    </div>
    <div className="col-md-12">
        <label>Alternative Flächen Farbe</label>
        <div className={"form-group"}> 
            <select name="background_alternative_area_color"  className="form-control" value={background_alternative_area_color} onChange={this.onChange}>
                <option value="">Default</option>
                <option value="background_alternative_area_color_one">Farbe 1</option>
                <option value="background_alternative_area_color_two">Farbe 2</option>
            </select>
        </div>
    </div>  
    <div className="col-md-6">
        <div className={"form-group"}> 
            <label>Abstand oben</label>
            <select name="margin_top"  className="form-control" value={margin_top} onChange={this.onChange}>
                <option value="0">0px</option>
                <option value="5">5px</option>
                <option value="10">10px</option>
                <option value="15">15px</option>
                <option value="20">20px</option>
                <option value="25">25px</option>
                <option value="30">30px</option>
                <option value="35">35px</option>
                <option value="40">40px</option>
                <option value="45">45px</option>
                <option value="50">50px</option>
                <option value="55">55px</option>
                <option value="60">60px</option>
                <option value="65">65px</option>
                <option value="70">70px</option>
                <option value="75">75px</option>
                <option value="80">80px</option>
                <option value="85">85px</option>
                <option value="90">90px</option>
            </select>
        </div>
    </div>
    <div className="col-md-6">
        <div className={"form-group"}> 
            <label>Abstand unten</label>
            <select name="margin_bottom"  className="form-control" value={margin_bottom} onChange={this.onChange}>
                <option value="0">0px</option>
                <option value="5">5px</option>
                <option value="10">10px</option>
                <option value="15">15px</option>
                <option value="20">20px</option>
                <option value="25">25px</option>
                <option value="30">30px</option>
                <option value="35">35px</option>
                <option value="40">40px</option>
                <option value="45">45px</option>
                <option value="50">50px</option>
                <option value="55">55px</option>
                <option value="60">60px</option>
                <option value="65">65px</option>
                <option value="70">70px</option>
                <option value="75">75px</option>
                <option value="80">80px</option>
                <option value="85">85px</option>
                <option value="90">90px</option>
            </select>
        </div>
    </div>
    <div className="col-md-12">
        <div className={"form-group"}>
            <label>Anker</label>
            <input name="anchor" className="form-control" placeholder="Anker" type="text" value={anchor} onChange={this.onChange} />
        </div>
    </div>
</div>
);
}
}
