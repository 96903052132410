import React from 'react';
import { InfinitySpin } from 'react-loader-spinner';

export default class HTMLEditor extends React.Component {
    constructor(props) {
        super(props);
        this.storage = props.value;
        this.state = { rewrite: false, loading: false }
        this.disableOnChange = false;
    }
    componentDidMount() {
        var self = this;
        var $editorContainer = $(this.refs.editorContainer);

        this.editor = $editorContainer.summernote({
            callbacks: {
                onChange: function (contents, $editable) {
                    if (!self.disableOnChange) {
                        self.props.onChange(contents)
                    }
                }
            },
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                //['fontsize', ['fontsize']],
                ['insert', ['link']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['codeview', ['codeview']]
            ],
            shortcuts: false,
            disableDragAndDrop: false
        });

    }
    componentWillReceiveProps(newProps) {
        this.disableOnChange = true;
        if (newProps.value !== $(this.editor).summernote('code')) {
            $(this.editor).summernote('code', newProps.value);
        }
        this.disableOnChange = false;
    }
    async generate(instruction) {
        console.log(instruction);
        if (!this.state.loading) {
            this.setState({ loading: true });
            try {
                var data = await global.client.create('/generators/text', { instruction });
                console.log("RESULT => ", data.data);
                if (!self.disableOnChange && data.data.choices && data.data.choices.length > 0) {
                    this.setState({ generator_result: data.data.choices[0].text, loading: undefined });
                }
            } catch (e) {
                console.error(e);
            }
        }
    }


    async rewriteText() {
        var textFiltered = $(this.editor).summernote('code').replace(/<[^>]+>/g, '');
        this.generate("Schreibe folgenden Text um: " + textFiltered);
    }
    async generateText(instruction) {
        this.generate(this.state.instruction);
    }
    render() {
        var instructionResult = <span></span>;
        if (this.state.loading) {
            instructionResult = <div style={{ textAlign: 'center', width: '100%', border: "1px solid lightgrey", fontSize: "17px", padding: "10px 20px", marginTop: "25px", marginBottom: "25px" }}>
                <InfinitySpin
                    width='200'
                    color="#2A3F54"
                />
            </div>
        } else if (this.state.generator_result) {
            instructionResult = <div style={{ border: "1px solid lightgrey", fontSize: "17px", padding: "10px 20px", marginTop: "25px", marginBottom: "25px" }}>
                <div style={{ marginBottom: '10px' }}>
                    {this.state.generator_result}
                </div>
                <div style={{ marginBottom: '10px', textAlign: "center" }}>
                    <button className="btn btn-default btn-lg" onClick={() => {
                        this.props.onChange(this.state.generator_result);
                        this.setState({ generator_result: undefined });
                    }}>Übernehmen</button>
                </div>
            </div>
        }
        return (
            <div>
                <div className="input-group">
                    <span class="input-group-addon">KI Textgenerator</span>
                    <input type="text" className="form-control" placeholder="Erstelle mir einen einleitenden Text für eine Webseite für einen Malerbetrieb..." 
                        value={this.state.instruction} 
                        onChange={(e) => this.setState({ instruction: e.target.value })} 
                        onKeyDown={async (e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                this.setState({ instruction: e.target.value }, () => {
                                    this.generateText()
                                })
                            }
                        }}
                    />
                    <span className="input-group-btn">
                        <button className={"btn btn-default " + (this.state.loading ? 'disabled' : '')} type="button" onClick={() => this.generateText()}>Neuen Text aus Anweisung erzeugen</button>
                    </span>
                    <span className="input-group-btn">
                        <button className={"btn btn-default " + (this.state.loading ? 'disabled' : '')} type="button" onClick={() => this.rewriteText()}>Aktuellen Text umschreiben</button>
                    </span>
                </div>
                {instructionResult}
                <div ref="editorContainer" style={{ width: '100%', height: '350px' }} dangerouslySetInnerHTML={{ __html: this.storage }} />
            </div>
        )
    }
}
