import React, { Component } from "react";
import PropTypes from "prop-types";

import { dataURItoBlob, shouldRender, setState } from "@rjsf/utils";

function addNameToDataURL(dataURL, name) {
  return dataURL.replace(";base64", `;name=${name};base64`);
}

function processFile(file) {
  const { name, size, type } = file;
  console.log("image url 2.2.1", name, size, type)
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onload = event => {
      console.log("image url 2.2.2", {
        dataURL: addNameToDataURL(event.target.result, name), 
        name,
        size,
        type,
      })
      resolve({
        dataURL: addNameToDataURL(event.target.result, name), 
        name,
        size,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files) {
  return Promise.all([].map.call(files, processFile));
}

function FilesInfo(props) {
  const { filesInfo } = props;
  if (filesInfo.length === 0) {
    return null;
  }
  return (
    <div>
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type } = fileInfo;
        return (
          <span>
            <strong>{name}</strong> ({type}, {size} bytes)
          </span>
        );
      })}
    </div>
  );
}

function extractFileInfo(dataURLs) {
  return dataURLs
    .filter(dataURL => typeof dataURL !== "undefined")
    .map(dataURL => {
      const { blob, name } = dataURItoBlob(dataURL);
      return {
        name: name,
        size: blob.size,
        type: blob.type,
      };
    });
}

function getDataUri(url, callback) {
  var image = new Image();

  image.onload = function () {
    var canvas = document.createElement('canvas');
    canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
    canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

    canvas.getContext('2d').drawImage(this, 0, 0);

    // Get raw image data
    //callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

    // ... or get as Data URI
    callback(canvas.toDataURL()); //'image/png'
  };

  image.src = url;
}

export default class FileWidgetExtended extends Component {


  constructor(props) {
    super(props);
    const { value } = props;
    this.onChange = event => {
      const { multiple, onChange } = this.props;
      //getDataUri(this.currentImageSrc, (data) => console.log('image data', data));
      //alert(this.currentImageSrc);
      console.log("change", event);
      if (typeof (event) === 'string') {
        var imgUrl = event;
        console.log('image url 1', imgUrl);
        //repairing
        if (imgUrl.indexOf('/cdn') === 0) {
          imgUrl = imgUrl.replace('/cdn', '/media');
        } else if (imgUrl.indexOf('cdn') === 0) { 
          imgUrl = imgUrl.replace('cdn', '/media');
        } else if (imgUrl.indexOf('media') === 0) {
          imgUrl = imgUrl.replace('media', '/media');
        } else if (imgUrl.indexOf('cdn') !== 0 || imgUrl.indexOf('/cdn') !== 0 || imgUrl.indexOf('/media') !== 0) {
          if (!global.protocol || !global.main_domain) {
            alert("FileWidgetExtended => global.protocol or main_domain not defined");
          }
          imgUrl = imgUrl.replace(global.protocol + '://' + global.main_domain, '');
          console.log('image url 2', imgUrl);
        }

        this.setState({
          values: [event]
        }, () => onChange(imgUrl));
      } else {
        console.log('image url 2.0', event.target.files);
        processFiles(event.target.files).then(filesInfo => {
          const state = {
            values: filesInfo.map(fileInfo => fileInfo.dataURL),
            filesInfo,
          };
          console.log('image url 2.1', state);
          this.setState(state, () => {
            var data = JSON.parse(JSON.stringify(state.values));
            console.log('image url 2.2', data);
            if (!multiple) {
              data = data[0];
            }
            onChange(data);
          });
        });
      }

    };
    const values = Array.isArray(value) ? value : [value];
    this.state = { values, existingFiles: []/*, filesInfo: extractFileInfo(values)*/ };

  }

  componentDidMount() {
    if (this.props.options.disable_image_selection !== true) {
      global.client.read('/webpages/' + global.resource.resourceId + '/media').then(function (res) {
        this.setState({ existingFiles: res.data });
      }.bind(this))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState);
  }

  onDeleteChange(event) {
    const { onChange } = this.props;
    this.setState({
      values: undefined
    }, () => onChange(undefined));
  }

  render() {
    const { multiple, id, readonly, disabled, autofocus } = this.props;
    
    const { filesInfo } = this.state;
    
    var preview = <span />;

    const { values } = this.state;
    
    if (values !== undefined && values.length === 1 && typeof (values[0]) !== 'undefined' && values[0].length > 0 && values[0] !== 'undefined') {
      var src = values[0].indexOf('media/') !== -1 && values[0].indexOf('http') === -1 ? 'https://' + global.main_domain + '/' + values[0] : values[0];
      var imageComponent = <img src={src} style={{ height: '100px' }} />;
      this.currentImageSrc = src;
      preview = imageComponent;
    }

    var existingFiles = [
    ];
    
    var fileSelection = <span />;
    if (this.props.options.disable_image_selection !== true) {
      this.state.existingFiles.forEach( (file, idx) => {
        existingFiles.push(<li key={"file-" + idx}><a href="#" onClick={(e) => { e.preventDefault(); this.onChange(e.target.dataset.src) }} data-src={global.protocol + '://' + global.main_domain + file.url}>{file.name}</a></li>);
      });
      fileSelection = <div className="btn-group" role="group">
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Auswählen
          <span className="caret m-l-5"></span>
        </button>
        <ul className="dropdown-menu">
          {existingFiles}
        </ul>
      </div>
    }



    return (
      <div className="row">
        <div className="col-md-6">
          <div className="btn-group" role="group">
            <label className="btn btn-default btn-file btn-upload">
              Hochladen <input
                ref={ref => (this.inputRef = ref)}
                id={id}
                type="file"
                disabled={readonly || disabled}
                onChange={this.onChange}
                defaultValue=""
                autoFocus={autofocus}
                multiple={multiple}
                style={{ display: 'none' }}
              />
            </label>
            {fileSelection}
            <button className="btn btn-danger btn-delete" style={{ display: 'auto' }} onClick={(e) => this.onDeleteChange()}>Löschen</button>
          </div>
        </div>
        <div className="col-md-6">
          {preview}
        </div>
      </div>
    );
  }
}

FileWidgetExtended.defaultProps = {
  autofocus: false,
  multiple: false
};

if (process.env.NODE_ENV !== "production") {
  FileWidgetExtended.propTypes = {
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    autofocus: PropTypes.bool,
  };
}

