import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import FloatMenu from './float-menu';
import React, { Component } from "react";

export default class GoogleAdwordsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = { schema: null, google_adwords: null };
    }
    validate() {
        var self = this;

        this.props.client.read('/schema/integration').then(function (schema) {
            self.setState({ schema: schema.data });
        });

        this.props.client.read('/webpages/' + this.props.resource.resourceId + '/integration').then(function (google_adwords) {
            if (google_adwords.status === 200) {
                self.setState({ google_adwords: google_adwords.data });
            } else {
                self.setState({ google_adwords: {} });
            }
        });
    }
    componentDidMount() {
        var self = this;
        self.validate();
    }
    componentWillReceiveProps(ev) {
        var self = this;
        self.validate();
    }
    componentDidUpdate(prevProps, prevState) {
        var self = this;
    }
    componentWillUpdate(prevProps, prevState) {
        var self = this;
    }
    handleChange(ev) {
        this.setState({ google_adwords: ev.formData });
    }
    save() {
        emitter.trigger('SHOW_LOADING');
        //var method = typeof(channels_group_id) !== 'undefined' ? 'update' : 'create';
        this.props.client.update('/webpages/' + this.props.resource.resourceId + '/integration', this.state.google_adwords).then(function (update) {
            emitter.trigger('HIDE_LOADING');
        })
    }
    render() {
        var self = this;
        if (self.state.google_adwords !== null && self.state.schema !== null) {
            var uiSchema = {
                ref_product_id: {
                    "ui:widget": "hidden"
                },
                ref_targetgroup_id: {
                    "ui:widget": "hidden"
                },
                ref_wordpress_id: {
                    "ui:widget": "hidden"
                }
            };
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function () {
                        history.back();
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function () {
                        self.save();
                    }
                }
            }
            return (
                <div className="row">
                    <div className="col-md-12 domains-form">
                        <Form validator={validator} uiSchema={uiSchema} schema={self.state.schema} formData={self.state.google_adwords} onChange={(e) => self.handleChange(e)}>
                            <div></div>
                        </Form>
                    </div>
                    <FloatMenu params={menu_params} />
                </div>
            )
        } else {
            return <div />;
        }
    }
}
