
import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import FloatMenu from './float-menu';
import FileWidgetExtended from './FileWidgetExtended';
import React, { Component } from "react";

export default class GroupEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {initialized: false};
    }
    validate () {
        var self = this;
        self.props.client.read('/schema/webpage').then(function (schema) {
            self.schema = schema.data;
            return self.props.client.read('/webpages/' + self.props.resource.resourceId);
        }).then(function (group) {
            self.storage = group.data;
            return self.props.client.read('/webpages/' + self.props.resource.resourceId + '/domains')
        }).then(function (domains) {
            self.domain = domains.data.main_domain;
            self.protocol = domains.data.enable_ssl === true ? 'https' : 'http';
            global.protocol = self.protocol; // for file extended widget
            global.main_domain = self.domain; // for file extended widget
            self.setState({initialized: true});
        });
    }
    componentDidMount () {
        var self = this;
        self.validate();
    }
    componentWillReceiveProps (ev) {
        var self = this;
        self.validate();
    }
    handleChange (ev) {
        this.storage = ev.formData;
    }
    save () {
        var self = this;
        emitter.trigger('SHOW_LOADING');
        var url_prefix = '/webpages/' + self.props.resource.resourceId + '/media?filename=';
        var client = self.props.client;
        var promises = [];

        var logo_change = self.storage.logo_url && self.storage.logo_url.indexOf('data:') !== -1;
        var blog_header_change = self.storage.blog_header_url && self.storage.blog_header_url.indexOf('data:') !== -1;
        var fav_icon_change = self.storage.fav_icon_url && self.storage.fav_icon_url.indexOf('data:') !== -1;

        let changes = [];
        if (logo_change) {
            changes.push['logo_url'];
            promises.push(client.create(url_prefix + 'logo.png', {base64data: self.storage.logo_url}));
        }
        if (blog_header_change) {
            changes.push['blog_header_url'];
            promises.push(client.create(url_prefix + 'blog_header.png', {base64data: self.storage.blog_header_url}));
        }
        /*
        if (fav_icon_change) {
            changes.push['fav_icon_url'];
            promises.push(client.create(url_prefix + 'favicon.png', {base64data: self.storage.fav_icon_url}));
        }
        */
        Promise.all(promises).then(function (res) {
            changes.forEach((imageProperty) => {
                
            })
            console.log('upload group images', res);
            if (logo_change && blog_header_change) {
                self.storage.logo_url = res[0].data.url;
                self.storage.blog_header_url = res[1].data.url;
            } else if (logo_change && !blog_header_change) {
                self.storage.logo_url = res[0].data.url;
            } else if (!logo_change && blog_header_change) {
                self.storage.blog_header_url = res[0].data.url;
            }
            client.update('/webpages/' + self.props.resource.resourceId, self.storage).then(function (update) {
                emitter.emit('VALIDATE_TARGETGROUP');
                emitter.trigger('HIDE_LOADING');
            })
        })



    }
    render () {
        var self = this;

        if (self.state.initialized) {
            var additional_widgets = {
                file_extended: FileWidgetExtended
            };
            var uiSchema = {
                _id: {
                    "ui:widget": "hidden"
                },
                ref_product_id: {
                    "ui:widget": "hidden"
                },
                ref_targetgroup_id: {
                    "ui:widget": "hidden"
                },
                automated: {
                    "ui:widget": "hidden"
                },
                created: {
                    "ui:widget": "hidden"
                },
                analytics: {
                    "classNames": "hidden-ui"
                },
                fav_icon_url: {
                    "ui:widget": "file_extended",
                    "ui:emptyValue": "",
                    "ui:options": {
                        disable_image_selection: true
                    }
                },
                logo_url: {
                    "ui:widget": "file_extended",
                    "ui:emptyValue": "",
                    "ui:options": {
                        disable_image_selection: true
                    }
                },
                blog_header_url: {
                    "ui:widget": "file_extended",
                    "ui:emptyValue": "",
                    "ui:options": {
                        disable_image_selection: true
                    }
                },
                global_css: {
                    "ui:widget": "textarea",
                    "ui:emptyValue": ""
                },
                global_js: {
                    "ui:widget": "textarea",
                    "ui:emptyValue": ""
                },
                footer: {
                    columnOne: {
                        "ui:widget": "textarea",
                    },
                    columnTwo: {
                        "ui:widget": "textarea",
                    },
                    columnThree: {
                        "ui:widget": "textarea",
                    }
                }
            };
            if (!self.storage.automated) {
                uiSchema.logo_url = {"ui:widget": "hidden"};
                uiSchema.blog_header_url = {"ui:widget": "hidden"};
                uiSchema.socialmedia = {
                    "classNames": "hidden-ui"
                };
            }
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function () {
                        if (self.storage.automated) {
                            history.back();
                        } else {
                            location.hash = '#webpages'
                        }
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function () {
                        self.save();
                    }
                }
            }
            return (
                    <div className="row">
                        <div className="col-md-12 menu-form">
                            <Form validator={validator} widgets={additional_widgets} uiSchema={uiSchema} schema={self.schema} formData={self.storage} onChange={(e) => self.handleChange(e)}>
                                <div></div>
                            </Form>
                        </div>
                        <FloatMenu params={menu_params}/>
                    </div>
                    )
        } else {
            return <div/>;
        }
    }
}

