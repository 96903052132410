import React, { Component } from "react";

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.myElement = null;
 
        this.myElementRef = element => {
            this.myElement = element; 
        };

    }

    componentDidMount() {
        var self = this;
        if (typeof (window) !== 'undefined') { 
            self.cp = $(this.myElement).colorpicker({ 
                horizontal: true,
                format: 'rgba'
            });
            self.cp.on('changeColor', function (e) {
                self.props.onChange($(e.target).find('input').val());
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        $(this.myElement).find('input').attr('value', nextProps.value);
        $(this.myElement).find('i').attr('style', 'background-color: ' + nextProps.value);
    }
    onChange(e) {
        var self = this;
        if (e.target.value.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)) {
            self.props.onChange(e.target.value);
        } else {
            console.log('no match');
        }
    }
    render() {
        var self = this;
        return (
            <div className="input-group colorpicker-component" ref={this.myElementRef}>
                <input type="text" className="form-control" value={self.props.value} onChange={(e) => self.onChange(e)} />
                <span className="input-group-addon"><i></i></span>
            </div>
        )
    }
}