var Authorization = function (config) {
    var self = this;

    this.auth_uri = config.oauth_api.login_uri;

    this.config = config;
    
    emitter.on('LOGOUT', function () {
        $.removeCookie('x-auth-token', null, {path: '/'});
        $.removeCookie('x-refresh-token', null, {path: '/'});
        location.href = self.auth_uri;
     });
    

    self.getCode = function() {
        if (location.hash && location.hash.length > 0) {
            var hash = location.hash.substring(1, location.hash.length);
            var hashSplitted = hash.split('=');
            if (hashSplitted[0] === 'code') {
                return hashSplitted[1];
            }
        }
        return;
    }

    this.validate = function (cb) {
        
        var code = self.getCode();
        
        if (typeof (code) !== 'undefined') {
            var formData = {
                grant_type: "authorization_code",
                client_id: self.config.oauth_api.client_id,
                client_secret: self.config.oauth_api.client_secret,
                code: code
            };
            $.ajax({
                url: self.config.oauth_api.request_bearer_token_uri,
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: formData,
                success: function (data) {
                    console.log("Authcookie 1", data)
                    var date = new Date();
                    var minutes = 60;
                    
                    self.deleteAllCookies();
                    console.log("Authcookie 2", data.access_token)
                    $.cookie("x-refresh-token", data.refresh_token, { expires: 1 });
                    $.cookie("x-auth-token", data.access_token, { expires: 1 });
                    location.hash = '';
                    cb();
                },
                error: function (e) {
                    location.href = self.auth_uri;
                }

            });


        } else {
            $.ajax(self.config.ressource_api.uri, {
                headers: {
                    "Authorization": 'Bearer ' + $.cookie("x-auth-token")
                },
                statusCode: {
                    200: function (response) {
                        cb();
                    },
                    302: function (response) {

                    },
                    401: function (response) {
                        location.href = self.auth_uri;
                    }
                }
            });
        }
    }

    this.deleteAllCookies = function() {
        var cookies = document.cookie.split(";");
    
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

}

module.exports = Authorization;