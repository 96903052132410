var moment = require('moment');
import React, { Component } from "react";

export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = { search: '', offset: 0, values: this.props.values };
    }
    validate(props)  {
        var self = this;
        var additionals = '';
        additionals = 'skip=0&limit=100&';
        if (props.sort) {
            additionals += 'sort=' + props.sort + '&';
        }
        if (additionals.length > 0) {
            additionals = '?' + additionals.substr(0, additionals.length - 1);
        }
        props.client.read('/webpages/' + props.group_id + '/' + props.resource + additionals).then(function(res) {
            self.setState({
                search: '',
                filter: {},
                offset: 0,
                values: res.data,
                real_values: res.data,
                show_filter: false
            });
        })
    }
    componentDidMount() {
        var self = this;
        self.validate(self.props);
    }
    componentWillReceiveProps (newProps) {
        var self = this;
        self.validate(newProps);
    }
    getValue (path, o) {
        var result = '';
        var recursive = (myPath, myO) => {
            var arr = myPath.split('.');
            var key = arr[0];
            var val = myO[key];
            console.log(key, val);
            if (arr.length !== 1) {
                recursive(arr.splice(1).join('.'), val);
            } else {
                result = val;
            }
        }
        recursive(path, o);

        return result;

    }
    handleSearch (e) {
        var self = this;
        var search_keys = e.target.value.toLowerCase().trim();
        search_keys = search_keys.split(' ');
        var new_values = [];
        this.state.real_values.forEach((entity) => {
            var filtered = false;
            Object.keys(self.props.types).forEach((key) => {
                var type = self.props.types[key];
                if (type.type === 'string') {
                    search_keys.forEach((search_key) => {
                        var property = JSON.parse(JSON.stringify(entity));
                        let splittedKey = key.split('.');
                        var i = 0;
                        while (i < splittedKey.length) {
                            property = property[splittedKey[i++]];
                        }
                        if (property.toLowerCase().indexOf(search_key) !== -1) {
                            filtered = true;
                        }
                    })
                } else if (type.type === 'array') {
                    search_keys.forEach((search_key) => {
                        var property = JSON.parse(JSON.stringify(entity));
                        
                        if (property[key] && property[key].join(',').toLowerCase().indexOf(search_key) !== -1) {
                            filtered = true;
                        }
                        
                    })
                }
            })
            if (filtered) {
                new_values.push(entity);
            }
        });
        this.setState({ search: e.target.value, values: new_values });
    }
    handleNext (ev) {
        ev.preventDefault();
        if ((this.state.offset + 1) * parseInt(this.props.max) < this.state.values.length) {
            this.setState({ offset: this.state.offset + 1 });
        }
    }
    handlePrevious (ev) {
        ev.preventDefault();
        if (this.state.offset - 1 > -1) {
            this.setState({ offset: this.state.offset - 1 });
        }
    }
    handleOffset (ev) {
        ev.preventDefault();
        var self = this;
        self.setState({ offset: parseInt(ev.currentTarget.dataset.offset) });
    }
    replace_placeholders (str, values) {
        var regex = /\$\((\w+)\)/g;
        var result = [];
        var match;
        while (match = regex.exec(str)) {
            var placeholder_key = match[1];
            str = str.replace(new RegExp('\\$\\(' + placeholder_key + '\\)', 'g'), values[placeholder_key]);
        }
        return str;
    }
    render () {
        var self = this;
        var body = <span></span>;
        if (Array.isArray(self.state.values)) {
            body = self.state.values.map(function (entity, idx) {
                var lt = idx >= self.state.offset * self.props.max;
                var st = idx < self.state.offset * self.props.max + self.props.max;

                if (lt && st) {
                    return (
                        <tr>
                            <th><input type="checkbox" className=""></input></th>
                            {Object.keys(self.props.types).map(function (key) {
                                var value = self.getValue(key, entity);
                                var type = self.props.types[key];
                                if (typeof (value) !== 'undefined') {
                                    if (type.type === 'string') {
                                        var data = value.length > 24 ? value.substring(0, 24) + '..' : value
                                        return (
                                            <td style={{ verticalAlign: 'middle' }}>{data}</td>
                                        )
                                    } else if (type.type === 'boolean') {
                                        if (value) {
                                            return <td style={{ verticalAlign: 'middle' }}><span className="fa fa-check" /></td>
                                        } else {
                                            return <td style={{ verticalAlign: 'middle' }}><span className="fa fa-times" /></td>
                                        }
                                    } else if (type.type === 'date') {
                                        var date = moment(value);
                                        return (
                                            <td style={{ verticalAlign: 'middle' }}>{date.utc().format('YYYY-MM-DD HH:mm')}</td>
                                        )
                                    } else if (type.type === 'array') {
                                        if (value) {
                                            value = value.join(', ');
                                        } else {
                                            value = '';
                                        }
                                        return (
                                            <td style={{ verticalAlign: 'middle' }}>{value}</td>
                                        )
                                    }
                                } else return <td></td>
                            })}
                            <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>
                                {self.props.actions.map(function (action) {
                                    var icon = '';
                                    if (action.icon) {
                                        icon = <i className={'fa fa-' + action.icon} />
                                    }
                                    if (action.type === 'button') {
                                        var id_val = entity[self.props.id_type];
                                        return (
                                            <button className={"btn btn-default btn-sm btn-" + action.icon} data-id={id_val} onClick={action.action}>{icon}{action.title}</button>
                                        )
                                    } else if (action.type === 'link') {
                                        return (
                                            <a className={"btn btn-default btn-sm btn-" + action.icon} href={self.replace_placeholders(action.url, entity)} target="_blank">{icon}{action.title}</a>
                                        )
                                    }
                                })}
                            </td>
                        </tr>
                    )
                } else {
                    return;
                }
            })
        }
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group form-group-sm">
                                <input type="text" id="first-name" required="required" className="form-control" placeholder="Suche..." onChange={(e) => self.handleSearch(e)} />
                            </div>
                        </div>
                        <div className="col-md-7">
                        
                            <a className="btn btn-default btn-sm" href={'/#webpages/' + this.props.group_id + '/' + this.props.resource}>Übersicht</a>
                            <button className="btn btn-default btn-sm" onClick={() => self.setState({ show_filter: !self.state.show_filter })}>Filter anzeigen</button>
                            <ul className="pagination pagination-sm pull-right" style={{ margin: '0px' }}>
                                <li><a href="#" onClick={(e) => self.handlePrevious(e)}>&lt;</a></li>
                                <li><a href="#" onClick={(e) => self.handleNext(e)}>&gt;</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ padding: "0px" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th key="heading-0">
                                            <select className="form-control" style={{ width: '0px', border: 'none', boxShadow: 'none', paddingRight: '2px', margin: 0 }}>
                                                <option></option>
                                                <option>alle auswählen</option>
                                                <option>zurücksetzen</option>
                                            </select>
                                        </th>
                                        {Object.keys(self.props.types).map(function (type_key, idx) {
                                            var type = self.props.types[type_key];
                                            if (typeof (type) !== 'undefined') {
                                                if (self.state.show_filter === true) {
                                                    var filter = <input type="text" className="form-control" placeholder="Suche"></input>
                                                }
                                                return (
                                                    <th key={'heading-' + idx}>{type.title}{filter}</th>
                                                );
                                            }
                                        })}
                                        {(() => {
                                            if (self.props.actions.length > 0) {
                                                return <th key="heading-100" style={{ textAlign: 'right' }}>Aktionen</th>
                                            }
                                        })()}
                                    </tr>
                                </thead>
                                <tbody>
                                    {body}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

