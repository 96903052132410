import React, { Component } from "react";

export default class TitlesField extends Component {
  constructor(props) {
    super(props);
    this.state = props.formData;
  }

  onChange(name) {
    return (event) => {
      event.preventDefault();
      let value = event.target.value ? event.target.value : event.target.textContent;
      console.log('CHANGE DOC', name,event.target.value, name,event.target.textContent, this.state);
      this.setState({
        [name]: value
      }, () => this.props.onChange(this.state));
    };
  }
 
  render() {
    const { title, sub_title, title_tag, sub_title_tag } = this.state;
    return (
      <div className="row">
        <div className="col-md-6">
          <label>Überschrift</label>
          <div className="input-group">
            <input className="form-control" placeholder="Titel" type="text" value={title} onChange={this.onChange("title")}></input>
            <div className="input-group-btn">
              <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title_tag} <span className="caret"></span></button>
              <ul className="dropdown-menu">
                <li><a href="#" onClick={this.onChange("title_tag")}>h1</a></li>
                <li><a href="#" onClick={this.onChange("title_tag")}>h2</a></li>
                <li><a href="#" onClick={this.onChange("title_tag")}>h3</a></li>
                <li><a href="#" onClick={this.onChange("title_tag")}>h4</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <label>Unterschrift</label>
          <div className="input-group">
            <input className="form-control" placeholder="Titel" type="text" value={sub_title} onChange={this.onChange("sub_title")}></input>
            <div className="input-group-btn">
              <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{sub_title_tag} <span className="caret"></span></button>
              <ul className="dropdown-menu">
                <li><a href="#" onClick={this.onChange("sub_title_tag")}>h1</a></li>
                <li><a href="#" onClick={this.onChange("sub_title_tag")}>h2</a></li>
                <li><a href="#" onClick={this.onChange("sub_title_tag")}>h3</a></li>
                <li><a href="#" onClick={this.onChange("sub_title_tag")}>h4</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
