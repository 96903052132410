import TopNavigation from './top-navigation';
import LeftNavigation from './left-navigation';
import Modal from './modal';
import OverviewModal from './overview-modal';
import Content from './content';
import Loader from './loader';
import React, { Component } from "react";

var Authorization = require('../libs/authorization');
var APIClient = require('../libs/rest-client').default;

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            authorized: false
        };
    }
    handleChange(ev) {
        ev.preventDefault();
    }
    componentDidMount() {
        emitter.emit('REACT_BOOT_FINISH');
        setInterval(function () {
            var height = $(window).height() - 160;
            $('.fix-form, .page-form, .preview').each(function () {
                if ($(this).height() - 15 !== height) {
                    $(this).css('max-height', height).css('height', height);
                }
            });
        }, 1000);
    }
    componentWillMount() {
        var self = this;
        localStorage.removeItem('edit-storage');
        var client = new APIClient(this.props.config.ressource_api.uri, this.props.config.oauth_api.login_uri);
        global.client = client;//for widgets global
        
        var oauth_client = new APIClient(this.props.config.oauth_api.host, this.props.config.oauth_api.login_uri);
        global.oauth_client = oauth_client;//for widgets global
        global.target_domain = this.props.config.domain;


        if (typeof (window) !== 'undefined') {
            
            new Authorization(self.props.config).validate(() => {
                oauth_client.read('/me').then((access_data) => {
                    oauth_client.read('/accounts/' + access_data.data.userId).then((user) => {
                        global.user = { id: access_data.data.userId, ...user.data };
                        global.user.scope.forEach((scope) => {
                            console.log('is_' + scope.role);
                            global['is_' + scope.role] = true;
                        });
                        self.setState({ user: { userId: access_data.data.userId }, authorized: true });
                    });
                });
            });
        }

    }
    render() {
        
        if (this.state.authorized === true && global.user && global.client) {
            return (
                <div className="main_container">
                    <Loader />
                    <LeftNavigation user={this.state.user} config={this.props.config} client={global.client} oauth_client={global.oauth_client}/>
                    <TopNavigation user={this.state.user} config={this.props.config} client={global.client} oauth_client={global.oauth_client}/>
                    <Content user={this.state.user} view={this.state.view} config={this.props.config} client={client} oauth_client={oauth_client}/>
                    <Modal />
                    <OverviewModal />
                </div>
            );
        } else {
            return (
                <div className="main_container">
                    <Modal />
                </div>
            )
        }
    }
}

