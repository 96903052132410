import React, { Component } from "react";

export default class DomainSelect extends Component {
    constructor(props) {
        super(props);
        var domain = typeof(this.props.value) !== 'undefined' ? this.props.value : "";
        this.state = {domain: domain, subdomain: domain.indexOf(global.target_domain) !== -1 || domain.length === 0 ? true : false };
    }
      
    componentDidMount() {
        var self = this;
    }
    onSubdomainHandle(e) {
        var self = this;
        var change2Subdomain = !self.state.subdomain;
        var base_domain = self.state.domain.replace('.' + global.target_domain, '');
        var target_domain = '';
        if (change2Subdomain) {
            target_domain = base_domain + '.' + global.target_domain;
        } else {
            target_domain = base_domain;
        }
        self.setState({domain: target_domain, subdomain: !self.state.subdomain});
        self.props.onChange(target_domain);
    }
    onChange(e) {
        var self = this;
        var domain = e.target.value.trim();
        if (self.state.subdomain) {
            domain = domain + '.' + global.target_domain;
        }
        self.setState({domain: domain});
        self.props.onChange(domain);
    }
    render() {
        var self = this;
        var domain = self.state.subdomain ? self.state.domain.replace('.' + global.target_domain, '') : self.state.domain;
        var checkbox = <span/>;
        if (self.props.options.show_subdomain) {
            checkbox = <input type="checkbox" checked={self.state.subdomain} style={{marginLeft: '10px', display: 'inline-block', position: 'relative', top: '1px'}} onChange={(e) => self.onSubdomainHandle(e)}/>;
        }
        var global_domain_suffix = '.' + global.target_domain;
        if (!self.state.subdomain) {
            global_domain_suffix = 'Als Subdomain ';
        }
        return (
            <div className="input-group">
                <input type="text" className="form-control" value={domain} placeholder="Domain" onChange={(e) => self.onChange(e)}/>
                <span className="input-group-addon">{global_domain_suffix}
                    {checkbox}
                </span>
            </div>
        )
    }
}