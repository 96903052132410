import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import FloatMenu from './float-menu';

import DomainSelect from './domain-select';
var shallowCompare = require('react-addons-shallow-compare');
import React, { Component } from "react";

export default class DomainsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            domain_registered: false
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }
    validate() {
        var self = this;
        this.props.client.read('/schema/domains').then((schema) => {
            self.schema = schema.data;
            return this.props.client.read('/webpages/' + this.props.resource.resourceId + '/domains');
        }).then((domains) => {
            self.storage = domains.data;
            self.storage_original = domains.data;
            console.log(self.storage, self.storage_original);
            self.setState({initialized: true});
        });
    }
    componentDidMount() {
        var self = this;
        self.validate();
    }
    componentWillReceiveProps(ev) {
        var self = this;
        self.validate();
    }
    handleChange(ev) {
        var self = this;
            self.props.client.read('/domains/' + ev.formData.main_domain).then(function(domains) {
                if (domains.status === 404) {
                    self.setState({domain_registered: false});
                } else if (self.storage_original.main_domain !== ev.formData.main_domain) {
                    self.setState({domain_registered: true});
                }
            });
        self.storage = ev.formData;
    }
    save() {
        emitter.trigger('SHOW_LOADING');
        console.log(self.storage, self.storage_original);
        this.storage.ref_targetgroup_id = this.props.resource.resourceId;
        this.props.client.update('/webpages/' + this.props.resource.resourceId + '/domains', this.storage).then(function(update) {
            emitter.trigger('HIDE_LOADING');
        })
    }
    render() {
        var self = this;
        if (self.state.initialized) {
            var uiSchema = {
                ref_product_id: {
                    "ui:widget": "hidden"
                },
                ref_targetgroup_id: {
                    "ui:widget": "hidden"
                },
                ref_wordpress_id: {
                    "ui:widget": "hidden"
                },
                automated: {
                    "ui:widget": "hidden"
                },
                main_domain: {
                    "ui:widget": "domainselect",
                    "ui:options": {
                        show_subdomain: true
                    }
                }
            };
            if (!self.storage.automated) {
                uiSchema.footer_domain = {
                    "ui:widget": "hidden"
                };
            }
            DomainSelect.defaultProps = {
                options: {
                    show_subdomain: true
                }
            };
            var additional_widgets = {
                domainselect: DomainSelect
            };
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function() {
                        if (self.storage.automated) {
                            location.hash = '#webpages/' + self.props.resource.resourceId
                        } else {
                            location.hash = '#webpages'
                        }
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function() {
                        self.save();
                    }
                }
            }
            var validate_data = function (formData, errors) {
                var domain_regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                if (self.state.domain_registered !== null && self.state.domain_registered) {
                    errors.main_domain.addError("Domainname bereits vergeben.");
                }
                /*if (!domain_regex.test(formData.main_domain)) {
                    errors.main_domain.addError("Domainname unzulässig.");
                }
                if (!domain_regex.test(formData.footer_domain)) {
                    errors.footer_domain.addError("Domainname unzulässig.");
                }
                _.each(formData.redirect_domains, function(redirect_domain, idx) {
                    if (!domain_regex.test(redirect_domain)) {
                        errors.redirect_domains.addError("Ein Domainname unzulässig.");
                    }
                });*/
                
                return errors;
            }
            var form = <Form validate={validate_data} validator={validator} showErrorList={false} liveValidate={true} uiSchema={uiSchema} schema={self.schema} formData={self.storage} onChange={(e) => self.handleChange(e)} widgets={additional_widgets}><div></div></Form>;
            console.log('render');
            return (
                <div className="row">
                    <div className="col-md-12 domains-form">
                        {form}
                    </div>
                    <FloatMenu params={menu_params}/>
                </div>
            )
        } else {
            return <div/>;
        }
    }
}
