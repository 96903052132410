module.exports = {
    slider_data: {
        order: [
            "titles_reversed",
            "*"],
        cols: {
            "6": [
                "titles_reversed",
                "title",
                "sub_title"]
        }
    },
    content_data: {
        order: [
            "title_style",
            "cols_left",
            "cols_right",
            "titles",
            "*"
        ],
        cols: {
            "4": [

                "title_style",
                "background_reversed"
            ],
            "6": [
                "cols_left",
                "cols_right",
                "title",
                "sub_title"
            ]
        }
    },
    contact_data: {
        order: [

            "send_button_title",
            "send_btn_color",
            "request_identifier",
            "titles",
            "*"
        ],
        cols: {
            "6": [

                "send_button_title",
                "send_btn_color",
                "titles",
            ]
        }
    },
    text_data: {
        order: [
            "titles",
            "*"
        ],
        cols: {
            "4": [

                "background_reversed"
            ],
            "6": [
                "title",
                "sub_title"
            ]
        }
    },
    text_image_horizontal_data: {
        order: [

            "box_type",
            "cols_left",
            "cols_right",
            "titles",
            "*"
        ],
        cols: {
            "4": [

                "box_type",
                "cols_left",
                "cols_right",
            ],
            "6": [
                "title",
                "sub_title"
            ]
        }
    },
    text_image_vertical_data: {
        order: [

            "titles",
            "*"
        ],
        cols: {
            "6": [

                "title",
                "sub_title"
            ]
        }
    },
    video_data: {
        order: [

            "titles",
            "*"
        ],
        cols: {
            "6": [

                "title",
                "sub_title"
            ]
        }
    },
    tabs_data: {
        order: [

            "style",

            "titles",
            "*"
        ],
        cols: {
            "4": [

                "style",
                "background_reversed"
            ],
            "6": [
                "title",
                "sub_title"
            ]
        }
    },
    references_image_data: {
        order: [

            "titles",
            "*"
        ],
        cols: {
            "6": [

                "title",
                "sub_title"
            ]
        }
    },
    gallery_data: {
        order: [ "filter_used", "titles", "*"],
        cols: {
            "6": [ "filter_used"]
        }
    },
    team_data: {
        order: [

            "titles",
            "*"
        ],
        cols: {
            "6": [

                "title",
                "sub_title"
            ]
        }
    },
    price_data: {
        order: [

            "brand_color",
            "order_btn_color",
            "titles",
            "*"
        ],
        cols: {
            "6": [

                "brand_color",
                "order_btn_color",
                "title",
                "sub_title"
            ]
        }
    },
    map_data: {
        order: [

            "background_color",
            "city",
            "city_code",
            "street",
            "street_number",
            "email",
            "phone",
            "*"
        ],
        cols: {
            "6": [

                "background_color",
                "city",
                "city_code",
                "street",
                "street_number",
                "email",
                "phone"
            ]
        }
    },
    demo_request_data: {
        order: [

            "style",

            "show_bg_img",
            "send_btn_color",
            "request_identifier",
            "message",
            "place_holder_title",
            "send_button_title",
            "remark",
            "*"
        ],
        cols: {
            "4": [

                "style",
                "background_reversed"
            ],
            "6": [
                "show_bg_img",
                "send_btn_color",
                "place_holder_title",
                "send_button_title"
            ]
        }
    },
    news_data: {
        order: [

            "show_image",
            "show_autor",
            "count",
            "titles",
            "*"
        ],
        cols: {
            "3": [

                "show_image",
                "show_autor",
                "count"
            ]
        }
    },
    custom_data: {
        order: ["*"]
    }
};