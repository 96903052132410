import React, { Component } from "react";

export default class GooglePlaceField extends Component {
    constructor(props) {
        super(props);
        this.state = props.formData;
    }

    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {
        this.setState();
    }
    onChange(data) {
        console.log(data);
        this.setState(data, () => this.props.onChange(this.state));
    }
    render() {
        return (
            <div>
                <label>Google Place Suche</label>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Firmenname u. Addresse" onChange={(e) => {
                        let query = e.target.value;
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                            global.client.read('/google/places?query=' + query)
                                .then(({data}) => {
                                    console.log('candidates -> ', data);
                                    if (data && data.length > 0) {
                                        this.onChange(data[0]);
                                    }
                                })
                                .catch((e) => {
                                    console.error(e);
                                });
                        }, 500)
                    }} />
                    <span class="input-group-addon">{this.state.place_id}</span>
                    <span class="input-group-addon">{this.state.name}</span>
                </div>
            </div>
        )
    }
}