import FloatMenu from './float-menu';
import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import HTMLEditor from './htmleditor';
import FileWidgetExtended from './FileWidgetExtended';
import uiSchema from './ui-schemas/menu-ui-schema.js';

import React, { Component } from "react";

var shallowCompare = require('react-addons-shallow-compare');

export default class MenuEdit extends Component {
    constructor(props) {
        super(props);
        this.state = { initialized: false };
    }
    shouldComponentUpdate (nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }
    validate () {
        var self = this;
        self.props.client.read('/schema/menu').then(function (schema) {
            self.schema = schema.data;
            return self.props.client.read('/webpages/' + self.props.resource.resourceId + '/menu');
        }).then(function (menu) {
            self.storage = menu.data;
            self.setState({ initialized: true }); 
        });
    }
    componentDidMount () {
        var self = this;
        self.validate();
    }
    componentWillReceiveProps (ev) {
        var self = this;
        self.validate();
    }
    handleChange (ev) {
        console.log(ev.formData);
        this.storage = ev.formData;
    }
    save () {
        emitter.trigger('SHOW_LOADING');
        this.props.client.update('/webpages/' + this.props.resource.resourceId + '/menu', this.storage).then(() => {
            this.props.client.update('/webpages/' + this.props.resource.resourceId + '/config');
        }).then(() => {
            emitter.trigger('HIDE_LOADING');
        })
    }
    render () {
        var self = this;
        console.log('render');
        if (self.state.initialized) {
            var additional_widgets = {
                file_extended: FileWidgetExtended,
                html_editor: HTMLEditor
            };
            
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function () {
                        history.back();
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function () {
                        self.save();
                    }
                }
            }
            console.log('menu', uiSchema, self.schema, self.storage);
            return (
                <div className="row">
                    
                    <div className="col-md-12 col-sm-12 col-xs-12 menu-form">
                        <Form validator={validator} uiSchema={uiSchema} schema={self.schema} formData={self.storage} onChange={(e) => this.handleChange(e)} widgets={additional_widgets}>
                            <div></div>
                        </Form>
                    </div>
                    <FloatMenu params={menu_params} />
                </div>
            )
        } else {
            return <div />;
        }
    }
}

