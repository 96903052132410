export default class SEOAdjuster {
    constructor() {
    }

    clearHTMLTags(strToSanitize) {
        return strToSanitize.replace(/(<([^>]+)>)/gi, ' ').trim().replace(/  +/g, ' ');;
    }

    async wait() {
        return new Promise((done) => setTimeout(done, 1000))
    }

    adjust(page) {
        var cancel = false;
        var traverse = (o, result, func) => {
            for (var i in o) {

                func.apply(this, [i, o[i], o, result]);

                if (cancel) {
                    break;
                }
                //console.log('before 2', i);
                if (o[i] !== null && typeof (o[i]) == "object") {
                    //going on step down in the object tree!!
                    traverse(o[i], result, func);

                }
            }
        }
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        var process = (key, value, parent, result) => {

            var process_inner = (key, value, parent, result) => {
                //console.log('before' , key, typeof value);
                if (typeof (value) === 'string' && (key === 'title' || key === 'sub_title' || key === 'text' || key === 'content' || key === 'html')) {
                    //console.log(key, clearHTMLTags(value));
                    var candidate = capitalizeFirstLetter(this.clearHTMLTags(value));
                    if (candidate.trim().length === 0) return;
                    candidate = candidate.replace(/&nbsp;/g, " ");
                    candidate += (candidate[candidate.length - 1] !== '.' ? '. ' : ' ');
                    //console.log("ADD => ", result.data, candidate);
                    
                    result.data += candidate;
                    if (result.data.length > 145) {
                        var sentences = result.data.split('. ');
                        var last_remove = null;
                        while (sentences.join('. ').trim().length > 143 && sentences.length > 1) {
                            last_remove = sentences.pop();
                        }
                        /*if ((sentences.join('. ') + last_remove + '.').length < 146) {
                            sentences.push(last_remove);
                        }*/
                        result.data = sentences.join('. ') + '.';
                        //console.log("AFTER SENTENCES => ", result.data.length, result.data);
                        if (result.data.length > 143) {
                            result.data = result.data.substring(0, 145);
                            var result_parts = result.data.split(' ');
                            result_parts.pop();
                            result.data = result_parts.join(' ') + '...';
                        }
                        result.data = result.data.replace(/\n/g, " ");
                        //console.log("RESULT => ", result.data);

                        cancel = true;
                        return;
                    }


                }

            }
            process_inner(key, value, parent, result);
        }
        if (!page.seo) page.seo = {};

        if (!page.seo.head_description || (page.seo.head_description && page.seo.head_description.trim().length === 0)) {
            if (page.sub_path.toLowerCase().indexOf('datenschutz') === -1 && page.sub_path.toLowerCase().indexOf('impressum') === -1) {
                //console.log("adjuster (seo)", "start");

                var base_for_desc = { data: '' };
                traverse(page.sections, base_for_desc, process);
                //console.log('--- Recommendation => ' + page.sub_path + ' ---');
                //console.log(page.seo.head_title);
                //console.log(page.seo.head_description);
                //console.log(page.seo.head_keywords);
                //console.log('result', base_for_desc);
                if (base_for_desc.data) base_for_desc.data = base_for_desc.data.replace(/\t/g, ' ').replace(/\n/g, ' ').replace(/  +/g, ' ').trim();
                if (base_for_desc.data && base_for_desc.data.length > 0 && base_for_desc.data.toLowerCase().indexOf('lorem') === -1) {
                    page.seo.head_description = base_for_desc.data;
                    page.seo.auto_generated = true;
                }
                //console.log("adjuster (seo)", "end");
            }
        }

    }
}