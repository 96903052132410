var moment = require('moment');
var _ = require('lodash');

var AnalyticsHelper = function () {

    this.request = function (client, group_id, period, steps, resources, endDate, startDate) {
        return new Promise(function (resolve, reject) {
            var api_url = '/webpages/' + group_id + '/analytics';
            var analytics_requests = [];
            var result = {};
            _.each(resources, function (resource) {
                var resource_url = resource.main + '/' + (typeof (resource.sub) !== 'undefined' ? resource.sub : '');
                for (var i = steps - 1; i >= 0; i--) {
                    var orienting_date = moment(endDate).subtract(i, period + 's');
                    var date_url_formatted = orienting_date.format('YYYY-MM-DD');
                    if (typeof (startDate) !== 'undefined') {
                        var days_between = moment(endDate).diff(moment(startDate), 'days');
                        var start_date_formatted = moment(startDate).subtract(days_between * i, 'days').format('YYYY-MM-DD');
                        var orienting_date = moment(endDate).subtract(days_between * i, 'days');
                        var end_date_formatted = orienting_date.format('YYYY-MM-DD');
                        date_url_formatted = start_date_formatted + ',' + end_date_formatted;
                    }
                    

                    analytics_requests.push(
                            new Promise(function (resolve, reject) {
                                
                                var millis = orienting_date.unix();
                                
                                client.read(api_url + '/' + period + '/' + date_url_formatted + '/' + resource_url).then(function (res) {
                                    var data = res.data;
                                    
                                    if (typeof (result[resource.key]) === 'undefined') {
                                        result[resource.key] = [];
                                    }
                                    data.date = millis;
                                    data.date_f = new Date(millis).toString();
                                    result[resource.key].push(data);
                                    resolve(data);
                                })
                            })
                            );
                }
            })
            Promise.all(analytics_requests).then(function () {
                _.each(result, function (item, key) {
                    if (Array.isArray(item) && item.length > 1 && typeof (item[0].date) !== 'undefined') {
                        item.sort(function (a, b) {
                            return a.date - b.date;
                        });
                    }
                });
                //console.log('fuck', result);
                resolve(result);
            })
        });
    }

}
module.exports = AnalyticsHelper;