import 'regenerator-runtime/runtime';

export default class APIClient {
    constructor(apiURL, loginURL) {
        //this.config.ressource_api.uri
        //this.config.oauth_api.host
        //this.config.oauth_api.login_uri
        
        this.apiURL = apiURL;
        this.loginURL = loginURL;
        
        console.log('login', this.loginURL);
    }

    read_cookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2)
            return parts.pop().split(";").shift();
    }

    async raw_request(method, resource, body, accept) {
        var options = {
            method,
            headers: {
                'Authorization': 'Bearer ' + this.read_cookie('x-auth-token'),
                'Content-Type': 'application/json',
                'Accept': accept ? accept : 'application/json'
            }
          }
        if (body !== null && !accept) {
            options.body = JSON.stringify(body);
        }
        let response = await fetch(this.apiURL + resource, options);
        let data = await response.json();
        let status = response.status;

        if (status === 401) {
            this.revalidate_access(); //TODO not jump to login
            //TODO revalidate token
        } else {
            return { data, status, response };
        }
    }

    async read(url, callback, accept) {
        let response = await this.raw_request('GET', url, null, accept);
        if (callback) {
            callback(response);
        } else {
            return response;
        }
    };
    
    async create(url, body, callback, accept) {
        let response = await this.raw_request('POST', url, body, accept);
        if (callback) {
            callback(response);
        } else {
            return response;
        }
    }
    
    async update(url, body, callback, accept) {
        let response = await this.raw_request('PUT', url, body, accept);
        if (callback) {
            callback(response);
        } else {
            return response;
        }
    }

    async remove(url, callback, accept) {
        let response = await this.raw_request('DELETE', url, null, accept);
        if (callback) {
            callback(response);
        } else {
            return response;
        }
    }
    


    revalidate_access() {
        location.href = this.loginURL;
    };
};

//module.exports = APIClient;