// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tui-image-editor-header-buttons {
  display: none !important;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {
  width: 10px;
  height: 10px;
}`, "",{"version":3,"sources":["webpack://./views/mediaedit.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ","sourcesContent":[".tui-image-editor-header-buttons {\n    display: none !important;\n}\n\n.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {\n    width: 10px;\n    height: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
