import React, { Component } from "react";
import PropTypes from "prop-types";
import FileWidget from '../FileWidgetExtended';


export default class SEOField extends Component {
        constructor(props) {
            super(props);
            this.state = props.formData;
            this.onChange = this.onChange.bind(this);
            
            
        }
        validate() {
        }
    
        componentDidMount() {
            this.validate();
        }
        componentWillReceiveProps(nextProps) {
            this.validate();
        }
        onChange(event, overrideName) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = overrideName ? overrideName: target.name;

            this.setState({
                [name]: value
            }, () => {
                this.props.onChange(this.state);
                
            });

            
        }

        render() {
            if (!this.state.head_description) {
                var desc = 'Keine Beschreibung';
            } else if (this.state.head_description.length > 160) {
                var desc = this.state.head_description.substring(0,160) + '...';
            } else { 
                var desc = this.state.head_description; 
            }
            return (
                <div>
                    
                    <div className="google-preview" style={{backgroundColor: 'white', border: '1px solid lightgray', padding: '10px 20px', marginBottom: '10px'}}>
                        <div><span className="hostname" style={{color: '#202124'}}>{global.main_domain}</span><span className="path" style={{color: '#5f6368'}}>{this.state.sub_path.split('/').join(' › ')}</span></div>
                        <div><span className="link" style={{color: '#1a0dab', textDecoration: 'none', fontSize: '20px'}}>{!this.state.head_title || this.state.head_title.trim().length === 0 ? 'Kein Titel' : this.state.head_title}</span></div>
                        <div>{desc}</div>
                    </div>
                    <div className="form-group">
                        <input type="text" name="head_title" className="form-control m-b-10" placeholder="Titel" value={this.state.head_title} onChange={this.onChange}/>
                        <textarea type="text"  name="head_description" className="form-control m-b-10" placeholder="Beschreibung" value={this.state.head_description}  onChange={this.onChange}/>
                        <input type="text"  name="head_keywords" className="form-control m-b-10" placeholder="Schlüsselbegriffe" value={this.state.head_keywords}  onChange={this.onChange}/>
                        <FileWidget name="sub_path" emptyValue={""} options={{disable_image_selection: true}} value={this.state.head_img} onChange={(value) => {
                            this.setState({
                                ["head_img"]: value
                            }, () => {
                                this.props.onChange(this.state);
                                
                            });
                        }}/>
                        <input type="text"  name="sub_path" className="form-control" placeholder="Pfad" value={this.state.sub_path}  onChange={this.onChange}/>
                    </div>
                </div>
            )
        }
    }

    /*
clearTimeout(this.timer);
                            this.timer = setTimeout(() => {
                                global.client.read('/google/places?query=' + query)
                                    .then(({data}) => {
                                        console.log('candidates -> ', data);
                                        if (data && data.length > 0) {
                                            this.onChange(data[0]);
                                        }
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                    });
                            }, 500)
    */