import React, { Component } from "react";
var moment = require('moment');

export default class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            type: this.props.type,
            steps: this.props.steps,
            date: this.props.date
        };
    }
    validate(props) {
        var self = this;
        if (typeof(window) !== 'undefined') {
            var api_url = '/webpages/' + self.props.group_id + '/analytics';
            var analytics_requests = [];
            var labels = [];
            self.props.categories.forEach((category) => {
                
                var ressource = category.main + '/' + (typeof(category.sub) !== 'undefined' ? category.sub : '');
                //console.log('analytics 1', category, ressource);
                for (var i = self.props.steps - 1; i >= 0; i--) {
                    var date_formatted = moment(self.props.date).subtract(i, 'months').format('YYYY-MM-DD');
                    var date_format_label = 'MM/YY';
                    if (self.props.type === 'day' || self.props.type === 'week') {
                        date_format_label = 'DD/MM/YY';
                    } else if (self.props.type === 'year') {
                        date_format_label = 'YYYY';
                    }
                    labels.push(moment(self.props.date).subtract(i, 'months').format(date_format_label));
                    //console.log('analytics 1', api_url + '/' + self.state.type  + '/' + date_formatted + '/' + ressource);
                    analytics_requests.push(props.client.read(api_url + '/' + self.state.type  + '/' + date_formatted + '/' + ressource));
                }
            })
            Promise.all(analytics_requests).then(function(analytics) {
                //console.log('analytics', analytics);
                var graph_config = {
                    type: self.props.graph_type,
                    data: {
                        labels: labels,
                        datasets: []
                    },
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                            display: false
                        }
                    }
                }
                _.each(self.props.categories, function(cat, idx) {
                    var data_path_steps = cat.data_path.split('.');
                    var range = analytics.length / self.props.categories.length;
                    var offset = idx * range;
                    var end = (idx + 1) * range;
                    var dataset = {
                        label: cat.title,
                        //fillColor: cat.color,
                        strokeColor: cat.color,
                        //backgroundColor: cat.color,
                        borderColor: cat.color,
                        data: []
                    };
                    for (var i = offset; i < end; i++) {
                        var single_analytics = analytics[i].data;
                        var last_val = single_analytics;
                        _.each(data_path_steps, function(step) {
                            last_val = last_val[step];
                        }) 
                        dataset.data.push(last_val);
                    }
                    graph_config.data.datasets.push(dataset);
                });
                $('canvas.chartjs').each(function(idx) {
                    var ctx = this;
                    var chart = new Chart(ctx, graph_config);
                });
            });
        }
    }
    componentDidMount() {
        var self = this;
        self.validate(self.props);
    }
    render() {
        var self = this;
        
        return (
            <div style={{position: 'relative', height: self.props.height + 'px'}}>
                <canvas className="chartjs"></canvas>
            </div>
        );
    }
};
