

import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import FloatMenu from './float-menu';
import React, { Component } from "react";
import ImageEditor from './image-editor';
import './mediaedit.scss';  

export default class MediaEdit extends Component {
    constructor(props) { 
        super(props);
        this.state = {schema:null, media:null, initialized: false};
    }
    validate(props) {
        var self = this;
        props.client.read('/schema/media').then(function(schema) {
            self.setState({schema: schema.data});
            console.log('request', '/webpages/' + props.resource.resourceId + '/media/' + props.resource.subResourceId);
            return props.client.read('/webpages/' + props.resource.resourceId + '/media/' + props.resource.subResourceId);
        }).then(function(res) {
            self.setState({media: res.data, initialized: true});
        });
    }
    componentDidMount() {
        var self = this;
        self.validate(self.props);
    }
    componentWillReceiveProps(new_props) {
        var self = this;
        self.validate(new_props);
    }
    handleChange(ev) {
        if (ev.formData.base64data && ev.formData.base64data.indexOf(";name=") === -1) {
            ev.formData.base64data = ev.formData.base64data.replace(";base64", `;name=${ev.formData.name};base64`);
        }
        this.setState({media: ev.formData});
    }
    save() {
        var self = this;
        if (typeof(self.state.media._id) !== 'undefined') {
            this.props.client.update('/webpages/' + self.props.resource.resourceId + '/media/' + self.props.resource.subResourceId, this.state.media).then((update) => {
                var parts = location.hash.split('/');
                if (parts[parts.length - 1] !== update.data.name) {
                    parts[parts.length - 1] = update.data.name;
                    location.hash = parts.join('/');
                }
                this.setState({media: update.data});
            })
        } else {
            this.props.client.create('/webpages/' + self.props.resource.resourceId + '/media', this.state.media).then(function(update) {
                
            })
        }
    }
    render() {
        var self = this;
         
        if (self.state.initialized) {
            var uiSchema = {
                _id: {
                    "ui:widget": "hidden"
                },
                ref_product_id: {
                    "ui:widget": "hidden"
                },
                ref_targetgroup_id: {
                    "ui:widget": "hidden"
                },
                references_count: {
                    "ui:readonly": true
                },
                references: {
                    "ui:widget": "hidden"
                },
                url: {
                    "ui:widget": "hidden"
                },
                base64data: {
                    "ui:field": "ImageEditor"
                }
            };
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function() {
                        history.back();
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function() {
                        self.save();
                    }
                }
            }
            var isImage = this.state.media.name.toLowerCase().indexOf('.png') !== -1 || this.state.media.name.toLowerCase().indexOf('.jpg') !== -1 || this.state.media.name.toLowerCase().indexOf('.jpeg') !== -1 || this.state.media.name.toLowerCase().indexOf('.svg') !== -1 || this.state.media.name.toLowerCase().indexOf('.webp') !== -1 ;
            var fields = {
                ImageEditor: isImage ? ImageEditor : function() {return <span></span>}
            }

            return (
                <div className="row"  style={{paddingBottom: '40px'}}>
                    <div className="col-md-12">
                        <Form validator={validator} uiSchema={uiSchema} schema={self.state.schema} fields={fields} formData={self.state.media} onChange={(e) => self.handleChange(e)}>
                            <div></div>
                        </Form>
                    </div>
                    <FloatMenu params={menu_params}/>
                </div>
            )
        } else {
            return <div/>;
        }
    }
}
