import React, { Component } from "react";

export default class IFrame extends Component {
    constructor(props) {
        super(props);
        this.iframe = React.createRef();
        this.state = {
            initialized: false,
            url: null
        };
    }
    validate(props) {
        var self = this;
        self.setState({url: props.url, initialized: true});
    }
    componentDidMount() {
        var self = this;
        emitter.on('UPDATE_PREVIEW', (url) => {
            console.log('update preview');
            //self.validate(self.props);
            let iframe = this.iframe.current;
            if (iframe) {
                setTimeout(() => {
                    iframe.src = url;
                }, 500)
            }
        });
        self.validate(self.props);
    }
    componentWillReceiveProps(nextProps) {
        console.log("iframe", nextProps);
        var self = this;
        self.validate(nextProps);
    }
    render() {
        
        var self = this;
        if (self.state.initialized) {
            console.log('iframe', this.state);
            return <div style={{height: '100%', overflow: 'hidden'}}>
                <iframe 
                    id="preview-iframe" 
                    ref={this.iframe}
                    className="preview" 
                    src={self.state.url} 
                    style={{
                        width: '100%', 
                        height: '100%', 
                        border: 'none', 
                        position: 'relative'
                    }}
                ></iframe>
                </div>
        } else {
            return <div/>
        }
    }
}
