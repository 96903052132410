import 'tui-image-editor/dist/tui-image-editor.css';
import ToastEditor from '@toast-ui/react-image-editor';

import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ImageEditor extends Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
    }
    componentDidMount() {
        const imageEditor = this.editorRef.current.getInstance();
        
        let changeCount = 0;
        imageEditor.on('undoStackChanged', (event) => {
            if (changeCount > 1) {
                var dataURL = imageEditor.toDataURL();
                this.props.onChange(dataURL);
            }
            changeCount++; // workaround
            
        });
    }
    render() {
        return <ToastEditor
            ref={this.editorRef}
            includeUI={{
                loadImage: {
                    path: this.props.formData + "?random=" + new Date().getTime(),
                    name: 'SampleImage'
                },
                theme: {
                    'common.bi.image': '',
                    'common.bisize.width': '0px',
                    'common.bisize.height': '0px',
                    'common.backgroundImage': '',
                    'common.backgroundColor': 'transparents',
                    'common.border': 'none',

                    'header.backgroundImage': 'none',
                    'header.backgroundColor': 'transparent',
                    'header.border': '0px',

                    // load button
                    'loadButton.backgroundColor': '#fff',
                    'loadButton.border': '1px solid #ddd',
                    'loadButton.borderRadius': '0px',
                    'loadButton.color': '#222',
                    'loadButton.fontFamily': "'Noto Sans', sans-serif",
                    'loadButton.fontSize': '12px',

                    // download button
                    'downloadButton.backgroundColor': '#fdba3b',
                    'downloadButton.border': '1px solid #fdba3b',
                    'downloadButton.borderRadius': '0px',
                    'downloadButton.color': '#fff',
                    'downloadButton.fontFamily': "'Noto Sans', sans-serif",
                    'downloadButton.fontSize': '12px',

                    // main icons
                    'menu.normalIcon.color': '#8a8a8a',
                    'menu.activeIcon.color': '#555555',
                    'menu.disabledIcon.color': '#434343',
                    'menu.hoverIcon.color': '#e9e9e9',
                    'menu.iconSize.width': '24px',
                    'menu.iconSize.height': '24px',

                    // submenu icons
                    'submenu.normalIcon.color': '#8a8a8a',
                    'submenu.activeIcon.color': '#e9e9e9',
                    'submenu.iconSize.width': '32px',
                    'submenu.iconSize.height': '32px',

                    // submenu primary color
                    'submenu.backgroundColor': '#1e1e1e',
                    'submenu.partition.color': '#3c3c3c',

                    // submenu labels
                    'submenu.normalLabel.color': '#8a8a8a',
                    'submenu.normalLabel.fontWeight': 'lighter',
                    'submenu.activeLabel.color': '#fff',
                    'submenu.activeLabel.fontWeight': 'lighter',

                    // checkbox style
                    'checkbox.border': '0px',
                    'checkbox.backgroundColor': '#fff',

                    // range style
                    'range.pointer.color': '#fff',
                    'range.bar.color': '#666',
                    'range.subbar.color': '#d1d1d1',

                    'range.disabledPointer.color': '#414141',
                    'range.disabledBar.color': '#282828',
                    'range.disabledSubbar.color': '#414141',

                    'range.value.color': '#fff',
                    'range.value.fontWeight': 'lighter',
                    'range.value.fontSize': '11px',
                    'range.value.border': '1px solid #353535',
                    'range.value.backgroundColor': '#151515',
                    'range.title.color': '#fff',
                    'range.title.fontWeight': 'lighter',

                    // colorpicker style
                    'colorpicker.button.border': '1px solid #1e1e1e',
                    'colorpicker.title.color': '#fff'
                },
                menu: ['resize', 'crop', 'filter', 'rotate', 'flip', 'draw', 'shape', 'icon', 'text'],
                initMenu: 'resize',
                uiSize: {
                    width: '100%',
                    height: '700px',
                },
                menuBarPosition: 'right',
            }}
            cssMaxHeight={500}
            cssMaxWidth={1000}
            onChange={(e) => console.log(e)}
            selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
            }}
            usageStatistics={false} />
    }
}