import Card from './card';

import React, { Component } from "react";
import e from 'cors';


export default class GroupsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            search: '',
            sort: 'ASC',
            initialized: false,
            updated: []
        };
    }

    validate(props) {
        emitter.emit('SHOW_LOADING');
        var request = (group, resource, override) => {
            return new Promise((done, failed) => {
                var url = '/webpages/' + group._id + '/' + (override ? override : resource);
                this.props.client.read(url)
                    .then((res) => {
                        group[resource] = res.data;
                        done();
                    })
                    .catch(failed);
            });
        }
        var all = (groups, resource, override) => {
            var all = [];
            groups.forEach((group) => {
                all.push(request(group, resource, override));
            })
            return Promise.all(all);
        }
        //TODO paginated services
        var aggregated_groups = {};
        this.props.client.read('/webpages/?fields=automated,name&skip=0&limit=300').then((groups) => {
            aggregated_groups = groups.data;
            aggregated_groups = aggregated_groups.filter((group) => {
                return group.automated === true;
            });
            return all(aggregated_groups, 'domains', 'domains?fields=main_domain,deactivated');
        }).then(() => {
            aggregated_groups = aggregated_groups.filter((group) => {
                return group.domains.deactivated === false;
                //return true;
            });
            this.setState({ groups: aggregated_groups, search: '', searchedGroups: [], sort: 'ASC', initialized: true });
            var current_date = new Date();
            /*var date_formatted = current_date.getFullYear() + '-' + current_date.getMonth();
            return all(aggregated_groups, 'statistics', 'analytics/year/' + date_formatted + '/visits-summary');
            //return;
        }).then(() => {*/
            //DANGER: only automated pages (other disabled for issue)
            /*this.setState({ groups: aggregated_groups });
            return all(aggregated_groups, 'contacts', 'contacts/count');
        }).then(() => {
            this.setState({ groups: aggregated_groups });
            return all(aggregated_groups, 'page', 'page/count');
        }).then(() => {
            this.setState({ groups: aggregated_groups });
            return all(aggregated_groups, 'blog', 'blog/count');
        }).then(() => {
            this.setState({ groups: aggregated_groups, search: '', sort: 'ASC', initialized: true });*/
            emitter.emit('HIDE_LOADING');
        });
    }
    componentDidMount() {
        this.validate(this.props);
    }
    componentWillReceiveProps(new_props) {
        this.validate(new_props);
    }
    componentDidUpdate(prevProps, prevState) {
        var self = this;
        if (typeof (window) !== 'undefined') {
            $('canvas.sizes-bar-chart').each((idx) => {
                var pages_size = parseInt($(this).data('pages-size'));
                var blogs_size = parseInt($(this).data('blogs-size'));
                var ctx = this;
                var chart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ["Pages", "Blogs"],
                        datasets: [{
                            label: '# of Votes',
                            backgroundColor: "#26B99A",
                            data: [pages_size, blogs_size]
                        }]
                    }
                });
            });
        }
    }
    componentWillUpdate(prevProps, prevState) {
        var self = this;
    }
    onGroupDetailsClick(ev) {
        var target = ev.currentTarget.dataset.target;
        var channel_id = ev.currentTarget.dataset.id;
        location.hash = '#webpages/' + channel_id;
    }
    onGroupDeleteClick(ev) {
        var self = this;
        var channel_id = ev.currentTarget.dataset.id;
        var channel_name = ev.currentTarget.dataset.name;
        console.log("on group delete 1");
        emitter.emit('SHOW_MODAL', {
            ok: () => {
                emitter.emit('SHOW_LOADING');
                console.log("on group delete 2");
                self.props.client.remove('/webpages/' + channel_id).then((res) => {
                    console.log('remove.res.status', res.status);
                    if (res.status === 200) {
                        self.setState({
                            groups: JSON.parse(JSON.stringify(self.state.groups)).filter((group) => { return channel_id !== group._id }),
                            searchedGroups: JSON.parse(JSON.stringify(self.state.searchedGroups)).filter((searched_group_id) => { return channel_id !== searched_group_id })
                        });
                    } else {
                        console.error(res.status, res.data);
                    }
                    emitter.emit('HIDE_LOADING');
                })
            },
            cancel: () => {
            },
            state: {
                title: 'Webseite löschen',
                body_title: '"' + channel_name + '"',
                body_content: 'Sind sie sicher, dass Sie diese Webseite löschen möchten?',
                cancel_title: 'Abbrechen',
                ok_title: 'Löschen'
            }
        });
    }
    onGroupCopyClick(ev, group) {
        var self = this;
        ev.preventDefault();
        var channel_id = ev.currentTarget.dataset.id;
        var channel_name = ev.currentTarget.dataset.name;
        var source_domain = group.domains.main_domain;
        emitter.emit('SHOW_MODAL', {
            ok: (formData) => {
                emitter.emit('SHOW_LOADING');
                var params = '';
                console.log("TRANSLATE", formData);
                if (formData.translations !== 'NONE') {
                    params = '&translate=' + formData.translations;
                }
                params += '&enable_ssl=' + (formData.enable_ssl && formData.enable_ssl === true ? 'true' : 'false');
                console.log('/webpages/' + channel_id + '/copy?domain=' + formData.domain + params);
                self.props.client.create('/webpages/' + channel_id + '/copy?domain=' + formData.domain + params).then((res) => {
                    if (res.status === 200) {
                        //self.validate(self.props);
                        location.hash = '#webpages/' + res.data._id;
                    } else {
                        var e = res.data;
                        var e = e.message ? e.message : (e.msg ? e.msg : 'Fehler beim Kopieren');
                        emitter.emit('SHOW_MODAL', {
                            ok: (formData) => {
                            },
                            cancel: () => {
                            },
                            state: {
                                title: 'Problem bei Erstellung',
                                cancel_title: 'Abbrechen',
                                ok_title: 'Ok',
                                body_content: e
                            }
                        });
                        console.error(res.status, res.data);
                    }
                    emitter.emit('HIDE_LOADING');
                })
            },
            cancel: () => {
            },
            state: {
                title: 'Webseite kopieren',
                cancel_title: 'Abbrechen',
                ok_title: 'Ok',
                form: {
                    schema: {
                        title: "",
                        type: "object",
                        properties: {
                            "domain": {
                                "title": "Neue Domain",
                                "type": "string"
                            },
                            "translations": { 
                                "title": "Übersetzen in",
                                "type": "string",
                                "enumNames": [ 
                                    "keine Übersetzung", 
                                    "englisch", "deutsch", "französisch", "spanisch", "italienisch", 
                                    "niederländisch", "polnisch", "albanisch", "baskisch", "weißrussisch", 
                                    "bulgarisch", "katalanisch", "kroatisch", "tschechisch", "dänisch", 
                                    "estnisch", "finnisch", "galicisch", "griechisch", "ungarisch", 
                                    "isländisch", "irisch", "lettisch", "litauisch", "mazedonisch", 
                                    "maltesisch", "norwegisch", "portugiesisch", "rumänisch", "russisch", 
                                    "serbisch", "slowakisch", "slowenisch", "schwedisch", "ukrainisch", 
                                    "walisisch", "türkisch"
                                ],
                                "enum": [
                                    "NONE", 
                                    "EN", "DE", "FR", "ES", "IT", 
                                    "NL", "PL", "SQ", "EU", "BE", 
                                    "BG", "CA", "HR", "CS", "DA", 
                                    "ET", "FI", "GL", "EL", "HU", 
                                    "IS", "GA", "LV", "LT", "MK", 
                                    "MT", "NO", "PT", "RO", "RU", 
                                    "SR", "SK", "SL", "SV", "UK", 
                                    "CY", "TR"
                                ],
                                "default": "NONE"
                            },
                            "enable_ssl": {
                                "title": "SSL einschalten",
                                "type": "boolean",
                                "default": true
                            }
                        }
                    },
                    formData: {
                        domain: source_domain + '-copy-' + channel_id
                    },
                    uiSchema: {
                        domain: {
                            "ui:widget": "domainselect"
                        }
                    }
                }
            }
        });
    }
    onSearchChange(e) {
        e.preventDefault();

        var searchedGroups = [];
        var newSearch = e && e.target && e.target.value ? e.target.value : undefined;
        if (newSearch && newSearch.trim().length > 0) {
            searchedGroups = [];
            var newSearchParts = newSearch.split('+');
            this.state.groups.forEach((group) => {
                var add = false;
                newSearchParts.forEach((searchPart) => {
                    searchPart = searchPart.trim();
                    if (group.name && group.name.toLowerCase().indexOf(searchPart.toLowerCase()) !== -1) {
                        add = true;
                    }
                    if (group.tags) {
                        group.tags.forEach(tag => {
                            if (tag !== null && tag.toLowerCase().indexOf(searchPart.toLowerCase()) !== -1) {
                                add = true;
                            }
                        });
                    }
                })
                if (add) {
                    searchedGroups.push(group._id);
                }
            })
        }
        this.setState({
            search: newSearch ? newSearch : '',
            searchedGroups
        });
    }
    updateWebserverConfiguration(e) {
        var self = this;
        emitter.emit('SHOW_LOADING');
        e.preventDefault();
        var groupsToUpdate = this.getVisibleGroups();
        var updated = [];
        var async_seq_delete = () => {
            var group = groupsToUpdate.pop();
            if (group !== undefined) {

                var domainsData = group.domains;
                if (domainsData.deactivated !== true && domainsData.automated === true) {
                    var timeoutDone = false;
                    var timeout = setTimeout(function () {
                        timeoutDone = true;
                        async_seq_delete();
                    }, 20000);
                    let group_url = '/webpages/' + group._id;
                    let start_time = new Date().getTime();
                    self.props.client.update(group_url + '/domains', domainsData).then((resp) => {

                        var title = 'Domain Update';
                        let end_time = new Date().getTime();
                        let msg = { action: title, name: group.name, status: resp.status, time: Math.round((end_time - start_time) / 1000) };
                        if (resp.status !== 200 && resp.data.msg) {
                            msg.msg = resp.data.msg;
                        }
                        updated.push(msg);
                        this.setState({ updated: updated });
                        if (timeoutDone !== true) {
                            clearTimeout(timeout);
                            async_seq_delete();
                        }
                    })
                } else async_seq_delete();
            } else {
                //this.setState({ updated: [] });
                emitter.emit('HIDE_LOADING');
            }
        }
        async_seq_delete();
    }
    getVisibleGroups() {
        return this.state.searchedGroups.length > 0 ? this.state.groups.filter(g => this.state.searchedGroups.indexOf(g._id) !== -1) : this.state.groups;
    }
    updateSearchedGroups(onlyDesign, e) {

        emitter.emit('SHOW_LOADING');
        e.preventDefault();
        var groupsToUpdate = this.getVisibleGroups();

        var updated = [];
        var async_seq_delete = () => {
            var group = groupsToUpdate.pop();
            if (group !== undefined) {
                var domainsData = group.domains;
                if (true || domainsData.deactivated !== true && domainsData.automated === true) {
                    var timeoutDone = false;
                    var timeout = setTimeout(function () {
                        timeoutDone = true;
                        async_seq_delete();
                    }, 20000);
                    let group_url = '/webpages/' + group._id;
                    let start_time = new Date().getTime();
                    self.props.client.update(group_url + (onlyDesign ? '/design' : '/config')).then((resp) => {

                        var title = (onlyDesign ? 'Design Update' : 'Vollständiges Update');
                        let end_time = new Date().getTime();
                        let msg = { action: title, name: group.name, status: resp.status, time: Math.round((end_time - start_time) / 1000) };
                        if (resp.status !== 200 && resp.data.msg) {
                            msg.msg = resp.data.msg;
                        }
                        updated.push(msg);
                        this.setState({ updated: updated });
                        if (timeoutDone !== true) {
                            clearTimeout(timeout);
                            async_seq_delete();
                        }
                    })
                } else async_seq_delete();
            } else {
                //this.setState({ updated: [] });
                emitter.emit('HIDE_LOADING');
            }
        }
        async_seq_delete();
    }
    sendProgressNotificationForSearchedGroups() {
        var self = this;
        var data = {};
        data.title = 'Neue Fortschrittmeldung';
        data.form = {
            schema: {
                title: "",
                type: "object",
                properties: {
                    "title": {
                        "title": "Titel",
                        "type": "string"
                    },
                    "effort": {
                        "title": "Verbrauchte Zeit in h",
                        "type": "number"
                    },
                    "mail_sent": {
                        "title": "Als Email an Kunden versenden",
                        "type": "boolean"
                    },
                    "description": {
                        "title": "Durchgeführte Arbeiten",
                        "type": "string"
                    }
                }
            },
            uiSchema: {
                description: {
                    "ui:widget": "textarea"
                }
            }
        };


        emitter.emit('SHOW_MODAL', {
            ok: (formData) => {
                emitter.emit('SHOW_LOADING');
                this.getVisibleGroups().forEach((group_id) => {
                    var updateRequests = [];
                    updateRequests.push(new Promise((resolve) => {
                        self.props.client.create('/webpages/' + group_id + '/progresses', formData).then(() => {
                            resolve();
                        }); emit('SHOW_LOADING');
                    }));
                    Promise.all(updateRequests).then(() => {
                        emitter.emit('HIDE_LOADING');
                    })
                });
            },
            cancel: function () {
            },
            state: data
        });
    }
    render() {
        if (this.state.initialized) {
            console.log(this.props.user);
            var admin_card = <span />;
            var update_card = <span />
            if (true) {
                admin_card = (
                    <Card key={-1} title={'Suche und schnelle Aktionen'} cols="12 12 12 12" height="120">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Sucheingabe..." onChange={(e) => this.onSearchChange(e)}></input>
                            <div className="input-group-btn">
                                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Aktion <span className="caret"></span></button>
                                {(() => {
                                    if (this.props.user.userId === 'support@sobek-innovations.de') {
                                        return <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="#" onClick={(e) => this.updateSearchedGroups(true, e)}>Aktualisierung Layout</a></li>
                                            <li><a href="#" onClick={(e) => this.updateSearchedGroups(false, e)}>Vollständige Aktualisierung</a></li>
                                            <li><a href="#" onClick={(e) => this.updateWebserverConfiguration(e)}>Aktualisierung Domain u. Webserverkonfiguration</a></li>
                                            <li><a href="#" onClick={(e) => this.sendProgressNotificationForSearchedGroups(e)}>Meldung versenden</a></li>
                                        </ul>
                                    }
                                })()}
                            </div>
                        </div>
                    </Card>
                )
                console.log('state chech', this.state, this.state.updated.length);
                if (this.state.updated.length > 0) {

                    var updatedItems = this.state.updated.map((item) => {
                        return (
                            <li>
                                <a>
                                    <span>
                                        <span>{item.action} // {item.name}</span>
                                        <span className="time">{item.time} secs</span>
                                    </span>
                                    <span className="message">
                                        Status: {item.status}<br />
                                        Message: {item.msg ? item.msg : 'Done'}
                                    </span>
                                </a>
                            </li>
                        );

                    })
                    update_card = (<Card key={-2} title={'Update Status'} cols="12 12 12 12">
                        <ul className="list-unstyled msg_list">
                            {updatedItems}
                        </ul>
                    </Card>
                    );
                }
            }

            return (
                <div className="row">
                    {admin_card}
                    {update_card}
                    {this.getVisibleGroups().map((group, i) => {
                        var group_info = <span></span>;
                        var group_actions = <span></span>;
                        var logo = <span></span>;
                        var deactivated_info = '';
                        var deactivated = group.domains && group.domains.deactivated && group.domains.deactivated === true;
                        if (deactivated) {
                            deactivated_info = <h3>Webseite ist deaktiviert</h3>;
                        }

                        /*group_info = (
                            <ul className="list-inline count2">
                                <li>
                                    <h3>{group.page ? group.page.count : <i className="fa fa-pulse fa-spinner"></i>}</h3>
                                    <span>Seiten</span>
                                </li>
                                <li>
                                    <h3>{group.blog ? group.blog.count : <i className="fa fa-pulse fa-spinner"></i>}</h3>
                                    <span>Artikel</span>
                                </li>
                                <li>
                                    <h3>{group.contacts ? group.contacts.count : <i className="fa fa-pulse fa-spinner"></i>}</h3>
                                    <span>Kontakte</span>
                                </li>
                            </ul>
                        );*/
                        logo = (
                            <div>
                                {deactivated_info}
                                {deactivated !== true &&
                                    <div style={{ display: 'flex', 'flexWrap': 'wrap', 'justifyContent': 'center', 'alignItems': 'center', height: '180px', 'padding': '10px 20px' }}>
                                        <img style={{ 'maxHeight': '100%', 'maxWidth': '100%' }} src={'https://' + group.domains.main_domain + '/media/logo.png'} />
                                    </div>
                                }
                            </div>
                        );
                        group_actions = (

                            <div className="btn-group btn-group-justified">
                                {(global.is_super_admin || global.is_admin) && <a style={{}} className="btn-webpage-open btn btn-default" href={'https://' + group.domains.main_domain} target="_blank"><i className="fa fa-external-link"></i></a>}
                                <a style={{}} className="btn-webpage-edit btn btn-default" href={global.is_customer && global.is_contacts ? '#webpages/' + group._id + '/contacts' : '#webpages/' + group._id}><i className="fa fa-edit"></i></a>
                                {(global.is_super_admin || global.is_admin) && <a style={{}} className="btn-webpage-copy btn btn-default" data-id={group._id} onClick={(e) => this.onGroupCopyClick(e, group)}><i className="fa fa-copy"></i></a>}
                                {(global.is_super_admin || global.is_admin) && <a style={{}} className="btn-webpage-delete btn btn-danger" data-id={group._id} data-name={group.name} onClick={(e) => this.onGroupDeleteClick(e)}><i className="fa fa-times"></i></a>}
                            </div>


                        );


                        return (
                            <Card key={i} title={group.domains.main_domain} additionalClass="webpage-card" cols="3 4 6 12" height="300" id={"webpage-" + group._id}>
                                {logo}
                                <div className="flex">

                                    {group_info}
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    {group_actions}
                                </div>
                            </Card>
                        )
                    })}
                </div>
            )
        } else {
            return <div />;
        }
    }
}

