import React, { Component } from "react";

export default class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }
    componentWillReceiveProps(ev) {
        var self = this;
        self.render();
    }
    componentDidMount() {
        var self = this;
        emitter.on('SHOW_LOADING', function (view) {
            self.setState({loading: true}); 
        });
        emitter.on('HIDE_LOADING', function (view) {
            setTimeout(() => {
                self.setState({loading: false});
            }, 500) 
        });  
    }
    render() {
        var self = this;
        var style = {
            display: self.state.loading ? 'block' : 'none'
        }
        return (
            <div className="loading-wrapper" style={style}>
                <div className="loading-pulse"></div>
            </div>
        );
    }
}
