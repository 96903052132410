

import Form from '@rjsf/core';
import FloatMenu from './float-menu';
import React, { Component } from "react";

export default class ContactEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {schema:null, contact:null, initialized: false};
    }
    validate(props) {
        var self = this;
        props.client.read('/schema/contacts').then(function(schema) {
            self.setState({schema: schema.data});
            return props.client.read('/webpages/' + props.resource.resourceId + '/contacts/' + props.resource.subResourceId);
        }).then(function(contact) {
            self.setState({contact: contact.data, initialized: true});
        });
    }
    componentDidMount() {
        var self = this;
        self.validate(self.props);
    }
    componentWillReceiveProps(new_props) {
        var self = this;
        self.validate(new_props);
    }
    handleChange(ev) {
        this.setState({contact: ev.formData});
    }
    save() {
        var self = this;
        if (typeof(self.state.contact._id) !== 'undefined') {
            self.props.client.update('/webpages/' + self.props.resource.resourceId + '/contact/' + self.props.resource.subResourceId, this.state.contact).then(function(update) {
                //console.log(update);
            })
        } else {
            self.props.client.create('/webpages/' + self.props.resource.resourceId + '/contact', this.state.contact).then(function(update) {
                //console.log(update);
            })
        }
    }
    render() {
        var self = this;
        
        if (self.state.initialized) {
            var uiSchema = {
                _id: {
                    "ui:widget": "hidden"
                },
                ref_product_id: {
                    "ui:widget": "hidden"
                },
                ref_targetgroup_id: {
                    "ui:widget": "hidden"
                }
            };
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function() {
                        history.back();
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function() {
                        self.save();
                    }
                }
            }
            return (
                <div className="row"  style={{paddingBottom: '40px'}}>
                    <div className="col-md-12">
                        <Form uiSchema={uiSchema} schema={self.state.schema} formData={self.state.contact} onChange={(e) => self.handleChange(e)}>
                            <div></div>
                        </Form>
                    </div>
                    <FloatMenu params={menu_params}/>
                </div>
            )
        } else {
            return <div/>;
        }
    }
}
