import React, { Component } from "react";
import Overview from './overview';
import { InfinitySpin } from 'react-loader-spinner';

export default class FileWidgetExtended extends Component {


    constructor(props) {
        super(props);
        console.log('props value', props);
        this.state = { value: props.value, showSearch: false }
    }

    componentDidMount() {

    }


    onDeleteChange(event) {
        const { onChange } = this.props;
        this.setState({
            value: undefined
        }, () => onChange(undefined));
    }

    async onSearch() {
        if (this.state.external_image_search) {
            try {
                this.setState({ unsplash_loading: true }, async () => {
                    var data = await global.client.read('/images/unsplash?search=' + this.state.external_image_search);
                    setTimeout(() => {
                        this.setState({ external_images: data.data, unsplash_loading: false });
                    }, 1500)
                });

            } catch (e) {
                console.error(e);
            }
        }
    }

    upload(image_data, cb) {
        return new Promise((done) => {
            var image = new Image();
            image.crossOrigin = 'Anonymous';
            var self = this;
            image.onload = async function () {
                var canvas = document.createElement('canvas');
                var context = canvas.getContext('2d');
                canvas.height = this.naturalHeight;
                canvas.width = this.naturalWidth;
                context.drawImage(this, 0, 0);
                var dataURL = canvas.toDataURL('image/jpeg');
                dataURL = dataURL.replace(";base64", `;name=${image_data.name};base64`);
                var res = await global.client.create('/webpages/' + global.groupId + '/media', { base64data: dataURL });
                done(res.data.url);
            };
            console.log('LOAD AND CONVERT', image_data, image_data.full);
            image.src = image_data.regular;
        });
    }

    render() {
        var MediaOverview = (props) => {
            return <Overview
                client={global.client}
                isHierarchical={true}
                resource={{
                    resourceName: global.resource.resourceName,
                    resourceId: global.resource.resourceId,
                    subResourceName: 'media'
                }}
                in_modal={true}
                onChange={(data) => this.props.onChange('/media/' + data.name)}>
            </Overview>
        }
        var searchComponent = <span></span>;
        var unsplashContent = undefined;
        if (this.state.unsplash_loading === true) {
            unsplashContent = <td style={{ textAlign: 'center', width: '100%' }}>
                <InfinitySpin
                    width='200'
                    color="#2A3F54"
                />
            </td>
        } else if (this.state.external_images) {
            unsplashContent = this.state.external_images.map((image) => {
                return <td><img onClick={(e) => {
                    this.setState({ external_image_selected: image });
                }} style={{ border: this.state.external_image_selected && this.state.external_image_selected.id === image.id ? '1px solid cyan' : 'none', height: "100px", marginRight: '15px', marginTop: '15px', marginTop: '15px' }} src={image.thumb} /></td>
            })
        }
        if (this.state.showSearch) {
            searchComponent = <div className="col-md-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Auf Unsplash suchen..." onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            this.onSearch()
                        }
                    }} onChange={e => this.setState({ external_image_search: e.target.value })} />
                    <span className="input-group-btn">
                        <button className="btn btn-default" type="button" onClick={e => this.onSearch()}>Suchen</button>
                    </span>
                </div>
                <div style={{ overflowX: 'scroll', paddingBottom: '20px', paddingTop: '10px' }}>
                    <table style={{ width: '100%' }}>
                        <tr>
                            {unsplashContent}
                        </tr>
                    </table>
                </div>
                <button className="btn btn-block btn-default" style={{ display: (this.state.external_image_selected ? 'block' : 'none') }}
                    onClick={async (e) => { 
                        var url = await this.upload(this.state.external_image_selected);
                        this.props.onChange(url)
                    }}>Übernehmen</button>
            </div>
        }
        var img = <span />;
        if (this.props.value && this.props.value.length > 0) {
            img = <img style={{ height: '46px' }} src={'https://' + global.main_domain + '/' + this.props.value} />
        }
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="btn-group" role="group">
                        <button className="btn btn-default btn-upload" onClick={() => {
                            emitter.emit('SHOW_MODAL', {
                                ok: async (data) => {
                                    /*for (var img_data of data) {
                                        await client.create('/webpages/' + global.resource.resourceId + '/media', { base64data: img_data });
                                    }
                                    props.onChange();*/
                                    console.log('IMAGE SELECTED => ' + data);
                                    emitter.emit('HIDE_LOADING');
                                },
                                cancel: () => {
                                },
                                element: MediaOverview,
                                state: {
                                    title: 'Dateien hochladen',
                                    body_title: '',
                                    body_content: '',
                                    cancel_title: 'Abbrechen',
                                    ok_title: 'Ok',
                                    large: true
                                }
                            })
                        }}>Auswählen</button>
                        <button className="btn btn-danger btn-delete" style={{ display: 'auto' }} onClick={(e) => this.onDeleteChange()}>Löschen</button>
                        <button className="btn btn-success btn-unsplash" style={{ display: 'auto' }} onClick={(e) => {
                            this.setState({ showSearch: true });
                        }}>Unsplash</button>
                    </div>
                </div>
                <div className="col-md-6">
                    {img}
                </div>
                {searchComponent}
            </div>
        );
    }
}
