import Wizard from './wizard';
import GroupsDashboard from './groups-dashboard';
import GroupDashboard from './group-dashboard';
import GroupEdit from './group-edit';
import Overview from './overview';
import PageEdit from './page-edit';
import DomainsEdit from './domains-edit';
import MenuEdit from './menu-edit';
import UserEdit from './user-edit';
import DesignEdit from './design-edit';
import ContactEdit from './contact-edit';
import MediaEdit from './media-edit';
import BlogEdit from './blog-edit';
import GoogleAdwordsEdit from './integration-edit';

import React, { Component } from "react";

export default class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        if (Error.stackTraceLimit) Error.stackTraceLimit = 50;
        function preCheckHash() {
            if (location.hash === undefined || location.hash.length === 0 || location.hash === '#') {
                location.hash = '#webpages';
                return;
            }
        }
        if (location.hash && location.hash.length > 1) {
            var new_resource_uri = location.hash.substring(1);
            this.handleResourceURI(new_resource_uri);
        }
        window.addEventListener("hashchange", (hash) => {
            preCheckHash();
            var new_resource_uri = location.hash.substring(1);
            this.handleResourceURI(new_resource_uri);
        }, false);
        preCheckHash();
        
    }
    handleURIParts(resourceURI) {
        var result = {
            resourceName: resourceURI
        };
        if (resourceURI.indexOf('/') !== -1) {
            var resource_uri_splitted = resourceURI.split('/');
            result.resourceName = resource_uri_splitted[0];
            result.resourceId = resource_uri_splitted[1];
            if (resource_uri_splitted.length > 2) {
                result.subResourceName = resource_uri_splitted[2];
                if (resource_uri_splitted.length > 3) {
                    result.subResourceId = resource_uri_splitted[3];
                }
            }
        }
        if (resourceURI.indexOf('?') !== -1) {
            var resource_uri_splitted = resourceURI.split('?')[1].split('=');
            result.resourceName = hash_uri.split('?')[0];
            result.resourceParams = {};
            result.resourcePrams[resource_uri_splitted[0]] = resource_uri_splitted[1];
        }
        global.currentResource = result;
        return result;
    }
    handleResourceURI(resourceURI) {
        this.setState({
            resource: this.handleURIParts(resourceURI)
        });
    }
    handleChange(ev) {
        ev.preventDefault();
        //this.state[ev.target.getAttribute('name')] = ev.target.value;
        //console.log('ev', this.state);
    }
    view() {
        if (this.state.resource) {
            global.resource = this.state.resource;

            var resource = this.state.resource;

            var resourceName = resource.resourceName;
            var resourceId = resource.resourceId;
            var subResourceName = resource.subResourceName;
            var subResourceId = resource.subResourceId;
            var resourceParams = resource.resourceParams;

            var resourceUI = <span />;

            var defaultUIAttributes = {
                user: this.props.user,
                params: resourceParams,
                client: this.props.client,
                oauth_client: this.props.oauth_client,
                config: this.props.config,
                resource: resource,
                store: this.state.store,
                store_update: (view_store) => {
                    this.setState({store: {...this.state.store, ...view_store}});
                },
                isHierarchical: true
            }
            console.log('resource', resource, defaultUIAttributes);

            let initOverview = () => {
                defaultUIAttributes.filterOut = ['sections', 'ref_', '_id']
                resourceUI = <Overview {...defaultUIAttributes} />;
            }

            if (resourceName === "wizard") {
                resourceUI = <Wizard {...defaultUIAttributes} />;
            } else if (resource.resourceName === 'me') {
                resourceUI = <UserEdit {...defaultUIAttributes} />;
            } else if (resource.resourceName === 'accounts') {
                if (resourceId !== undefined) {
                    resourceUI = <UserEdit {...defaultUIAttributes} />;
                } else {
                    resource.clientType = 'oauth';
                    resource.defaultId = 'email';
                    defaultUIAttributes.isHierarchical = false;
                    initOverview();
                }
            } else if (resourceName === 'webpages') {
                console.log(1);
                if (subResourceName !== undefined) {

                    if (subResourceName === 'pages') {
                        if (subResourceId === undefined) {
                            initOverview();
                        } else {
                            resourceUI = <PageEdit {...defaultUIAttributes} />;
                        }
                    } else if (subResourceName === 'menu') {
                        resourceUI = <MenuEdit {...defaultUIAttributes} />;
                    } else if (subResourceName === 'design') {
                        resourceUI = <DesignEdit {...defaultUIAttributes} />;
                    } else if (subResourceName === 'posts') {
                        if (subResourceId === undefined) {
                            initOverview();
                        } else {
                            resourceUI = <BlogEdit {...defaultUIAttributes} />;
                        }
                    } else if (subResourceName === 'domains') {
                        resourceUI = <DomainsEdit {...defaultUIAttributes} />;
                    } else if (subResourceName === 'integration') {
                        resourceUI = <GoogleAdwordsEdit {...defaultUIAttributes} />;
                    } else if (subResourceName === 'contacts') {
                        if (subResourceId === undefined) {
                            initOverview();
                        } else {
                            resourceUI = <ContactEdit {...defaultUIAttributes} />;
                        }
                    } else if (subResourceName === 'media') {
                        if (subResourceId === undefined) {
                            initOverview();
                        } else {
                            resourceUI = <MediaEdit {...defaultUIAttributes} />;
                        }
                    } else if (subResourceName === 'webpage') {
                        resourceUI = <GroupEdit {...defaultUIAttributes} />;
                    } else {
                        initOverview();
                    }

                } else {
                    if (resourceId) {
                        resourceUI = <GroupDashboard {...defaultUIAttributes} />;
                    } else {
                        resourceUI = <GroupsDashboard {...defaultUIAttributes} />;
                    }
                }
            }
            return resourceUI;
        } else return <span />
    }
    componentDidUpdate() {
        emitter.trigger('HIDE_LOADING');
    }
    render() {
        this.current_view = this.view();
        return (
            <div className="right_col" role="main">
                <div className="page-title">
                    <div className="title_left">
                        <h3>{'Titel'}</h3>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div>
                    {this.current_view}
                </div>
            </div>
        );
    }
}


