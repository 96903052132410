import React, { Component } from "react";

import quickCreateSchemas from "./ui-schemas/quick-create-schemas";
export default [
    {
        title: 'Bearbeiten',
        icon: 'edit',
        type: 'row',
        func: (id, props) => {

            if (props.isHierarchical) {
                location.hash = '#webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName + '/' + id;
            } else {
                location.hash = '#' + props.resource.resourceName + '/' + id;
            }
        },
    },
    {
        title: 'Kopieren',
        icon: 'copy',
        type: 'row',
        func: (id, props) => {
            let client = props.resource.clientType === 'oauth' ? props.oauth_client : props.client;
            emitter.emit('SHOW_MODAL', {
                ok: (formData) => {
                    emitter.emit('SHOW_LOADING');
                    var new_page_name = formData;
                    client.read('/webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName + '/' + id).then((page_res) => {
                        var page = page_res.data;
                        delete page._id;
                        delete page.ref_product_id;
                        delete page.ref_targetgroup_id;
                        delete page.__v;
                        page.landingpage = false;
                        page.seo.head_title = new_page_name;

                        client.create('/webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName, page).then(() => {
                            props.onChange();
                            emitter.emit('HIDE_LOADING');
                        })
                    });
                },
                cancel: () => {
                },
                state: {
                    title: 'Seite kopieren',
                    body_title: '',
                    body_content: '',
                    form: {
                        schema: {
                            title: "Neuer Seitenname",
                            type: "string",
                            required: true
                        }
                    },
                    cancel_title: 'Abbrechen',
                    ok_title: 'Kopieren'
                }
            });

        },
    },
    {
        title: 'Löschen',
        icon: 'trash',
        type: 'row',
        func: (id, props) => {

            emitter.emit('SHOW_MODAL', {
                ok: () => {
                    emitter.emit('SHOW_LOADING');
                    let removeURL = '';
                    if (props.isHierarchical) {
                        removeURL = '/webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName + '/' + id;
                    } else {
                        removeURL = '/' + props.resource.resourceName + '/' + id;
                    }
                    let client = props.resource.clientType === 'oauth' ? props.oauth_client : props.client;
                    client.remove(removeURL).then(() => {
                        props.onChange();
                        emitter.emit('HIDE_LOADING');
                    })
                },
                cancel: () => {
                },
                state: {
                    title: 'Element löschen',
                    body_title: '',
                    body_content: 'Sind sie sicher, dass Sie dieses Element löschen möchten?',
                    cancel_title: 'Abbrechen',
                    ok_title: 'Löschen'
                }
            });
        }
    },
    {
        title: 'Neu erstellen',
        type: 'all',
        func: (props) => {
            var data = {
                body_title: '',
                body_content: '',
                cancel_title: 'Abbrechen',
                ok_title: 'Ok',
                form: {
                }
            };

            let target_resource = props.resource.subResourceName ? props.resource.subResourceName : props.resource.resourceName;
            let schema = quickCreateSchemas[target_resource];
            
            data.title = schema.title;
            data.form = { schema: schema.schema };

            return <button className="btn btn-default" onClick={() => {
                let showModal = (data) => {
                    emitter.emit('SHOW_MODAL', {
                        ok: (formData) => {

                            var new_entity = {};
                            if (props.resource.subResourceName === 'pages' || props.resource.subResourceName === 'posts') {
                                new_entity = {
                                    seo: {
                                        head_title: formData
                                    },
                                    landingpage: false

                                }
                            } else if (props.resource.subResourceName === 'media') {
                                new_entity = { base64data: formData };
                            } else {
                                new_entity = formData;
                            }
                            emitter.emit('SHOW_LOADING');
                            if (props.isHierarchical) {
                                var targetURL = '/webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName;
                            } else {
                                var targetURL = '/' + props.resource.resourceName;
                            }

                            let client = props.resource.clientType === 'oauth' ? props.oauth_client : props.client;
                            client.create(targetURL, new_entity).then(() => {
                                props.onChange();
                                emitter.emit('HIDE_LOADING');
                            });
                        },
                        cancel: () => {
                        },
                        state: data
                    });
                }
                if (props.resource.clientType === 'oauth') {
                    props.client.read('/webpages/?skip=0&limit=1000&fields=name').then((groups) => {
                        data.form.schema.properties.targetgroup_ids.items.enum = [];
                        data.form.schema.properties.targetgroup_ids.items.enumNames = [];
                        groups.data.forEach(g => {
                            data.form.schema.properties.targetgroup_ids.items.enum.push(g._id);
                            data.form.schema.properties.targetgroup_ids.items.enumNames.push(g.name);
                        })

                        showModal(data);
                    })
                } else {
                    showModal(data);
                }
            }}>Neu erstellen</button>;
        }
    }
]