

module.exports = {
    ref_product_id: {
        "ui:widget": "hidden"
    },
    ref_targetgroup_id: {
        "ui:widget": "hidden"
    },
    ref_wordpress_id: {
        "ui:widget": "hidden"
    },
    landingpage: {
        "ui:widget": "hidden"
    },
    seo: {
        "ui:field": "SEOField"
    },
    sub_path: {
        "ui:widget": "hidden"
    },
    sidebar: {
        contents: {
            items: {
                _id: {
                    "ui:widget": "hidden"
                },
                data: {
                    content: {
                        'ui:widget': "html_editor"
                    }
                }
            }
        }
    },
    sections: {
        items: {
            _id: {
                "ui:widget": "hidden"
            },
            field_type: {
                classNames: "field-type"
            },
            slider_data: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    buttons: {
                        items: {
                            _id: {
                                "ui:widget": "hidden"
                            }
                        }
                    },
                    img: {
                        "ui:widget": "file_extended"
                    }
                }
            },
            header_data: {
                background_image: {
                    "ui:widget": "file_extended"
                },
                background_color: {
                    "ui:widget": "colorpicker"
                },
                background_gradient: {
                    start_color: {
                        "ui:widget": "colorpicker"
                    },
                    end_color: {
                        "ui:widget": "colorpicker"
                    }
                },
                columns: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    items: {
                        contents: {
                            items: {
                                _id: {
                                    "ui:widget": "hidden"
                                },
                                type: {
                                    "ui:widget": "hidden"
                                },
                                url: {
                                    "ui:widget": "file_extended"
                                },
                                html: {
                                    "ui:widget": "html_editor"
                                }

                            }
                        }
                    }
                }
            },
            image_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                img: {
                    "ui:widget": "file_extended"
                }
            },
            header_data: {
                background_image: {
                    "ui:widget": "file_extended"
                },
                background_color: {
                    "ui:widget": "colorpicker"
                },
                background_gradient: {
                    start_color: {
                        "ui:widget": "colorpicker"
                    },
                    end_color: {
                        "ui:widget": "colorpicker"
                    }
                },
                columns: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    items: {
                        classNames: "any-of-items",
                        contents: {
                            items: {
                                _id: {
                                    "ui:widget": "hidden"
                                },
                                type: {
                                    "ui:widget": "hidden"
                                },
                                url: {
                                    "ui:widget": "file_extended"
                                },
                                html: {
                                    "ui:widget": "html_editor"
                                },
                                classNames: "any-of-item"
                            }
                        }
                    }
                }
            },
            contact_data: {
                "ui:order": [
                    "config",
                    "titles",
                    "*"
                ],
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                }
            },
            text_contact_social_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                content: {
                    "ui:widget": "html_editor",
                    "ui:options": {
                        rows: 15
                    }
                },
                img: {
                    "ui:widget": "file_extended"
                },
                icons: {
                    items: {
                        icon: {
                            classNames: "col-xs-6"
                        },
                        socialLink: {
                            classNames: "col-xs-6"
                        }
                    }
                }
            },
            content_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                background_image: {
                    "ui:widget": "file_extended"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                left_content: {

                    items: {
                        html: {
                            "ui:widget": "html_editor"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        }
                    }
                },
                right_content: {
                    items: {
                        html: {
                            "ui:widget": "html_editor"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        }
                    }
                }
            },
            text_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                content: {
                    "ui:widget": "html_editor"
                }
            },
            portfolio_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                filters: {
                    _id: {
                        "ui:widget": "hidden"
                    }
                },
                items: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        }
                    }
                }

            },
            text_image_horizontal_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                buttons: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        }
                    }
                },
                content: {
                    "ui:widget": "html_editor"
                },
                img: {
                    "ui:widget": "file_extended"
                }
            },
            text_image_vertical_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                boxes: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        content: {
                            "ui:widget": "html_editor"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        },
                        background_color: {
                            "ui:widget": "colorpicker"
                        },
                        text_color: {
                            "ui:widget": "colorpicker"
                        }
                    }
                }
            },
            imagecontentslider_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                background_img_url: {
                    "ui:widget": "file_extended"
                },
                slider: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        slider_body: {
                            "ui:widget": "html_editor"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        }
                    }
                }
            },
            tabs_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                tabs: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        content: {
                            "ui:widget": "html_editor"
                        }
                    }
                }
            },
            references_image_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                paginationColor: {
                    "ui:widget": "color"
                },
                references: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        },
                        content: {
                            "ui:widget": "html_editor"
                        }
                    }
                }
            },
            gallery_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                filter: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        }
                    }
                },
                images: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        sub_images: {
                            items: {
                                _id: {
                                    "ui:widget": "hidden"
                                },
                                img: {
                                    "ui:widget": "file_extended"
                                }
                            }
                        }
                    }
                }
            },
            lead_generator_data: {
                steps: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        step_items: {
                            items: {
                                _id: {
                                    "ui:widget": "hidden"
                                },
                                img: {
                                    "ui:widget": "file_extended"
                                }
                            }
                        }
                    }
                }
            },
            team_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                members: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        }
                    }
                }
            },
            price_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                prices: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        features: {
                            items: {
                                _id: {
                                    "ui:widget": "hidden"
                                }
                            }
                        }
                    }
                }
            },
            map_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                business_hours: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        }
                    }
                }
            },
            demo_request_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                bg_img: {
                    "ui:widget": "file_extended"
                }
            },
            news_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                _id: {
                    "ui:widget": "hidden"
                }
            },
            video_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                _id: {
                    "ui:widget": "hidden"
                }
            },
            opener_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                _id: {
                    "ui:widget": "hidden"
                },
                img: {
                    "ui:widget": "file_extended"
                }
            },
            cleaver_reach_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                _id: {
                    "ui:widget": "hidden"
                }
            },
            custom_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                html: {
                    "ui:widget": "textarea"
                },
                css: {
                    "ui:widget": "textarea"
                },
                js: {
                    "ui:widget": "textarea"
                }
            },
            accordion_data: {
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                accordion_elements: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        accordion_body: {
                            "ui:widget": "html_editor"
                        }
                    }
                }
            },
            extended_contact_data: {

                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                filds: {
                    items: {

                    }
                }
            },
            testimonial_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                testamonial: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        text: {
                            "ui:widget": "html_editor"
                        },
                        img: {
                            "ui:widget": "file_extended"
                        }
                    }
                }

            },
            cta_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                fadeColor: {
                    "ui:widget": "colorpicker"
                },
                content: {
                    "ui:widget": "html_editor"
                },
                img: {
                    "ui:widget": "file_extended"
                },
                button: {
                    _id: {
                        "ui:widget": "hidden"
                    }
                }
            },
            textboxen_simple_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                boxes: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        },
                        content: {
                            "ui:widget": "html_editor"
                        }
                    }
                }
            },
            textboxen_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                boxes: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        }, img: {
                            "ui:widget": "file_extended"
                        }, content: {
                            "ui:widget": "html_editor"
                        }
                    }
                }
            },

            about_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                image: {
                    img: {
                        "ui:widget": "file_extended"
                    },
                    description: {
                        "ui:widget": "html_editor"
                    }
                },
                description: {
                    "ui:widget": "html_editor"
                },
                about: {
                    description: {
                        "ui:widget": "textarea"
                    }
                }
            },

            google_place_reviews_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                hash: {
                    "ui:readonly": true
                },
                place: {
                    "ui:field": "GooglePlaceField"
                }

            },
            shopping_cart_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                }
            },
            shopping_product_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                description: {
                    "ui:widget": "html_editor"
                },
                img: {
                    "ui:widget": "file_extended"
                },
                details: {
                    desc: {
                        "ui:widget": "html_editor"
                    }
                }
            },
            slider_gallery_data: {
                _id: {
                    "ui:widget": "hidden"
                },
                config: {
                    "ui:field": "ConfigField"
                },
                titles: {
                    "ui:field": "TitlesField"
                },
                slides: {
                    items: {
                        _id: {
                            "ui:widget": "hidden"
                        }, 
                        images: {
                            items: {
                                img: {
                                    "ui:widget": "file_extended"
                                },
                            }
                        }
                    }
                }
            }
        }
    },
    custom: {
        css: {
            "ui:widget": "textarea"
        },
        script: {
            "ui:widget": "textarea"
        }
    }
};