import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import IFrame from './iframe';
import FloatMenu from './float-menu';
import ColorPicker from './color-picker';
import React, { Component } from "react";
var IFrameCommunicator = require('../libs/iframe-communication');

export default class DesignEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {schema:null, design:null, domain: null};
    }
    validate() {
        var self = this;
        this.props.client.read('/schema/design').then(function(schema) {
            self.setState({schema: schema.data});
        });
        this.props.client.read('/webpages/' + this.props.resource.resourceId + '/domains').then(function(domain) {
            self.setState({domain: domain.data.main_domain, domain_info: domain.data});
        });
        this.props.client.read('/webpages/' + this.props.resource.resourceId + '/design').then(function(design) {
            self.setState({design: design.data});
        });
    }
    componentDidMount() {
        var self = this;
        self.validate();
    }
    componentWillReceiveProps(ev) {
        var self = this;
        self.validate();
    }
    componentDidUpdate(prevProps, prevState) {
        var self = this;
    }
    componentWillUpdate(prevProps, prevState) { 
        var self = this;    
    }
    handleChange(ev) {
        this.setState({design: ev.formData});
    }
    save() {
        emitter.trigger('SHOW_LOADING');
        console.log('send design', this.state.design);
        this.props.client.update('/webpages/' + this.props.resource.resourceId + '/design', this.state.design).then(function(update) {
            new IFrameCommunicator('iframe').trigger({action: 'reload_css'});
            emitter.trigger('HIDE_LOADING');
        });
    }
    render() {
        var self = this; 
        
        var additional_widgets = { 
            colorpicker: ColorPicker
        };
        
        if (self.state.design !== null && self.state.domain !== null && self.state.schema !== null) {
            var uiSchema = {
                _id: {
                    "ui:widget": "hidden"
                },
                ref_product_id: {
                    "ui:widget": "hidden"
                },
                ref_targetgroup_id: {
                    "ui:widget": "hidden"
                },
                brands_colors: {
                    classNames: "row",
                    text_primary: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background_primary: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    text_secondary: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background_secondary: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    text_default: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background_default: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background_reversed: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                     background_alternative_area_color_one: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                     background_alternative_area_color_two: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    img_overflow_color: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    standard_opacity: {
                        "classNames": "col-xs-6"
                    }
                },
                heading_colors: {
                    classNames: "row",
                    default: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    sub_title: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    reversed: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    onimage: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background_onimage: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-12"
                    }
                },
                content_colors: {
                    classNames: "row",
                    paragraph_default: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    paragraph_reversed: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    text_onimage: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background_onimage: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    }
                },
                fonts: {
                    classNames: "row",
                    size_default: {
                        "classNames": "col-xs-12"
                    },
                    family_serif: {
                        "classNames": "col-xs-4"
                    },
                    family_heading: {
                        "classNames": "col-xs-4"
                    },
                    family_sub_heading: {
                        "classNames": "col-xs-4"
                    },
                    family_navigation: {
                        "classNames": "col-xs-4"
                    },
                    family_body: {
                        "classNames": "col-xs-4"
                    },
                    family_script: {
                        "classNames": "col-xs-4"
                    }
                },
                nav_colors: {
                    classNames: "row",
                    breakpoint: {
                        "classNames": "col-xs-4"
                    },
                    height: {
                        "classNames": "col-xs-4"
                    },
                    header_height: {
                        "classNames": "col-xs-4"
                    },
                    fluid: {
                        "classNames": "col-xs-4"
                    },
                    capitalization: {
                        "classNames": "col-xs-4"
                    },
                    social_media_links: {
                        "classNames": "col-xs-4"
                    },
                    design_type: {
                        "classNames": "col-xs-4"
                    },
                    alignment: {
                        "classNames": "col-xs-4"
                    },
                    font_size: {
                        "classNames": "col-xs-4"
                    },
                    default: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    hover: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6"
                    },
                    background_hover: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-6",
                    },
                    scroll_background: {
                        "ui:widget": "colorpicker",
                        "classNames": "col-xs-12"
                    },
                    logo_rendering_method: {
                        "classNames": "col-xs-4"
                    },
                    logo_padding_vertical: {
                        "classNames": "col-xs-4"
                    },
                    logo_additional_height: {
                        "classNames": "col-xs-4"
                    },
                    padding_vertical: {
                        "classNames": "col-xs-4"
                    },
                    padding_btn_vertical: {
                        "classNames": "col-xs-4"
                    },
                    margin_between: {
                        "classNames": "col-xs-4"
                    },                    
                    top_menu: {
                        "classNames": "col-xs-12 row",
                        fluid: {
                            "classNames": "col-xs-12"
                        },
                        background_color: {
                            "ui:widget": "colorpicker",
                            "classNames": "col-xs-4"
                        },
                        scroll_background: {
                            "ui:widget": "colorpicker",
                            "classNames": "col-xs-4"
                        },  
                        text_color: {
                            "ui:widget": "colorpicker",
                            "classNames": "col-xs-4"
                        },
                        font_size: {
                            "classNames": "col-xs-4"
                        },
                        height: {
                            "classNames": "col-xs-4"
                        },
                        margin_between: {
                            "classNames": "col-xs-4"
                        }

                    }
                }
            };
            
            var webpage_url = (self.state.domain_info.enable_ssl ? 'https' : 'http') + '://' + self.state.domain + '/';
            webpage_url = webpage_url + '?live&notrack';
            
            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function() {
                        history.back();
                    }
                },
                ok: {
                    title: 'Speichern',
                    action: function() {
                        self.save();
                    }
                }
            }
            
            return (
                <div className="row">
                    <div className="col-md-6 fix-form">
                        <Form validator={validator} widgets={additional_widgets} uiSchema={uiSchema} schema={self.state.schema} formData={self.state.design} onChange={(e) => self.handleChange(e)}>
                            <div></div>
                        </Form>
                    </div>
                    <div className="col-md-6 preview">
                        <IFrame url={webpage_url}/>
                    </div>
                    <FloatMenu params={menu_params}/>
                </div>
            )
        } else {
            return <div/>;
        }
    }
}