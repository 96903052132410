import React, { Component } from "react";
import colConfig from "./ui-schemas/overview-fields";
import FloatMenu from './float-menu';
import overviewStandardActions from "./overview-standard-actions"
import overviewMediaActions from "./overview-media-actions"

import './overview.scss';
export default class Overview extends Component {
    constructor(props) {
        super(props);
        this.flatModel = {};
        var limit = 12;
        if (props.resource.subResourceName === 'media') {
            if (!props.in_modal) {
                limit = 24;
            } else {
                limit = 8;
            }
        }
        this.state = { 
            tableSchema: {}, 
            collection: [], 
            properties: [], 
            search: '', 
            skip: 0, 
            limit, 
            count: 0, 
            modus: props.resource.subResourceName === 'media' ? 'blocks' : 'list' 
        };
        console.log("overview - constructor", this.state);
    }

    wildcartCheck(key, filters) {
        var result = false;
        for (var i = 0; i < filters.length; i++) {
            if (key.indexOf(filters[i]) !== -1) {
                result = true;
                break;
            }
        }
        return result;
    }

    validate(props) {
        let client = props.resource.clientType === 'oauth' ? global.oauth_client : global.client;
        let tableSchema = {};
        let count = 0;
        let resource_url = '/webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName;
        if (props.resource.clientType === 'oauth') resource_url = '/' + props.resource.resourceName;

        let onlySelection = props.onlySelection ? true : false;

        let newsletter = false
        if (global.is_customer && global.is_contacts && props.resource.subResourceName === 'contacts') {
            newsletter = true;
        }

        client.read('/schema/' + (props.isHierarchical ? props.resource.subResourceName : props.resource.resourceName)).then((res) => {
            let schema = res.data;

            this.traverse(
                schema,
                (key, value) => {
                    tableSchema[key] = value;
                },
                (value) => {
                    return value.type && value.type !== "object";
                },
                (key, value) => {
                    return this.wildcartCheck(key, ['_id', 'sections', 'sidebar']);
                }
            );
            var url = resource_url + '/count?';
            if (this.state.search && this.state.search.length > 0) url += '&search=' + this.state.search;
            if (newsletter) url += '&newsletter';
            return client.read(url);
        }).then((res) => {
            count = res.data.count;


            return client.read(resource_url + '?search=' + this.state.search + '&skip=' + this.state.skip + '&limit=' + this.state.limit + (newsletter ? '&newsletter' : ''));
        }).then((res) => {
            let resourceName = this.props.isHierarchical ? this.props.resource.subResourceName : this.props.resource.resourceName;


            this.setState({
                tableSchema: tableSchema,
                collection: res.data,
                properties: colConfig[resourceName],
                count,
                onlySelection
            });
        })
    }

    componentDidMount() {
        this.validate(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.validate(newProps);
    }

    traverse(o, extractFunc, filterFunc, excludeFunc) {
        for (var i in o) {
            //console.log('bbb', i, o);
            if (o[i] !== null) {
                let execute = () => {
                    if (filterFunc) {
                        if (filterFunc(o[i])) {
                            extractFunc.apply(this, [i, o[i]]);
                        }
                    } else {
                        extractFunc.apply(this, [i, o[i]]);
                    }
                    if (typeof o[i] == "object") {
                        //going one step down in the object tree!!
                        this.traverse(o[i], extractFunc, filterFunc, excludeFunc);
                    }
                };
                if (excludeFunc) {
                    if (!excludeFunc(i, o[i])) {
                        execute();
                    }
                } else {
                    execute();
                }
            }
        }
    }



    getTemplate() {
        if (this.props.resource.subResourceName === 'media') {
            var actions = overviewMediaActions.filter(a => a.type === 'row');
        } else {
            var actions = overviewStandardActions.filter(a => a.type === 'row');
        }

        if (this.state.modus === 'list') {
            //REBUILD
            let theadElements = []
            this.state.properties.forEach((name, idx) => {
                let property = this.state.tableSchema[name];
                if (!this.props.in_modal) {
                    theadElements.push(<th className={idx !== 0 ? 'hide-lt-lg' : ''}>{property.title ? property.title : name}</th>);
                } else {
                    if (idx === 0) theadElements.push(<th>{property.title ? property.title : name}</th>);
                }
            })
            theadElements.unshift(<th>Auswahl</th>)
            for (let i = 0; i < actions.length; i++) {
                var action = actions[i];
                theadElements.push(<th>{action.title}</th>)
            }
            var thead = <thead>
                <tr>
                    {theadElements}
                </tr>
            </thead>;
            var tbodyElements = this.state.collection.map((object) => {
                let flatValues = {};

                this.traverse(
                    object,
                    (key, value) => {
                        flatValues[key] = value;
                    },
                    (e) => {
                        return typeof (e) !== 'object';
                    },
                    (key, value) => {
                        return this.wildcartCheck(key, ['_id', 'sections', 'sidebar', '__schema_version']);
                    }
                );
                let cells = [];
                this.state.properties.forEach((name, i) => {
                    if (!this.props.in_modal) {
                        cells.push(<td className={(i > 0 ? 'hide-lt-lg' : '') + ' ' + name}>{flatValues[name]}</td>);
                    } else {
                        if (i === 0) cells.push(<td className={name}>{flatValues[name]}</td>);
                    }
                });

                let targetId = this.props.resource.subResourceName === 'media' ? object.name : object._id;
                if (this.props.resource.defaultId) {
                    targetId = object[this.props.resource.defaultId];
                }

                cells.unshift(<td><div className={"form-check form-check-inline"} >
                    <input id="checkbox2" type="checkbox" checked={this.state.selectedItemId === targetId} />
                </div></td>);
                for (let i = 0; i < actions.length; i++) {
                    var action = actions[i];
                    
                    var func = () => {
                        var id = targetId;
                        var params = {
                            resource: this.props.resource,
                            isHierarchical: this.props.isHierarchical,
                            client: this.props.client,
                            oauth_client: this.props.oauth_client,
                            clientType: this.props.clientType,
                            onChange: () => this.validate(this.props)
                        };
                        
                        actions[i].func(id, params);
                    }

                    cells.push(<td className="text-center"><div className="form-check form-check-inline">
                        <button data-id={targetId} className="btn btn-default" onClick={func}><i class={'fa fa-' + actions[i].icon}></i><span>{action.title}</span></button>
                    </div></td>);
                }
                return <tr onClick={() => {
                    
                    this.setState({ selectedItemId: this.state.selectedItemId !== targetId ? targetId : undefined }, () => {
                        if (this.props.in_modal && this.state.collection) {
                            var result = {};
                            this.state.collection.forEach((object) => {
                                let targetId = this.props.resource.subResourceName === 'media' ? object.name : object._id;
                                if (targetId === this.state.selectedItemId) result = object;
                            })
                            this.props.onChange(result);
                        };
                    })
                }}>{cells}</tr>
            });
            return <table className={"table table-striped table-hover"}>
                {thead}
                <tbody>
                    {tbodyElements}
                </tbody>
            </table>;
        } else {
            var cards = this.state.collection.map((object) => {
                let targetId = this.props.resource.subResourceName === 'media' ? object.name : object._id;
                if (this.props.resource.defaultId) {
                    targetId = object[this.props.resource.defaultId];
                }
                var isImage = object.name.toLowerCase().indexOf('.png') !== -1 || object.name.toLowerCase().indexOf('.jpg') !== -1 || object.name.toLowerCase().indexOf('.jpeg') !== -1 || object.name.toLowerCase().indexOf('.svg') !== -1 || object.name.toLowerCase().indexOf('.webp') !== -1 ;
                if (isImage) {
                    var info = <img style={{}} src={object.url + '?random=' + new Date().getTime()}></img>
                } else {
                    var info = <i className="fa fa-file-o"></i>
                }
                
                return <div className={'gallery-card ' + (!this.props.in_modal ? 'col-sm-6 col-md-3 col-lg-2 col-xl-1' : 'col-sm-6 col-md-4 col-lg-3 col-xl-2') + (this.state.selectedItemId === targetId ? ' selected' : '')}  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ selectedItemId: this.state.selectedItemId !== targetId ? targetId : undefined }, () => {
                        if (this.props.in_modal &&this.state.collection) {
                            var result = {};
                            this.state.collection.forEach((object) => {
                                let targetId = this.props.resource.subResourceName === 'media' ? object.name : object._id;
                                if (targetId === this.state.selectedItemId) result = object;
                            })
                            this.props.onChange(result);
                        };
                    })
                }}>
                    {info}
                    <div>
                        {!this.props.in_modal && actions.map((action) => {
                            let targetId = this.props.resource.subResourceName === 'media' ? object.name : object._id;
                            if (this.props.resource.defaultId) {
                                targetId = object[this.props.resource.defaultId];
                            }
                            var func = () => {
                                var id = targetId;
                                var params = {
                                    resource: this.props.resource,
                                    isHierarchical: this.props.isHierarchical,
                                    client: this.props.client, 
                                    oauth_client: this.props.oauth_client,
                                    clientType: this.props.clientType,
                                    onChange: () => this.validate(this.props)
                                };
                                action.func(id, params);
                            }   
                            
                            return <button data-id={object.name} className="btn btn-default" onClick={func}><i class={'fa fa-' + action.icon}></i></button>;
                        })}
                    </div>
                </div>;
            });
            return <div className="row gallery">{cards}</div>;

        }
    }

    getSearch() {
        var buttons = [];
        if (this.props.resource.subResourceName === 'media') {
            buttons = [
                <span className="input-group-btn">
                    <button type="button" className={"btn btn-default " + (this.state.modus === 'blocks' ? 'active' : '')} onClick={() => this.setState({ modus: 'blocks' })}>
                        <i className={"fa fa-th "}></i>
                    </button> 
                </span>,
                <span className="input-group-btn">
                    <button type="button" className={"btn btn-default " + (this.state.modus === 'list' ? 'active' : '')} onClick={() => this.setState({ modus: 'list' })}>
                        <i className={"fa fa-list " }></i>
                    </button>
                </span>
            ]
        }
        var search = <div className="input-group">
            {buttons}
            <input type="text" className="form-control" placeholder="Suchbegriff..." value={this.state.search}
                onChange={e => this.setState({ search: e.target.value })}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        this.setState({ search: e.target.value }, () => this.validate(this.props));
                    }
                }}></input>
            <span className="input-group-btn">
                <button className="btn btn-default" type="button" onClick={e => this.validate(this.props)}>Suche</button>
            </span>

        </div>;
        return search;
    }

    changeView(viewElement) {
        this.temporaryViewElement = viewElement.element;
        this.temporaryViewOnChange = viewElement.onChange;
    }

    getMainButtons() {
        if (this.props.resource.subResourceName === 'media') {
            var actions = overviewMediaActions.filter(a => a.type === 'all');
        } else {
            var actions = overviewStandardActions.filter(a => a.type === 'all');
        }
        var params = {
            resource: this.props.resource,
            isHierarchical: this.props.isHierarchical,
            client: this.props.client,
            oauth_client: this.props.oauth_client,
            clientType: this.props.clientType,
            in_modal: this.props.in_modal,
            onChangeView: (view_element) => {
                this.changeView(view_element);
                this.forceUpdate();
            },
            onChange: () => {
                this.validate(this.props)
            }
        };
        return actions.map(a => a.func(params));
    }

    getPagination() {
        let pageSize = 0;
        if (this.state.limit < this.state.count) {
            pageSize = Math.ceil(this.state.count / this.state.limit);
        }
        let page_btns = [];
        var cur_page = this.state.skip / this.state.limit;

        page_btns.push(
            <li className={''}><a data-idx={'previous'} href="#" onClick={(e) => {
                e.preventDefault();
                if (cur_page !== 0) {
                    this.setState({ skip: (cur_page - 1) * this.state.limit }, () => {
                        this.validate(this.props);
                    });
                }
            }}>Vorherige</a></li>
        );
        console.log(cur_page);
        for (var i = cur_page; i < cur_page + 3 && i < pageSize; i++) {
            page_btns.push(<li className={this.state.skip / this.state.limit === i ? 'active' : ''}><a data-idx={i} href="#" onClick={(e) => {
                e.preventDefault();
                let idx = parseInt(e.currentTarget.dataset.idx);
                console.log('skip', idx, this.state.limit, idx * this.state.limit);
                this.setState({ skip: idx * this.state.limit }, () => {
                    this.validate(this.props);
                });
            }}>{i + 1}</a></li>);
        }
        page_btns.push(
            <li className={this.state.skip !== pageSize ? 'active' : ''}><a data-idx={'next'} href="#" onClick={(e) => {
                e.preventDefault();
                /*this.setState({ skip: idx * this.state.limit }, () => {
                    this.validate(this.props);
                });*/
            }}>Nächste</a></li>
        );
        var pagination = <nav aria-label="Page navigation example">
            <ul className="pagination" style={{ margin: "0px" }}>
                {page_btns}
            </ul>
        </nav>;
        return pagination;
    }

    getNav() {

        return <div className="row m-t-10 m-b-10">
            <div className={"col-sm-12 col-lg-4 search-col"}>
                {this.getSearch()}
            </div>
            <div className="col-sm-6 col-lg-4 actions-col">
                {this.getMainButtons()}
            </div>
            <div className={"col-sm-6 col-lg-4 pagination-col"}>
                {this.getPagination()}
            </div>
        </div>
    }

    render() {

        var menu_params = {
            cancel: {
                title: 'Zurück',
                action: function () {
                    history.back();
                }
            }
        }
        var menu = '';
        if (!this.props.in_modal) {
            var menu = <FloatMenu params={menu_params} />;
        }
        var contents = [];
        if (!this.temporaryViewElement) {
            contents.push(this.getNav());
            contents.push(this.getTemplate());
            contents.push(this.getNav());
            contents.push(menu);
        } else {
            var element = React.createElement(this.temporaryViewElement, {
                onChange: async (data) => {
                    await this.temporaryViewOnChange(data); 
                    delete this.temporaryViewElement;
                    delete this.temporaryViewOnChange;
                },
                onResetView: () => {
                    console.log('force update');
                    this.forceUpdate();
                }
            });
            contents.push(element);

        }
        return (
            <div className="p2g-overview">
                {contents}
            </div>
        )
    }
}


