import React, { Component } from "react";
var IFrameCommunicator = require('../libs/iframe-communication');
export default class EditMethods extends Component {
    constructor(props) {
        super(props);
        this.warnings = [];
        this.dangers = [];
        this.checks = {
            one_word_title: {
                notice: 'Titel enthält nur ein Wort.',
                target: 'head_title',
                type: 'danger',
                func: (title) => {
                    console.log('check', title);
                    return title && title.split(' ').length < 2;
                }
            },
            no_title: {
                notice: 'Kein Titel.',
                target: 'head_title',
                type: 'danger',
                func: (title) => {
                    return !title || (title && title.length === 0);
                }
            },
            short_title: {
                notice: 'Titel zu kurz.',
                target: 'head_title',
                type: 'danger',
                func: (title) => {
                    return title && title.length > 0 && title.length < 12;
                }
            },
            long_title: {
                notice: 'Titel zu lang. Max. Länge von 65 Zeichen überschritten.',
                target: 'head_title',
                type: 'warning',
                func: (title) => {
                    return title && title.length > 65;
                }
            },
            no_description: {
                notice: 'Keine Beschreibung.',
                target: 'head_description',
                type: 'danger',
                func: (desc) => {

                    return !desc || (desc && desc.trim().length === 0);
                }
            },
            short_description: {
                notice: 'Beschreibung zu kurz.',
                target: 'head_description',
                type: 'warning',
                func: (desc) => {

                    return desc && desc.trim().length < 30;
                }
            },
            long_description: {
                notice: 'Beschreibung zu lang.',
                target: 'head_description',
                type: 'warning',
                func: (desc) => {
                    return desc && desc.trim().length > 160;
                }
            }
        }
    }

    process_checks() {
        this.warnings = [];
        this.dangers = [];
        for (var check_key in this.checks) {
            var check = this.checks[check_key];
            console.log("BOCK5");
            var failed = check.func(this.storage.seo[check.target]);
            if (failed) {
                if (check.type === 'danger') {
                    this.dangers.push(check.notice);
                } else {
                    this.warnings.push(check.notice);
                }
            }

        }
        this.forceUpdate();
    }

    get_warnings() {
        if (this.warnings.length > 0) {
            return <div className="alert alert-warning"><span className="glyphicon glyphicon-warning-sign m-r-10"></span>{this.warnings.join(' ')}</div>
        } else {
            return <span></span>;
        }
    }

    get_dangers() {

        if (this.dangers.length > 0) {
            return <div className="alert alert-danger"><span className="glyphicon glyphicon-warning-sign m-r-10"></span>{this.dangers.join(' ')}</div>
        } else {
            return <span></span>;
        }

    }

    handleSectionChange(idx, ev) {
        //TODO fix reverse deleting on fast editing also at other edit views

        var section_data = null;
        var data_type = ev.formData.data_type;
        if (data_type === 'slider_data') {
            section_data = ev.formData.array;
        } else {
            section_data = ev.formData;
        }
        if (typeof (ev.formData.field_type) !== 'undefined') {
            //for standard pages
            var section_update = {
                _id: ev.formData._id,
                field_type: ev.formData.field_type
            };
            section_update[data_type] = section_data;
            this.storage.sections[idx] = section_update;
        } else {
            //for blog pages
            this.storage.sections[idx] = section_data;
            this.forceUpdate();
        }
        ///this.forceUpdate();
        
        //this.forceUpdate();
    }
    handleSectionMoveUp(idx, ev) {

        if (idx > 0) {
            var section = this.storage.sections[idx];
            var replace_section = this.storage.sections[idx - 1];

            this.storage.sections[idx] = replace_section;
            this.storage.sections[idx - 1] = section;
            this.forceUpdate();
        }

    }
    handleSectionMoveDown(idx, ev) {

        if (idx + 1 < this.storage.sections.length) {
            var section = this.storage.sections[idx];
            var replace_section = this.storage.sections[idx + 1];

            this.storage.sections[idx] = replace_section;
            this.storage.sections[idx + 1] = section;
            console.log('new section order', JSON.parse(JSON.stringify(this.storage)));
            this.forceUpdate();
        }
    }
    handleSectionDelete(idx, ev) {

        let removedSection = this.storage.sections.splice(idx, 1);
        console.log(removedSection);

        this.forceUpdate();
    }
    handleSectionCopy(idx, ev) {
        let cloned_section = JSON.parse(JSON.stringify(this.storage.sections[idx]));
        var process_remove_ids = (key, value, parent) => {
            if (key === '_id') delete parent[key];
        }
        this.traverse(cloned_section, process_remove_ids);
        //let removedSection = this.storage.sections.splice(idx, 1);


        localStorage.setItem('edit-storage', JSON.stringify({ section: cloned_section }));

        //this.forceUpdate();
    }
    handleSectionPaste(idx, ev) {

        //let removedSection = this.storage.sections.splice(idx, 1);
        //localStorage.setItem('edit-storage', JSON.stringify({section: cloned_section}));
        let data = localStorage.getItem('edit-storage');
        if (data !== null) {
            console.log(data);
            data = JSON.parse(data);
            this.storage.sections.splice(idx, 0, data.section);
            this.forceUpdate();
        }
    }
    handleSEOChange(formData) {
        console.log("handleSEOChange", formData);
        if (this.storage.auto_generated === true && this.storage.head_description !== formData.head_description) {
            delete formData.auto_generated;
        } 
        if (!formData.head_description || formData.head_description.length === 0) {
            //TODO auto create
        }
        var change = () => {
            this.storage.seo = formData;
            console.log('process change', this.storage.seo);
            this.process_checks();
        }
        var data = formData;
        //first upload image and save url
        if (data.head_img && data.head_img.indexOf('data:') === 0) {
            this.props.client.create('/webpages/' + this.storage.ref_targetgroup_id + '/media', {
                base64data: data.head_img
            }).then((response) => {
                data.head_img = response.data.url;
                change();
            });
        } else {
            change();
        }
    }
    handleURLChange(ev) {

        this.storage.sub_path = ev.formData;
    }
    handleProtected(ev) {

        this.storage.protected = ev.formData;
        this.proctectionChanged = true;

    }
    handleCustomChange(ev) {

        this.storage.custom = ev.formData;
    }
    handleSidebarChange(ev) {

        this.storage.sidebar = ev.formData;
    }
    handleVisibilityClick(id) {

        console.log("handleVisibilityClick", id, this.state.widget_visibilities);

        let changed_widget_visibilities = JSON.parse(JSON.stringify(this.state.widget_visibilities));
        var not_visible = typeof (changed_widget_visibilities[id]) === 'undefined' || changed_widget_visibilities[id] === false;

        Object.keys(changed_widget_visibilities).forEach(widget_id => {
            delete changed_widget_visibilities[widget_id];
        });


        if (not_visible) {
            changed_widget_visibilities[id] = true;

        } else {
            changed_widget_visibilities[id] = false;
        }

        var state = { widget_visibilities: changed_widget_visibilities };
        if (not_visible) {
            state.widget_active = id;
        }

        
        this.setState(state, () => {
            new IFrameCommunicator('.preview iframe').trigger({ action: 'scroll_to', target: id });
        });
        
    }

    traverse(o, func) {
        for (var i in o) {
            func.apply(this, [i, o[i], o]);
            if (o[i] !== null && typeof (o[i]) == "object") {
                //going on step down in the object tree!!
                this.traverse(o[i], func);
            }
        }
    }
}