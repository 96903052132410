module.exports = {
    'Slider': 'slider_data',
    'Tabs': 'tabs_data',
    'Text': 'text_data',
    'Portfolio': 'portfolio_data', 
    'DemoRequest': 'demo_request_data',
    'TextImageBlockVertical': 'text_image_vertical_data',
    'TextImageBlockHorizontal': 'text_image_horizontal_data',
    'Video': 'video_data',
    'ReferenceImages': 'references_image_data',
    'Prices': 'price_data',
    'CleaverReachIntegration': 'cleaver_reach_data',
    'Gallery': 'gallery_data',
    'LeadGenerator': 'lead_generator_data',
    'ContactInfoWithSocial': 'text_contact_social_data',
    'Contact': 'contact_data',
    'Team': 'team_data',
    'Map': 'map_data',
    'Custom': 'custom_data',
    'News': 'news_data',
    'Content': 'content_data',
    'Accordion':'accordion_data',
    'Image':'image_data',
    'ExtendedContact': 'extended_contact_data',
    'Kundenstimmen' : 'testimonial_data',
    'Opener': 'opener_data',
    'Counter': 'counter_data',
    'GooglePlaceReviews': 'google_place_reviews_data',
    'About': 'about_data',
    'TextboxenSimple':'textboxen_simple_data',
    'Cta':'cta_data',
    'Imagecontentslider':'imagecontentslider_data',
    'textbox_blog_data':"Blog Absatz",
    'Textboxen': 'textboxen_data',
    'Header': 'header_data',
    'ShoppingCart': 'shopping_cart_data',
    'ShoppingProduct': 'shopping_product_data',
    'SliderGallery': 'slider_gallery_data'
}