import React, { Component } from "react";

export default class PageEdit extends Component {
  render() {
    var self = this;
    var colClazz = 'col-md-' + this.props.cols + ' tile_stats_count';
    
    return (
        <div className={colClazz}>
            <span className="count_top"><i className="fa fa-user"></i> {self.props.title}</span>
            <div className="count">{self.props.current}</div>
            <span className="count_bottom"><i className="green">{self.props.last} </i> {self.props.last_title}</span>
        </div>
    );
  }
}