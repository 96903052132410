module.exports = {
    ref_product_id: {
        "ui:widget": "hidden"
    },
    ref_targetgroup_id: {
        "ui:widget": "hidden"
    },
    ref_wordpress_id: {
        "ui:widget": "hidden"
    },
    sub_path: {
        "ui:widget": "hidden"
    },
    seo: {
        "ui:field": "SEOField",
        head_img: {
            "ui:widget": "file_extended"
        }
    },
    main_img: {
        "ui:widget": "file_extended"
    },
    sections: {
        items: {
            "ui:order": [
                "titles",
                "config",
                "*"
            ],
            section_type: {
                "ui:widget": "hidden"
            },
            _id: {
                "ui:widget": "hidden"
            },
            titles: {
                "ui:field": "TitlesField"
            },
            config: {
                "ui:field": "ConfigField"
            },
            
            field_type: {
                classNames: "field-type"
            },
            content: {
                "ui:widget": "html_editor"
            },
            img: {
                "ui:widget": "file_extended"
            },
            left_content: {

                items: {
                    html: {
                        "ui:widget": "html_editor"
                    },
                    img: {
                        "ui:widget": "file_extended"
                    }
                }
            },
            right_content: {
                items: {
                    html: {
                        "ui:widget": "html_editor"
                    },
                    img: {
                        "ui:widget": "file_extended"
                    }
                }
            },
            buttons: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    }
                }
            },
            boxes: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    content: {
                        "ui:widget": "html_editor"
                    },
                    img: {
                        "ui:widget": "file_extended"
                    },
                    background_color: {
                        "ui:widget": "colorpicker"
                    },
                    text_color: {
                        "ui:widget": "colorpicker"
                    }

                }
            },
            tabs: {
                _id: {
                    "ui:widget": "hidden"
                },
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    content: {
                        "ui:widget": "html_editor"
                    }
                }
            },
            references: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    img: {
                        "ui:widget": "file_extended"
                    },
                    content: {
                        "ui:widget": "html_editor"
                    }
                }
            },
            filter: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    }
                }
            },
            images: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    sub_images: {
                        items: {
                            _id: {
                                "ui:widget": "hidden"
                            },
                            img: {
                                "ui:widget": "file_extended"
                            }
                        }
                    }
                }
            },
            members: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    img: {
                        "ui:widget": "file_extended"
                    }
                }
            },
            prices: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    features: {
                        items: {
                            _id: {
                                "ui:widget": "hidden"
                            }
                        }
                    }
                }
            },
            business_hours: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    }
                }
            },
            bg_img: {
                "ui:widget": "file_extended"
            },
            html: {
                "ui:widget": "textarea"
            },
            css: {
                "ui:widget": "textarea"
            },
            js: {
                "ui:widget": "textarea"
            },
            accordion_elements: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    accordion_body: {
                        "ui:widget": "html_editor"
                    }
                }
            },
            testamonial: {
                items: {
                    _id: {
                        "ui:widget": "hidden"
                    },
                    text: {
                        "ui:widget": "html_editor"
                    },
                    img: {
                        "ui:widget": "file_extended"
                    }
                }
            },
            button: {
                _id: {
                    "ui:widget": "hidden"
                }
            },
            image: {
                img: {
                    "ui:widget": "file_extended"
                },
                description: {
                    "ui:widget": "html_editor"
                }
            },
            about: {
                description: {
                    "ui:widget": "textarea"
                }
            },
            hash: {
                "ui:readonly": true
            },
            place: {
                "ui:field": "GooglePlaceField"
            },
            background_image: {
                "ui:widget": "file_extended"
            },
            background_color: {
                "ui:widget": "colorpicker"
            },
            background_gradient: {
                start_color: {
                    "ui:widget": "colorpicker"
                },
                end_color: {
                    "ui:widget": "colorpicker"
                }
            },
            columns: {
                _id: {
                    "ui:widget": "hidden"
                },
                items: {
                    contents: {
                        items: {
                            _id: {
                                "ui:widget": "hidden"
                            },
                            type: {
                                "ui:widget": "hidden"
                            },
                            url: {
                                "ui:widget": "file_extended"
                            },
                            html: {
                                "ui:widget": "html_editor"
                            }

                        }
                    }
                }
            }
        }
    }
};