import React, { Component } from "react";

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillReceiveProps(ev) {
    var self = this;
    this.forceUpdate();
  }
  render() {
    console.log('here');
    var self = this;
    var cols = this.props.cols.split(' ');
    var colClazz = (this.props.additionalClass ? this.props.additionalClass : '') + ' col-lg-' + cols[0] + ' col-md-' + cols[1] + ' col-sm-' + cols[2] + ' col-xs-' + cols[3];
    
    var styleData = {
        height: typeof(self.props.height) !== 'undefined' ? self.props.height + 'px': 'auto',
        maxHeight: typeof(self.props.height) !== 'undefined' ? self.props.height + 'px': 'auto'
    };
    
    return (
        <div id={this.props.id} className={colClazz}>
            <div className="x_panel" style={styleData}>
                <div className="x_title">
                    <h2 style={{whiteSpace: 'nowrap',overflow: 'hidden', width: "100%"}}>{this.props.title}</h2>
                    <div className="clearfix"></div>
                </div>
                <div className="x_content">
                    {this.props.children}
                </div>
            </div>
        </div>
    );
  }
}