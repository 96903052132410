import React, { Component } from "react";

export default class LeftNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channels: [],
            page_url: null,
            user: this.props.user,
            initialized: false
        };
    }
    validate() {
        var self = this;
        self.setState({ initialized: true});
    }
    componentDidMount() {
        var self = this;
        emitter.on('VALIDATE_TARGETGROUP', function () {
           self.validate();
        });
        self.validate();
    }
    switchView(id, target) {
        var self = this;
        var params = {
            id: id
        };
        if (target === 'page-edit') {
            self.props.client.read('/webpages/' + id + '/page').then(function(pages) {
                var landing_page_id = null;
                pages.forEach((page) => {
                    if (page.landingpage) {
                        landing_page_id = page._id;
                    } 
                });
                
                params.page_id = landing_page_id;
                emitter.emit('SWITCH_CONTENT_VIEW', {id: target, name: target, params: params});
            });
        } else if (target === 'wizard') { //TODO later scope based
            console.log('i am wizard');
            emitter.emit('SHOW_MODAL', {
                ok: function(formData) {
                    emitter.emit('SHOW_LOADING');
                    //{webpage_type: "manually", name: "test", domain_name: "test"}
                    
                    //now create channels group
                    if (formData.webpage_type !== 'automated') {
                        var group = null;
                        self.props.client.create('/webpage', {
                            name: formData.name, 
                            contact_mail: formData.mail,
                            automated: false,
                            targetGenerator: formData.targetGenerator ? formData.targetGenerator : 'html'
                        }).then(function(group_res) {
                            group = group_res.data;
                            console.log('group create', group);
                            var domain_data = {
                                main_domain: formData.domain_name + '.' + global.target_domain,
                                automated: false,
                                footer_domain: 'sobek-agency.de',
                                redirect_domains: []
                            };
                            console.log('domain create', domain_data);
                            return self.props.client.create('/webpages/' + group._id + '/domains', domain_data);
                        }).then(function(domain_res) {
                            console.log('group create', domain_res);
                            return self.props.client.create('/webpages/' + group._id + '/analytics', {});
                        }).then(function(analytics_res) {
                            console.log('analytics create', analytics_res);
                            emitter.emit('HIDE_LOADING');
                        })
                    } else {
                        emitter.emit('SWITCH_CONTENT_VIEW', {id: target, name: target, params: params});
                        emitter.emit('HIDE_LOADING');
                    }
                    
                },
                cancel: function() {
                },
                onChange: function(component) {
                    
                },
                state: {
                    title: 'Neue Webseite',
                    body_title: '',
                    body_content: '',
                    cancel_title: 'Abbrechen',
                    ok_title: 'Ok',
                    form: {
                        schema: {
                            type: 'object',
                            properties: {
                                webpage_type: {
                                    title: "Art der Webseite auswählen",
                                    type: "string",
                                    enum: ["automated", "manual"],
                                    enumNames: ["Automatisiert", "Manuell"],
                                    default: 'automated',
                                    required: true
                                },
                                targetGenerator: {
                                    title: "Generatorplattform",
                                    type: "string",
                                    enum: ["html", "wordpress"],
                                    enumNames: ["HTML Generator", "Wordpress CMS"],
                                    default: 'html',
                                    required: true
                                },
                                name: {
                                    title: "Webseitenname (nur bei manueller Installation)",
                                    type: "string",
                                    required: true
                                },
                                domain_name: {
                                    title: "Name der Domain (nur bei manueller Installation)",
                                    type: "string",
                                    required: true
                                },
                                mail: {
                                    title: "Kontaktadresse (nur bei manueller Installation)",
                                    type: "string",
                                    required: true
                                }
                            }
                        },
                        formData: {
                            webpage_type: 'automated'
                        }
                    }
                }
            });
        } else {
            emitter.emit('SWITCH_CONTENT_VIEW', {id: target, name: target, params: params});
        }
    }
    onClick(ev) {
        var id = ev.currentTarget.dataset.id;
        var target = ev.currentTarget.dataset.target;
        this.switchView(id, target);
    }
    onLogout(ev) {
        ev.preventDefault();
        emitter.emit('LOGOUT');
    }
    render() {
        var self = this;
        if (self.state.initialized) {
            var logo = '/static/' + self.props.config.app.type + '/img/' + self.props.config.app.type +  '-logo-negativ.svg';
            return (
                <div className="col-md-3 left_col menu_fixed">
                    <div className="left_col scroll-view">
                        <div className="navbar nav_title logo">
                            <img src={logo}/>
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
                            <div className="menu_section">
                                <ul className="nav side-menu">
                                    <li>
                                        <a data-target="groups-dashboard" className="groups-dashboard-link" href="#webpages">
                                            <i className="fa fa-home"></i> Übersicht
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#me">
                                            <i className="fa fa-user"></i> Meine Daten
                                        </a>
                                    </li>
                                    {global.is_super_admin &&
                                    <li>
                                        <a href="#accounts">
                                            <i className="fa fa-users"></i> Zugänge
                                        </a>
                                    </li>
                                    }
                                    {(global.is_super_admin || global.is_admin) &&
                                    <li>
                                        <a href="#wizard">
                                            <i className="fa fa-plus-circle"></i> Neue Webseite
                                        </a>
                                    </li>
                                     }
                                    <li>
                                        <a onClick={self.onLogout}>
                                            <i className="fa fa-sign-out"></i> Logout
                                        </a>
                                    </li>
                                </ul>
                                <div style={{textAlign: 'center', width:'100%', padding: '10px 10px 0 10px'}}>
                                
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-footer hidden-small">
                          
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}
