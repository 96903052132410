import HTMLEditor from './htmleditor';
import FileWidgetExtended from './FileWidgetExtended';
import ColorPicker from './color-picker';
import DomainSelect from './domain-select';
import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
var update = require('react-addons-update');
import React, { Component } from "react";

export default class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            body_title: '',
            body_content: '',
            cancel_title: '',
            ok_title: '',
            form: null
        };
    }
    componentDidMount () {
        
        emitter.on('UPDATE_MODAL_BODY', (state) => {
            this.setState({...state});
        });
        emitter.on('SHOW_MODAL', (modal) => {
            console.log('modal', modal);
            //reset values
            if (this.element) delete this.element;
            this.setState({
                title: '',
                body_title: '',
                body_content: '',
                cancel_title: '',
                ok_title: '',
                form: null
            });
            
            if (modal.element) { 
                this.element = React.createElement(modal.element, {
                    onChange: (data) => {
                        this.data = data;
                    }
                });
            }
            this.cancel = () => {
                modal.cancel();
                delete this.element;
                $('.p2g-modal').modal('hide');
            }
            this.ok = () => {
                if (this.state.form !== null) {
                    modal.ok(this.state.form.formData);
                } else if (this.element) {
                    console.log('this.element', this.data);
                    modal.ok(this.data);
                } else {
                    modal.ok();
                }
                $('.p2g-modal').modal('hide');
            }
            this.setState(modal.state);
        });
    }
    componentDidUpdate () {
        $('.p2g-modal').modal('show');
    }
    render () {
        var form = <span />

        if (this.state.form !== null) {
            var handleInput = (ev) => {
                this.setState({ form: update(this.state.form, { formData: { $set: ev.formData } }) });
            }
            var schema = this.state.form.schema;
            var formData = this.state.form.formData;// ? this.state.form.formData : {};
            var uiSchema = this.state.form.uiSchema;//  ? this.state.form.uiSchema : {};
            var additional_widgets = {
                file_extended: FileWidgetExtended,
                html_editor: HTMLEditor,
                colorpicker: ColorPicker,
                domainselect: DomainSelect
            };
            form = (
                <Form widgets={additional_widgets} uiSchema={uiSchema} schema={schema} formData={formData}  validator={validator} onChange={(e) => handleInput(e)}>
                    <div></div>
                </Form>
            )
        }
        
        return (
            <div className="p2g-modal modal fade in" tabIndex="-1" role="dialog" aria-hidden="true" style={{ marginTop: '70px', zIndex: 10000000 }}>
                <div className={"modal-dialog" + (this.state.large ? ' modal-lg' : '')}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title">{this.state.title}</h4>
                        </div>
                        <div className="modal-body">
                            <div class="container">
                            <h4>{this.state.body_title}</h4>
                            <p>{this.state.body_content}</p>
                            {form}
                            {this.element ? this.element : ''}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default modal-button-cancel" data-dismiss="modal" onClick={() => this.cancel()}>{this.state.cancel_title}</button>
                            <button type="button" className="btn btn-primary modal-button-ok" onClick={() => this.ok()}>{this.state.ok_title}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
