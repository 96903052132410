import React, { Component } from "react";

export default class TopNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }
    componentDidMount() {
        var self = this;
        emitter.on('DASHBOARD_TITLE_UPDATE', function(data) {
            if (data.resource === 'page') {
                data.resource = 'Seite';
            }
            if (data.resource === 'blog') {
                data.resource = 'Blogartikel';
            }
            data.resource = data.resource.charAt(0).toUpperCase() + data.resource.substring(1);
            self.setState(data);
        })
    }
    handleChange(ev) {
        ev.preventDefault();
        //this.state[ev.target.getAttribute('name')] = ev.target.value;
        //console.log('ev', this.state);
    }
    render() {
        var loadingSpinner = <span/>;
        if (this.state.loading) {
            loadingSpinner = <li><a><i class="fa fa-refresh fa-spin" style="font-size:24px"></i></a></li>;
        }
        return (
            <div className="top_nav">
                <div className="nav_menu">
                    <nav className="" role="navigation">
                        <div className="nav toggle">
                            <a id="menu_toggle"><i className="fa fa-bars"></i></a>
                        </div>
                        <ul className="nav navbar-nav navbar-right">
                            {loadingSpinner}
                        </ul>
                    </nav>
                    <div className="header-title">
                        <span className="left">{this.state.resource}</span>
                        <span className="middle">{this.state.title}</span>
                        
                        <span className="right">
                            
                            <span>{this.state.domain}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
