import React, { Component } from "react";
import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import IFrame from './iframe';
import FloatMenu from './float-menu';
import HTMLEditor from './htmleditor';
import FileWidgetExtended from './FileWidgetExtended';
var IFrameCommunicator = require('../libs/iframe-communication');
var uiSchema = require('./ui-schemas/blog-ui-schema.js');

var shallowCompare = require('react-addons-shallow-compare');
import SEOField from './widgets/SEOField';
import TitlesField from './widgets/TitlesField';
import ConfigField from './widgets/ConfigField';
import GooglePlaceField from './widgets/GooglePlaceField';

import EditMethods from './edit-methods.js';

import Section from './section';

import SEOAdjuster from "./seo-adjuster";

export default class BlogEdit extends EditMethods {
    constructor(props) {
        super(props);
        global.checks = this.checks;
        this.state = { initialized: false };
        this.sectionSchemas = {};

        this.seoAdjuster = new SEOAdjuster();
        this.additional_widgets = {
            file_extended: FileWidgetExtended,
            html_editor: HTMLEditor
        }

    }
    shouldComponentUpdate(nextProps, nextState) {
        
        return shallowCompare(this, nextProps, nextState);
    }
    validate(props) {
        global.groupId = this.props.resource.resourceId;
        props.client.read('/schema/posts').then((schema) => {
            this.schema = schema.data;
            
            this.schema.properties.sections.items.oneOf.forEach(sectionSchema => {
                if (sectionSchema.title !== 'Slider') {
                    this.sectionSchemas[sectionSchema.properties.section_type.default] = sectionSchema;
                }

            })
            return props.client.read('/webpages/' + this.props.resource.resourceId + '/posts/' + this.props.resource.subResourceId);
        }).then((blog) => {
            this.storage = blog.data;
            this.storage.seo.sub_path = this.storage.sub_path;
            this.storage.post_date = this.formatDate(this.storage.post_date);
            
            return props.client.read('/webpages/' + this.props.resource.resourceId + '/domains');
        }).then((domain) => {
            this.domain = domain.data.main_domain;
            this.protocol = domain.data.enable_ssl === true ? 'https' : 'http';
            global.protocol = this.protocol; // for file extended widget
            global.main_domain = this.domain; // for file extended widget
            
            this.setState({
                initialized: true,
                widget_visibilities: {}
            },() => {
                this.process_checks();
            });
        });
    }
    componentDidMount() {

        this.validate(this.props);
        
        setInterval(() => {
            $('.page-form, .preview').css('max-height', $(window).height() - 145).css('height', $(window).height() - 145);
        }, 1000)
    }
    componentWillReceiveProps(new_props) {
        
        this.validate(new_props);
    }
    save() {


        emitter.trigger('SHOW_LOADING');
        try {
            if (!this.storage.seo.head_description || this.storage.seo.head_description.trim().length === 0) {
                this.seoAdjuster.adjust(this.storage);
                this.forceUpdate();
            }
        } catch (e) {
            console.error(e);
        }


        var promises = [];
        var process = (key, value, parent) => {
            if (typeof (value) === 'string' && value.indexOf('data:image') !== -1 && value.indexOf('data:image') === 0) {
                promises.push(new Promise((resolve, reject)  => {
                    this.props.client.create('/webpages/' + this.storage.ref_targetgroup_id + '/media', { base64data: value }).then((response) => {
                        parent[key] = response.data.url;
                        resolve();
                    })
                }));
            }
        }
        // transform date to iso string on save
        let dateClientFormat = this.storage.post_date;
        if (this.storage.post_date && this.storage.post_date.indexOf('-') !== -1) {
            this.storage.post_date = new Date(this.storage.post_date).toISOString();
        }
        //TODO: workaround before movement to seo fields
        if (this.storage.seo.sub_path) {
            this.storage.sub_path = this.storage.seo.sub_path;
        }
        this.traverse(this.storage, process);
        Promise.all(promises).then(() => {
            this.props.client.update('/webpages/' + this.storage.ref_targetgroup_id + '/posts/' + this.storage._id, this.storage).then((saved_blog) => {
                this.storage.post_date = dateClientFormat;
                
                this.forceUpdate(() => {
                    setTimeout(() => {
                        new IFrameCommunicator('.preview iframe').trigger({ action: 'reload_body', target: 'body' });
                    }, 1000)
                });
                emitter.trigger('HIDE_LOADING');
            });
        });

    }
    handleSectionNew(idx, ev) {

        var skeleton = {section_type: 'text'};
        if (ev) {
            this.storage.sections.splice(idx, 0, skeleton);
            this.lastNewIdx = idx;
        } else {
            this.storage.sections.push(skeleton);
            this.lastNewIdx = 0;
        }
        this.forceUpdate();
    }
    handleTitleChange(ev) {

        this.storage.title = ev.formData;
    }
    handleAuthorChange(ev) {

        this.storage.author = ev.formData;
        this.forceUpdate();
    }
    formatDate(dateString) {
        let format = (d) => {
            var month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        }
        console.log('date string', dateString, Date.parse(dateString));
        try {
            return format(new Date(dateString));
        } catch (e) {
            console.log(e);
            return format(new Date());
        }

    }
    handleStatusChange(ev) {
        this.storage.status = ev.formData;
    }
    handleDateChange(ev) {
       this.storage.post_date = ev.formData;
    }
    handleCategoriesChange(ev) {

        this.storage.categories = ev.formData;
    }
    handleHeaderImageChange(ev) {
        console.log("handleheaderchange",ev);
        if (!ev.formData && this.storage.main_img) {
            delete this.storage.main_img;
        }
        if (ev.formData) {
            this.storage.main_img = ev.formData;
        }
        
    }
    menu_params() {

        return {
            preview: {
                title: 'Vorschau',
                href: this.webpage_url()
            },
            cancel: {
                title: 'Zurück',
                action: () => {
                    history.back();
                }
            },
            ok: {
                title: 'Speichern',
                disabled: false,//!this.state.page_changed,
                action: () => {
                    this.save();
                }
            }
        }
    }
    webpage_url() {

        var year = this.storage.post_date.substring(0, 4);
        var month = this.storage.post_date.substring(5, 7);
        var day = this.storage.post_date.substring(8, 10);
        var webpage_url = this.protocol + '://' + this.domain + '/' + year + '/' + month + '/' + day + '/' + this.storage.sub_path + (this.storage.status === 'draft' ? '--draft' : '');

        webpage_url = webpage_url + '?notrack';
        return webpage_url;
    }

    fields() {

        var CustomTitleField = (title, required) => {
            if (title.id !== 'root__title') {
                var legend = required ? title.title + '*' : title;
                return <legend>{legend}</legend>;
            } else { return <span />; }
        };

        var fields = {
            SEOField: SEOField,
            TitleField: CustomTitleField,
            TitlesField: TitlesField,
            ConfigField: ConfigField,
            GooglePlaceField: GooglePlaceField
        };

        return fields;
    }

    section_forms() {
        
        var sections = [];
        if (this.storage.sections.length > 0) {
            this.storage.sections.map((formData, idx) => {
                //console.log("section- " + idx, formData);
                
                
                var section_form = <Section
                    key={'section-' + idx}
                    idx={idx}
                    fields={this.fields()}
                    additional_widgets={this.additional_widgets}
                    formData={formData}
                    onChange={e => this.handleSectionChange(idx, e)}
                    sectionSchemas={this.sectionSchemas}
                    uiSchema={uiSchema.sections.items}
                    default="text"
                    moveUp={() => this.handleSectionMoveUp(idx)}
                    moveDown={() => this.handleSectionMoveDown(idx)}
                    new={() => this.handleSectionNew(idx)}
                    delete={() => this.handleSectionDelete(idx)}
                    copy={() => this.handleSectionCopy(idx)}
                    paste={() => this.handleSectionPaste(idx)}
                    visible={() => this.handleVisibilityClick(idx)}
                    isVisible={this.state.widget_visibilities[idx]}
                    schemaDefinitions={this.schema.definitions}
                />
                sections.push(section_form);
            })
        } else {
            sections.push(<button className="btn btn-default" onClick={this.handleSectionNew(0)}>Neues Seitenelement</button>);
        }
        return sections;
    }
    render() {

        if (this.state.initialized) {
            var seo_visibility = this.state.widget_visibilities['seo'];
            var seo_display = seo_visibility ? { display: 'block' } : { display: 'none' };
            var seo_display_chevron = seo_visibility ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

            var custom_visibility = this.state.widget_visibilities['custom'];
            var custom_display = custom_visibility ? { display: 'block' } : { display: 'none' };
            var custom_display_chevron = custom_visibility ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

            var settings_visibility = this.state.widget_visibilities['settings'];
            var settings_display = settings_visibility ? { display: 'block' } : { display: 'none' };
            var settings_display_chevron = settings_visibility ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

            var sections = this.section_forms();
            
            return (
                <div className="row" style={{ paddingBottom: '40px' }}>
                    <div className="col-md-6 blog-form">
                        {this.get_warnings()}
                        {this.get_dangers()}
                        <div className="x_panel post-seo seo">
                            <div className="x_title">
                                <h2>{this.schema.properties.seo.title}</h2>
                                <ul className="nav navbar-right panel_toolbox">
                                    <li><a onClick={() => this.handleVisibilityClick('seo')}><i className={seo_display_chevron}></i></a></li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="x_content" style={seo_display}>
                            <Form
                                    transformErrors={(a, b) => {
                                        console.log('transform formData', a, b);
                                    }}
                                    validate={(formData, errors) => {
                                        console.log('formData', formData, errors);


                                        return errors;
                                    }}
                                    widgets={this.additional_widgets}
                                    uiSchema={uiSchema.seo}
                                    schema={this.schema.properties.seo}
                                    formData={this.storage.seo}
                                    fields={this.fields()}
                                    onChange={(ev) => this.handleSEOChange(ev.formData)}
                                    onError={
                                        (a, b) => {
                                            console.log('onError formData', a, b);
                                        }
                                    }
                                    validator={validator}

                                >

                                    <div></div>
                                </Form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="x_panel post-settings settings">
                            <div className="x_title">
                                <h2>Einstellungen</h2>
                                <ul className="nav navbar-right panel_toolbox">
                                    <li><a onClick={() => this.handleVisibilityClick('settings')}><i className={settings_display_chevron}></i></a></li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="x_content" style={settings_display}>
                                <Form
                                    key="title"
                                    widgets={this.additional_widgets}
                                    schema={this.schema.properties.title}
                                    formData={this.storage.title}
                                    fields={this.fields()}
                                    validator={validator}
                                    onChange={(e) => this.handleTitleChange(e)}>
                                    <div></div>
                                </Form>
                                <Form
                                    key="status"
                                    schema={this.schema.properties.status}
                                    formData={this.storage.status}
                                    fields={this.fields()}
                                    validator={validator}
                                    onChange={(e) => this.handleStatusChange(e)}>
                                    <div></div>
                                </Form>
                                <Form
                                    key="author"
                                    widgets={this.additional_widgets}
                                    schema={this.schema.properties.author}
                                    formData={this.storage.author}
                                    fields={this.fields()}
                                    validator={validator}
                                    onChange={(e) => this.handleAuthorChange(e)}>
                                    <div></div>
                                </Form>
                                <Form
                                    key="post_date"
                                    widgets={this.additional_widgets}
                                    schema={this.schema.properties.post_date}
                                    formData={this.storage.post_date}
                                    fields={this.fields()}
                                    validator={validator}
                                    onChange={e => {
                                        this.handleDateChange(e);
                                    }}>
                                    <div></div>
                                </Form>
                                <Form
                                    key="categories"
                                    widgets={this.additional_widgets}
                                    schema={this.schema.properties.categories}
                                    formData={this.storage.categories}
                                    validator={validator}
                                    onChange={(e) => this.handleCategoriesChange(e)}>
                                    <div></div>
                                </Form>
                                <Form
                                    key="main_img"
                                    widgets={this.additional_widgets}
                                    schema={this.schema.properties.main_img}
                                    formData={this.storage.main_img}
                                    fields={this.fields()}
                                    uiSchema={uiSchema.main_img}
                                    validator={validator}
                                    onChange={(e) => this.handleHeaderImageChange(e)}>
                                    <div></div>
                                </Form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="post-sections sections">
                        {sections}
                        </div>
                    </div>
                    <div className="col-md-6 post-preview preview">
                        <IFrame url={this.webpage_url()} />
                    </div>
                    <FloatMenu params={this.menu_params()} />
                </div>
            )
        } else {
            return <div />;
        }
    }
};
