import React, { Component } from "react";

export default class OverviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }
    componentDidMount () {
        emitter.on('SHOW_OVERVIEW_MODAL', (config) => {
            /**
             * clientType oauth or api (nothing works too)
             * resource.resourceId => webpage id
             * resource.subResourceName => e.g. media
             * isHierarchical => true e.g. for media, pages etc. false for domains, design, etc.
             */
            //reset values
            this.config = {
                clientType: config.clientType,
                resource: {
                    resourceId: config.resource.resourceId,
                    subResourceName: config.resource.subResourceName
                },
                isHierarchical: config.isHierarchical
            }
            this.setState({
                show: true
            });
            this.cancel = () => {
                config.cancel();
                $('.p2g-overview-modal').modal('hide');
            }
            this.ok = () => {
                if (this.state.form !== null) {
                    config.ok(this.state.form.formData);
                } else {
                    config.ok();
                }
                $('.p2g-overview-modal').modal('hide');
            }
        });
    }
    componentDidUpdate () {
        $('.p2g-overview-modal').modal('show');
    }
    render () {
        var self = this;
        var overview = <span />
        if (this.config) {
            this.config.filterOut = ['sections', 'ref_', '_id']
            overview = <Overview {...this.config} />;
        }
        return (
            <div className="p2g-overview-modal modal fade in" tabIndex="-1" role="dialog" aria-hidden="true" style={{ marginTop: '70px', zIndex: 10000000 }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="modal-title">{''}</h4>
                        </div>
                        <div className="modal-body">
                            {overview}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.cancel}>{this.state.cancel_title}</button>
                            <button type="button" className="btn btn-primary" onClick={this.ok}>{this.state.ok_title}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
