import React, { Component } from "react";
import Dropzone from 'react-dropzone'

export default [
    {
        title: 'Bearbeiten',
        icon: 'edit',
        type: 'row',
        func: (id, props) => {

            if (props.isHierarchical) {
                location.hash = '#webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName + '/' + id;
            } else {
                location.hash = '#' + props.resource.resourceName + '/' + id;
            }
        },
    },
    {
        title: 'Kopieren',
        icon: 'copy',
        type: 'row',
        func: (id, props) => {
            let client = props.resource.clientType === 'oauth' ? props.oauth_client : props.client;
            emitter.emit('SHOW_MODAL', {
                ok: async (new_name) => {
                    emitter.emit('SHOW_LOADING');
                    var mediaData = await client.read('/webpages/' + props.resource.resourceId + '/media/' + id);
                    mediaData = mediaData.data;
                    console.log(mediaData);

                    const toDataURL = url => fetch(url)
                        .then(response => response.blob())
                        .then(blob => new Promise((resolve, reject) => {
                            const reader = new FileReader()
                            reader.onloadend = () => resolve(reader.result)
                            reader.onerror = reject
                            reader.readAsDataURL(blob)
                        }))


                    var dataURL = await toDataURL(mediaData.url)

                    function addNameToDataURL(dataURL, name) {
                        return dataURL.replace(";base64", `;name=${name};base64`);
                    }

                    dataURL = addNameToDataURL(dataURL, new_name);

                    await client.create('/webpages/' + props.resource.resourceId + '/media', { base64data: dataURL });
                    props.onChange();

                    emitter.emit('HIDE_LOADING');

                },
                cancel: () => {
                },
                state: {
                    title: 'Datei kopieren',
                    body_title: '',
                    body_content: '',
                    form: {
                        schema: {
                            title: "Neuer Dateiname",
                            type: "string",
                            required: true,
                            default: id
                        }
                    },
                    cancel_title: 'Abbrechen',
                    ok_title: 'Kopieren'
                }
            });

        },
    },
    {
        title: 'Löschen',
        icon: 'trash',
        type: 'row',
        func: (id, props) => {

            emitter.emit('SHOW_MODAL', {
                ok: () => {
                    emitter.emit('SHOW_LOADING');
                    let removeURL = '';
                    if (props.isHierarchical) {
                        removeURL = '/webpages/' + props.resource.resourceId + '/' + props.resource.subResourceName + '/' + id;
                    } else {
                        removeURL = '/' + props.resource.resourceName + '/' + id;
                    }
                    let client = props.resource.clientType === 'oauth' ? props.oauth_client : props.client;
                    client.remove(removeURL).then(() => {
                        props.onChange();
                        emitter.emit('HIDE_LOADING');
                    })
                },
                cancel: () => {
                },
                state: {
                    title: 'Bild löschen',
                    body_title: '',
                    body_content: 'Sind sie sicher, dass Sie dieses Bild löschen möchten?',
                    cancel_title: 'Abbrechen',
                    ok_title: 'Löschen'
                }
            });
        }
    },
    {
        title: 'Hochladen',
        type: 'all',
        func: (props) => {

            return <button className="btn btn-default" onClick={() => {
                var save = async(data) => {
                    console.log('Hochladen all', props);
                    for (var img_data of data) {
                        await client.create('/webpages/' + props.resource.resourceId + '/media', { base64data: img_data });
                    }
                    props.onChange();
                    emitter.emit('HIDE_LOADING');
                }
                if (!props.in_modal) {
                    emitter.emit('SHOW_MODAL', {
                        ok: async (data) => {
                            save(data);
                        },
                        cancel: () => {
                        },
                        element: Uploader,
                        state: {
                            title: 'Dateien hochladen',
                            body_title: '',
                            body_content: '',
                            cancel_title: 'Abbrechen',
                            ok_title: 'Ok'
                        }
                    })
                } else {
                    props.onChangeView({
                        element: Uploader, onChange: async (data) => {
                            await save(data);
                        }
                    });
                }
            }}>Hochladen</button>

        }
    }
]

export class Uploader extends React.Component {
    constructor(props) {
        super(props);
        this.onDrop = async (files) => {
            var existingFiles = this.state.files;
            files.forEach((file) => {
                existingFiles.push(file);
            })
            var data = [];
            for (var file of existingFiles) {
                var base64data = await this.getBase64(file);
                data.push(base64data);
            }
            this.props.onChange(data);
            this.setState({ files: existingFiles })
            console.log('on drop', this.props);
            if (this.props.onResetView) {
                this.props.onResetView();
            }
        };
        this.state = {
            files: []
        };
    }
    getBase64(file) {
        return new Promise((done) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                function addNameToDataURL(dataURL, name) {
                    return dataURL.replace(";base64", `;name=${name};base64`);
                }
                var base64data = addNameToDataURL(reader.result, file.name);
                done(base64data);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        })
    }

    render() {
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        return (
            <Dropzone multiple={true} onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <section className="container">
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Hier Dateien per Drag 'n' Drop hinzufügen oder klicke auf die Fläche und wähle Bilder zum Hochladen aus.</p>
                        </div>
                        <aside>
                            <p>Hochgeladene Bilder</p>
                            <ul>{files}</ul>
                        </aside>
                    </section>
                )}
            </Dropzone>
        );
    }
}